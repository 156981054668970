import SpaLoader from "./SpaLoader";
import RestaurantLoader from "./RestaurantsLoader";
import CarRentalLoader from "./CarRentalLoader";
import LaundryLoader from "../../Components/Loaders/LaundryLoader";
import PharmacyLoader from "./PharmacyLoader";
import PharmacySubMenuLoader from "./PharmacySubMenuLoader";

const CustomBusinessServiceLoader = ({ businessName, loader }) => {
	// Normalize business name for comparison
	const normalizedBusinessName = businessName
		?.trim()
		.toLowerCase()
		.replaceAll(",", "")
		.replaceAll(".", "");

	if (
		businessName &&
		normalizedBusinessName === "the lana residences dubai"
	) {
		return (
			<div className="home_loader">
				<div
					className="loader"
					style={{
						borderColor: "lightgray",
						borderRightColor: "#000000",
					}}
				></div>
			</div>
		);
	}
	if (
		businessName &&
		normalizedBusinessName !== "the lana residences dubai" &&
		!loader
	) {
		return (
			<div className="home_loader">
				<div className="loader"></div>
			</div>
		);
	}
	if (!loader) return null;
	switch (loader) {
		case "spa":
			return <SpaLoader />;
		case "restaurant":
			return <RestaurantLoader />;
		case "car_rental":
			return <CarRentalLoader />;
		case "laundry":
			return <LaundryLoader />;
		case "pharmacy":
			return <PharmacyLoader />;
		case "pharmacySubMenu":
			return <PharmacySubMenuLoader />;
		case "carRental":
			return <CarRentalLoader />;

		default:
			return (
				<div className="home_loader">
					<div
						className="loader"
						style={{
							borderColor:
								normalizedBusinessName ===
									"the lana residences dubai" && "lightgray",
							borderRightColor:
								normalizedBusinessName ===
									"the lana residences dubai" && "#000000",
						}}
					></div>
				</div>
			);
	}
};

export default CustomBusinessServiceLoader;
