import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Button, Form, Modal } from "react-bootstrap";
import Post from "../assets/images/food.png";
import { AiOutlineCloseCircle } from "react-icons/ai";
import Image from "react-bootstrap/Image";
import { useCartStore } from "../store/useStore";
import { addToCart } from "../utils/cartUtils";
import axios from "../axios";
import { useQueryClient } from "react-query";
import { useTranslation } from "react-i18next";
import { Zoom } from "react-reveal";
import { object } from "yup";
import ReactGA from "react-ga4";
import MealItem from "./MealItem";
import { ThemeContext } from "../context/ThemeContext";
import Currency from "./Currency";

function ComboMeal({ item, restaurantId, hideBtn, currency, restaurantInfo }) {
	// console.log("isOffCategory", restaurant_is_closed);
	// console.log("restaurant_is_closed", !isOffCategory || !restaurant_is_closed);

	// console.log("res", res);
	// const [enableClass, setEnableClass] = useState(true);
	const [show, setShow] = useState(false);
	const [count, setCount] = useState(1);
	const [showCart, setShowCart] = useState(false);
	const [loading, setLoading] = useState(false);
	const [extras, setExtras] = useState([]);
	const [priceOption, setPriceOption] = useState(0);
	const [openCard, setOpenCard] = useState(false);
	const [groubs_extras, setGroubs_extras] = useState([]);
	const [groubs, setGroubs] = useState([]);
	const [groubsID, setGroubsID] = useState([]);
	const [itemsID, setItemsID] = useState([]);

	// const isRequired = item?.extrasWithOptions[0]?.is_required;
	const location = useLocation();
	const rawPath = location.pathname;
	const room_uuid = rawPath.split("/")[1];
	// console.log("itemitemitem", item);
	const queryClient = useQueryClient();

	const { cart, setCart, cartCount, setCartCount } = useCartStore(
		(state) => ({
			cart: state.cart,
			setCart: state.setCart,
			cartCount: state.cartCount,
			setCartCount: state.setCartCount,
		})
	);

	// setTimeout(() => {
	//   setEnableClass(false);
	// }, 3000);
	// const currency = localStorage.getItem("currency");
	const { t, i18n } = useTranslation();
	const handleClose = () => {
		setShow(false);
	};
	const handleShow = () => {
		setShow(true);
		ReactGA.event({
			category: "Choose an item from the restaurant ",
			action: "Click",
			value: `item name: ${item?.name}}`,
		});
	};

	useEffect(() => {
		const checkboxes = document.getElementsByClassName("checkboxes");
		Object.values(checkboxes).map((check) => {
			if (check.children[0].required == true) {
				check.children[0].setCustomValidity(
					"Please select 1 option at least"
				);
			}
		});
	}, []);

	useEffect(() => {}, [show]);

	const handleCartClose = () => {
		setShowCart(false);
		setCount(1);
		setExtras([]);
	};
	const handleCartShow = () => setShowCart(true);
	const handleIncrease = () => {
		setCount((prev) => prev + 1);
	};
	const handleDecrease = () => {
		setCount((prev) => {
			if (prev <= 1) {
				return 1;
			}
			return prev - 1;
		});
	};

	const lang = localStorage.getItem("lang");
	// console.log("languahg", lang);
	// const SetCartItem = (item) => {
	//   const tempCart = [...JSON.parse(JSON.stringify(cart)), item];
	//   setCart(tempCart);
	// };
	// const [myObject, setMyObject] = useState([]);
	// const obje = [];
	const formData = new FormData();
	// const setExtramm = (id, idx) => {
	//   formData.append(`extras[${idx}]`, id);
	//   // setMyObject(...myObject, id);
	//   // console.log("myObjmmdmdmect", id);
	// };
	formData.append("item_id", item.id);
	formData.append("quantity", count);
	formData.append("restaurant_id", parseInt(restaurantId));
	//   useEffect(() => {
	//     const input_ch = document.getElementsByClassName("input_ch");
	// console.log("item?.extrasWithOptions?.length", item?.extrasWithOptions?.length);
	//   }, []);
	const [optionsIds, setOptionsIds] = useState({});
	// const new_design = localStorage.getItem("new_design");
	// const theme = new_design == "true";
	const { theme } = useContext(ThemeContext);
	const handleInvalid = (event, index) => {
		event.target.setCustomValidity("Please select 1 option at least");
	};
	const handleHandleOptionsChanges = (
		event,
		optionName,
		optionId,
		type,
		price,
		idx,
		max_op
	) => {
		event.target.setCustomValidity("");
		// console.log("max_op", max_op);

		if (type == "radio") {
			// if (state == "remove") {
			//   const { [optionName]: _, ...rest } = optionsIds;

			//   setOptionsIds(rest);
			// } else {
			setOptionsIds((prev) => ({
				...prev,
				[optionName]: optionId,
			}));
			// }
		} else {
			const idex = document.getElementById(`idx${idx}`);
			var inputNodes = idex.getElementsByTagName("INPUT");
			var inputLang = idex.querySelectorAll(
				'input[type="checkbox"]:checked'
			).length;
			// console.log("inputNodes", inputNodes);
			Object.values(inputNodes).map((inp) => {
				if (inputLang >= 1) {
					inp.required = false;
					inp.setCustomValidity("");
				} else if (inputLang == 0) {
					inp.required = true;
					inp.setCustomValidity("Please select 1 option at least");
				}
				if (inputLang == max_op) {
					// console.log("inputLang", inputLang);
					if (inp.checked == false) {
						inp.disabled = true;
					}
				} else {
					if (inp.checked == false) {
						inp.disabled = false;
					}
				}
			});

			// Check if the object is present in the array
			const index = extras.findIndex(
				(obj) => JSON.stringify(obj) === JSON.stringify(optionId)
			);

			if (index !== -1) {
				// If found, remove the object from the array
				extras.splice(index, 1);
				setPriceOption(priceOption - Number(price * count));
			} else {
				// If not found, add the object to the array
				extras.push(optionId);
				setPriceOption(priceOption + Number(price * count));
			}

			// if (extras.includes(optionId)) {

			//   const tempExtras = JSON.parse(JSON.stringify(extras));
			//   console.log("tempExtras", tempExtras);
			//   const filtered = tempExtras.filter((temp) => {
			//     return temp !== optionId;
			//   });
			//   setExtras(filtered);
			//   console.log("filtered", filtered);
			// } else {
			//   setPriceOption(priceOption + Number(price));

			//   setExtras((prev) => [optionId, ...prev]);
			// }
		}
	};

	// console.log("price", priceOption);
	// console.log("optionsIds", Object.values(optionsIds));
	// useEffect(() => console.log("optionsIds", optionsIds, extras), [optionsIds]);

	// const objectOpt = [Object.values(optionsIds)]
	// console.log();
	let text_extras = [];
	const addToCartV2 = (e) => {
		// console.log("update extras from Item state", extras);
		const optionsArray = [...Object.values(optionsIds), ...extras];
		console.log("optionsArray", optionsArray);
		const formatArray = optionsArray.map((option) => {
			return {
				extra_id: option.extra_id,
				option_id: option.id,
				price: option.price,
			};
		});
		// console.log("...Object.values(optionsIds), ...extras]", formatArray);
		e.preventDefault();

		let extraText = document.getElementsByClassName("ex_class");
		if (extraText.length > 0) {
			Object.values(extraText).map((item) => {
				if (item.value != "") {
					text_extras.push({ extra_id: item.id, text: item.value });
				}
			});
		}

		const obj = {
			restaurant_id: parseInt(restaurantId),
			meal_id: item.id,
			quantity: count,
			extras: formatArray,
			groups: groubs,
		};

		setLoading(true);

		axios
			.post(`/${room_uuid}/restaurant/order/order-meal`, {
				restaurant_id: parseInt(restaurantId),
				meal_id: item.id,
				quantity: count,
				extras: formatArray,
				groups: groubs,
			})
			.then((res) => {
				setLoading(false);
				queryClient.invalidateQueries(`checkout`);
				const data = res.data.data;
				handleCartClose();
				setCartCount(cartCount + 1);
			});
		setGroubs([]);
		setItemsID([]);
		setGroubsID([]);
	};
	const setExtra = (name, type) => {
		// if (extras.length <= 2) {
		if (type == "radio") {
			setExtras([name]);
		} else {
			if (extras.includes(name)) {
				const tempExtras = JSON.parse(JSON.stringify(extras));
				const filtered = tempExtras.filter((temp) => {
					return temp !== name;
				});
				setExtras(filtered);
			} else {
				setExtras((prev) => [name, ...prev]);
			}
		}

		// }
	};
	let newpice = 0;
	extras?.map((ext) => {
		newpice = newpice + ext.price;
	});
	newpice = newpice * count;

	let showBtn = true;

	// console.log("iteeeeeeeeeeeeeeeeeeeeem", item);

	// console.log(" extras: formatArray", groubs_extras);
	console.log("itemitemitem", item.groups);
	console.log("G_ID", groubsID);
	const handleDataFromChild = (data) => {
		// setMessage(data); // Update state with received data
	};
	return (
		<div
			className={`add-post-container add-cart meal_card ${
				openCard ? "active" : ""
			}`}
			// onClick={() => setOpenCard(true)}
		>
			{item ? (
				<div className="add-cart-post new">
					<div className="add-cart-post-inner meal_post_inner">
						<div className={"d-none"}></div>
						<div
							// onClick={handleShow}
							className="img-pnl position-relative navigate cover animated-background"
							// className="img-pnl animated-background"
						>
							<Image src={item.image} alt="Post" />
						</div>
						<div className="txt-pnl navigate">
							<h2
							//    className="animated-background"
							>
								{item?.name}
							</h2>
							{item.restaurant_name && (
								<h6 className="s_title">
									{item?.restaurant_name}
								</h6>
							)}
							<p
							// className="animated-background"
							>
								{item.description}
							</p>
							<div className="flex-div">
								<h3 className={`"mt7"}`}>
									<Currency currency={currency}></Currency>{" "}
									{item?.price}
								</h3>
								<div className="d-flex align-items-center">
									{/* {console.log("grop*************", item.groups)} */}
									{item?.groups?.map((grop) => {
										// console.log("grop*************", grop.id);
										if (groubsID.includes(grop.id)) {
											showBtn = false;
										} else {
											showBtn = true;
										}
									})}
									{/* change to add to cart to build meal */}
									{/*{hideBtn && (
										<Button
											disabled={
												item?.groups?.length !=
												groubsID.length
											}
											onClick={handleCartShow}
											className="add-button"
										>
											{t("Add To Cart")}
										</Button>
									)}*/}
									{/* open items model */}
									{hideBtn && (
										<Button
											onClick={() =>
												setOpenCard(!openCard)
											}
											type="button"
											className="add-button"
										>
											{t("Build Your Meal")}
										</Button>
									)}
									{openCard ? (
										<span
											onClick={() => setOpenCard(false)}
											className="open_card"
										>
											<i class="fas fa-chevron-up"></i>
										</span>
									) : (
										<span
											onClick={() => setOpenCard(true)}
											className="open_card"
										>
											<i class="fas fa-chevron-down"></i>
										</span>
									)}
								</div>
							</div>
						</div>
					</div>
					<div className="group">
						{Object.values(item?.groups).map((gro, idx) => {
							return (
								<>
									<div className="extras-heading" key={idx}>
										<p className="text">{gro.name}</p>
									</div>
									<div
										className={`add-post-container add-cart`}
									>
										{/* group */}
										{gro?.items.map((item_, index) => {
											return (
												<>
													<></>
													<MealItem
														key={index}
														item={item_}
														groubs_extras={
															groubs_extras
														}
														setGroubs_extras={
															setGroubs_extras
														}
														//selected groups
														// when  select item from group it add group id and  item id
														groubs={groubs}
														setGroubs={setGroubs}
														groubsID={groubsID}
														setGroubsID={
															setGroubsID
														}
														itemsID={itemsID}
														itemsGroups={
															item.groups
														}
														setItemsID={setItemsID}
														groub_id={gro.id}
														hideBtn={hideBtn}
														max_items={
															gro.max_items
														}
														// itemsID, setItemsID
													/>
												</>
											);
										})}
									</div>
								</>
							);
						})}
					</div>
					{/* new add to card */}
					<div className="d-flex justify-content-center ">
						{hideBtn && (
							<Button
								disabled={
									item?.groups?.length != groubsID.length
								}
								onClick={handleCartShow}
								className="card-add-button"
							>
								{t("Add To Cart")}
							</Button>
						)}
					</div>
				</div>
			) : null}
			{item ? (
				<Modal
					show={show}
					// style={{ maxWidth: "430px" }}
					id={"restaurant-order-modal"}
					className="custom-width img p-2"
					onHide={handleClose}
				>
					<Button className="close-btn img" onClick={handleClose}>
						<div className="close-btn-wrap"></div>
						<AiOutlineCloseCircle />
					</Button>
					<Modal.Body style={{ padding: "20px" }}>
						<div className="modal-img-container">
							<Image src={item?.combo_item?.image} alt="Post" />
						</div>
					</Modal.Body>
				</Modal>
			) : (
				<Modal
					show={show}
					// style={{ maxWidth: "430px" }}
					id={"restaurant-order-modal"}
					className={`${theme ? "food-modal" : ""} custom-width img`}
					onHide={handleClose}
				>
					<Button className="close-btn img" onClick={handleClose}>
						<div className="close-btn-wrap"></div>
						<AiOutlineCloseCircle />
					</Button>
					<Modal.Body style={{ padding: "7px" }}>
						<div className="modal-img-container">
							<Image
								src={
									"https://tdhbucket.s3.me-central-1.amazonaws.com/restaurant_items/item1.png"
								}
								alt="Post"
							/>
						</div>
					</Modal.Body>
				</Modal>
			)}
			{item ? (
				<Modal
					show={showCart}
					className={`${theme ? "food-modal" : ""} custom-width`}
					onHide={handleCartClose}
				>
					<Button className="close-btn" onClick={handleCartClose}>
						{/* <i className="fa fa-times-circle-o"></i> */}
						<div className="close-btn-wrap"></div>
						<AiOutlineCloseCircle />
					</Button>
					<Modal.Body>
						<div className="modal-img-container">
							<Image src={item?.image} alt="Post" />
						</div>
						<div className="modal-text-container sub-menu">
							<h2 className="text-1">{item?.name}</h2>
							<p className="text-2">
								{item?.combo_item?.description
									? item?.description
									: ""}
							</p>
							<div className="price-container">
								<p>
									{item.price > 0 ? (
										<>
											<Currency
												currency={currency}
											></Currency>
											{item?.price}
										</>
									) : (
										<span style={{ fontSize: "10px" }}>
											{t("Price upon selection")}
										</span>
									)}
								</p>
								<div className="count-order-amount">
									<Button
										className="small"
										onClick={handleDecrease}
									>
										<i className="fa fa-minus"></i>
									</Button>
									<p>{count}</p>
									<Button
										className=" small"
										onClick={handleIncrease}
									>
										<i className="fa fa-plus"></i>
									</Button>
								</div>
							</div>
							{/* <div className="d-flex justify-content-center cart-btn-container">
              <Button className="reg-btn small w-100">Add To Cart</Button>
            </div> */}
							<form action="" onSubmit={addToCartV2}>
								{item?.combo_item?.extrasWithOptions?.length > 0
									? item?.combo_item?.extrasWithOptions.map(
											(opti, idx) => {
												return (
													<>
														<div
															className="extras-heading"
															key={idx}
														>
															<p className="text">
																{opti.name}
															</p>
															<p className="required">
																{" "}
																{opti?.is_required
																	? t(
																			"Required"
																	  )
																	: ""}
															</p>
														</div>
														{opti.extra_type_name ==
															"text" && (
															<div className="extra_input">
																<input
																	type="text"
																	id={
																		opti.extra_id
																	}
																	className="ex_class"
																/>
															</div>
														)}
														<div
															className="extras-items"
															id={`idx${idx}`}
														>
															{opti?.option
																? opti?.option.map(
																		(
																			extra,
																			index
																		) => {
																			return (
																				<div
																					className="extras-item"
																					id={
																						index
																					}
																				>
																					<p className="item-text">
																						{
																							extra.name
																						}
																					</p>
																					<div
																						className="res_options"
																						key={
																							index
																						}
																					>
																						{extra?.option_has_price && (
																							<p>
																								<>
																									+
																									<Currency
																										currency={
																											currency
																										}
																									/>
																									{
																										"  "
																									}
																									{` ${extra.price}`}
																								</>
																							</p>
																						)}

																						{opti.extra_type_name ==
																						"radio" ? (
																							<Form.Check
																								type={
																									opti.extra_type_name
																								}
																								name={
																									opti.name
																								}
																								id={
																									opti.name
																								}
																								className="item-check"
																								required={
																									opti.is_required
																								}
																								// disabled={
																								//   extras.length >= 2 &&
																								//   !extras.includes(extra.name)
																								// }
																								onChange={(
																									event
																								) =>
																									handleHandleOptionsChanges(
																										event,
																										opti.name,
																										(extra =
																											{
																												...extra,
																												extra_id:
																													opti.extra_id,
																											}),
																										opti.extra_type_name,
																										extra.price
																									)
																								}
																								aria-label="radio 1"
																							/>
																						) : (
																							<Form.Check
																								type={
																									opti.extra_type_name
																								}
																								name={
																									extra.name
																								}
																								id={
																									extra.name
																								}
																								className="item-check"
																								required={
																									index ==
																									0
																										? opti.is_required
																										: false
																								}
																								onInvalid={
																									handleInvalid
																								}
																								onChange={(
																									event
																								) =>
																									handleHandleOptionsChanges(
																										event,
																										opti.name,
																										(extra =
																											{
																												...extra,
																												extra_id:
																													opti.extra_id,
																											}),
																										opti.extra_type_name,
																										extra.price,
																										idx,
																										opti.max_options
																									)
																								}
																								aria-label="radio 1"
																							/>
																						)}
																					</div>
																				</div>
																			);
																		}
																  )
																: null}
														</div>
													</>
												);
											}
									  )
									: null}
								<div className="full-div mt-4 modal-add-cart">
									{hideBtn && (
										<Button
											// onClick={() =>
											//   addToCart({
											//     props: { item, cart, setCart, count, handleCartClose },
											//   })
											// }
											type="submit"
											// onClick={addToCartV2}
											className="reg-btn big w-100 flex-div"
											disabled={loading}
										>
											<div className="plus-container">
												<i className="fa fa-plus-square"></i>{" "}
												<span>{t("Add To Cart")}</span>
											</div>
											<div>
												{item?.price > 0 &&
												item?.price == 0 ? (
													<span
														style={{
															fontSize: "12px",
														}}
													>
														{t(
															"Price upon selection"
														)}
													</span>
												) : (
													<>
														<Currency
															currency={currency}
														/>{" "}
														{parseFloat(
															item?.price *
																count +
																newpice
														).toFixed(2)}
													</>
												)}
											</div>
										</Button>
									)}
								</div>
							</form>
						</div>
					</Modal.Body>
				</Modal>
			) : null}
		</div>
	);
}
export default ComboMeal;
