import React from "react";
import HeaderInner from "../../Components/HeaderInner";
import PrintDetails from "./PrintDetails";

export default function Print() {
	return (
		<div className="mubadala-page">
			<HeaderInner headerName={"Print"} />
			<PrintDetails />
		</div>
	);
}
