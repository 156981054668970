import React from "react";
import "./MubadalaWifiInstructions.css"; // Import the CSS file for styling

export default function MubadalaWifiInstructions({ businessName }) {
	return (
		<div className="mubadala-container">
			<div className="mubadala-content">
				<ol className="mubadala-steps">
					<li>
						Select <strong>MIC-Guest</strong> wireless network and
						click on Connect.
					</li>
					<li>
						You will be re-directed to Guest Portal{" "}
						{businessName === "Mubadala London" ? (
							<a
								href="http://1.2.3.4"
								target="_blank"
								rel="noopener noreferrer"
							>
								http://1.2.3.4
							</a>
						) : (
							<a
								href="https://guestaccessmamoura.mubadala.ae"
								target="_blank"
								rel="noopener noreferrer"
							>
								https://guestaccessmamoura.mubadala.ae
							</a>
						)}{" "}
						and click on Register for Guest access.
					</li>
					<li>
						Enter Guest details along with Sponsor email address and
						click on Register.
					</li>
					<li>
						Email will be sent to the sponsor to Approve or Deny,
						click on Approve.
					</li>
					<li>
						Once Approved, Guest will receive the credentials via
						email to login.
					</li>
					<li>Click on “Back to login page” on the Guest Portal.</li>
					<li>
						Enter your Username & Password from the approval email,
						select Agree to the terms and conditions and click Sign
						On.
					</li>
					<li>Click Continue on the welcome page.</li>
					<li>
						You will be redirected to <strong>Mubadala.com</strong>{" "}
						with Internet access.
					</li>
					<li>
						<strong>MIC Guest</strong> will show as connected.
					</li>
				</ol>
			</div>
		</div>
	);
}
