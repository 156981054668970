import React, { useEffect, useState, useRef, useContext } from "react";
import {
	Button,
	ButtonGroup,
	Col,
	Dropdown,
	DropdownButton,
	Form,
	Row,
	SplitButton,
} from "react-bootstrap";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import HeaderInner from "../../Components/HeaderInner";
import { ShowRestaurantCategories } from "../../API/In-Room-DiningAPI";
import Carosle from "../../Components/Carosle";
import { useTranslation } from "react-i18next";
import axios from "../../axios";
import { Toast } from "primereact/toast";
import SpaLoader from "../../Components/Loaders/SpaLoader";
import ReactGA from "react-ga4";
import "../../new-pages/hotel-services/HotelServices.scss";
import Title from "../../new-components/title/Title";
import NewFooter from "../../NewFooter";
import BannerServices from "../../new-components/banner-home/BannerServices";
import BannerHome from "../../new-components/banner-home/BannerHome";
// import { Calendar } from "primereact/calendar";
import CalendarComponent from "../../Components/Calender/Calender";
import TimePickerComponent from "../../Components/TimePicker/TimePickerComponent";
import PhoneInput from "react-phone-input-2";
import Iframe from "react-iframe";
import { phoneFormat } from "../../constants/api";
import PhoneInputComponent from "../../Components/PhoneInputComponent";
import { ThemeContext } from "../../context/ThemeContext";
import ShiftsData from "./ShiftsData";
import { useBusinessNameContext } from "./../../context/BusinessName";

import CustomBusinessServiceLoader from "../../Components/Loaders/CustomBusinessServiceLoader";

const BookTable = () => {
	const { businessName, setBusinessName } = useBusinessNameContext();
	const toast = useRef(null);
	const [count, setCount] = useState(1);
	const [timeInput, setTimeInput] = useState(new Date());
	const { t, i18n } = useTranslation();
	const navigate = useNavigate();
	const params = useParams();
	const location = useLocation();
	const rawPath = location.pathname;
	let room_uuid = rawPath.split("/")[1];
	const timeref = useRef(null);
	// const theme = location.search;
	// const new_design = localStorage.getItem("new_design");
	// const theme = new_design == "true";
	const { theme } = useContext(ThemeContext);
	console.log("theme", theme);

	// const [selectedDate, setSelectedDate] = useState(minDate);
	const [dateInput, setDateInput] = useState(new Date());

	const [email, setEmail] = useState("");
	const [name, setName] = useState("");
	const [phone, setPhone] = useState("");
	const [note, setNote] = useState("");
	const [loading, setLoading] = useState(false);
	const lang = localStorage.getItem("lang");
	const [numberPeople, setNumberPeople] = useState("1");

	const [sh_time, setShTime] = useState(null);
	const [hourShow, sethourShow] = useState("");
	const [minShow, setMinShow] = useState("");
	const [shifts, setShifts] = useState([]);
	const [showSlotMsg, setShowSlotMsg] = useState(false);

	const days = [
		{ Mon: "Monday" },
		{ Tue: "Tuesday" },
		{ Wed: "Wednesday" },
		{ Thu: "Thursday" },
		{ Fri: "Friday" },
		{ Sat: "Saturday" },
		{ Sun: "Sunday" },
	];
	let start_time = "00:00:00";
	let end_time = "23:00:00";
	let start_time_show = "12:00 AM";
	let end_time_show = "11:59 PM";
	let isOff = 0;
	let is_all_day = 1;
	let nameDay;
	let day_name;

	let restaurant;
	let restaurantCategories;
	let images = [];
	let isVideo = false;
	let bgColor;
	let color;
	let text;
	let working_times;
	let isbooking = "";
	// let use_shifts_logic = false;
	let res_uuid;
	const { data, isLoading, error } = ShowRestaurantCategories(
		room_uuid,
		params.restaurant_uuid,
		lang,
		isbooking
	);

	let booking_button_link;

	console.log("numberPeople", numberPeople);
	if (!isLoading) {
		if (!error) {
			restaurant = data.data.data;
			// use_shifts_logic = restaurant.restaurant?.use_shifts_logic;
			// setHeaderName=restaurant.center_header;
			// let restaurant_images = [...restaurant.restaurant?.images]
			isVideo = false;
			images = restaurant?.banners;
			working_times = restaurant.restaurant?.working_times;
			res_uuid = restaurant.restaurant?.uuid;
			booking_button_link = restaurant.restaurant?.booking_button_link;
			bgColor = data.data.data.header_color;
			text = data.data.data.center_header;

			if (working_times.length > 0) {
				const dayOfWeek = new Date(dateInput).toLocaleString("en-us", {
					weekday: "short",
				});
				// console.log("dayOfWeek", dayOfWeek);
				nameDay = days?.find((dayObj) => dayOfWeek in dayObj)[
					dayOfWeek
				];
				// console.log("nameDay", nameDay);
				const dayCur = working_times?.filter((time) => {
					if (time?.working_day?.names[0]?.name == nameDay) {
						day_name = time?.working_day?.name;
						return time;
					}
				});

				isOff = dayCur[0]?.is_off;
				is_all_day = dayCur[0]?.is_all_day;
				// console.log("start_timestart_time", dayCur[0]);
				if (isOff != 1) {
					start_time = dayCur[0]?.start_time;
					let hours_count =
						dayCur[0]?.hours_count == 24
							? 23
							: dayCur[0]?.hours_count;

					const [hours_, minutes_, seconds_] = start_time
						?.split(":")
						.map(Number);

					let totalHours = Number(hours_) + Number(hours_count);
					let totalHours_show = Number(hours_) + Number(hours_count);

					if (totalHours_show >= 24) {
						totalHours_show = totalHours_show - 24;
					}

					end_time = `${String(totalHours).padStart(2, "0")}:${String(
						minutes_
					).padStart(2, "0")}:${String(seconds_).padStart(2, "0")}`;
					end_time_show = dayCur[0]?.end_time_12;
					start_time_show = dayCur[0]?.start_time_12;

					// timeref.current.max = end_time;
					// timeref.current.min = start_time;
				} else {
					start_time = "00:00:00";
					end_time = "23:00:00";
					end_time_show = "11:59 PM";
					start_time_show = "12:00 AM";
				}
			}

			// console.log(" new Date(dateInput)", new Date(dateInput));

			// console.log("start_time", start_time);
			// console.log("end_time", end_time);
			// console.log("restaurant_imagesrestaurant_images", restaurant);
			// window.scrollTo({
			//   top: 0,
			//   left: 0,
			// });
		}
	}

	const [use_shifts_logic, setUse_shifts_logic] = useState(false);
	useEffect(() => {
		if (data) {
			const restaurant = data.data.data;
			setUse_shifts_logic(restaurant.restaurant?.use_shifts_logic);
		}
	}, [data]);

	console.log("booking_button_link", booking_button_link);

	const [timeValue, setTimeValue] = useState(new Date());
	const tineRif = useRef();

	const [shLoading, setShLoading] = useState(false);
	const [renderOne, setRenderOne] = useState(true);

	const shiftLogic = () => {
		setShLoading(true);

		const day_ = new Date(dateInput).getDate().toString().padStart(2, "0");
		const month = (new Date(dateInput).getMonth() + 1)
			.toString()
			.padStart(2, "0");
		const year = new Date(dateInput)
			.getFullYear()
			.toString()
			.padStart(2, "0");
		const formatDate = `${year}-${month}-${day_}`;

		axios
			.get(
				`/${room_uuid}/restaurant/shifts/${res_uuid}?date=${formatDate}`
			)
			.then((res) => {
				setShifts([]);
				setRenderOne(true);
				setShowSlotMsg(true);
				if (res?.data?.data?.shifts?.length > 0) {
					setShifts(res?.data?.data?.shifts[0]?.shifts);
				}

				setShLoading(false);
				start_time = "00:00:00";
				end_time = "23:00:00";
				// setTimeInput("15:00")
			});
	};
	console.log("ssssssss", shLoading);

	useEffect(() => {
		if (use_shifts_logic && renderOne) {
			console.log("data888880", dateInput);
			setRenderOne(false);
			shiftLogic();
		}
	}, [use_shifts_logic, dateInput]);

	console.log("shifts", shifts);

	const handleTime = (e) => {
		console.log("etime", e.target.value);
		// setTimeValue(e.value);
		const time = e.target.value;
		// sethourShow(
		//   new Date(e.target.value).getHours().toString().padStart(2, "0")
		// );

		setTimeInput(time);

		const time_ = time.split(":");

		sethourShow(time_[0]);
		setMinShow(time_[1]);
		// setShowInput(false);
	};

	// console.log("timeInput", timeInput);

	const handleDate = (e) => {
		const dayOfWeek = new Date(dateInput).toLocaleString("en-us", {
			weekday: "short",
		});
		nameDay = days.find((dayObj) => dayOfWeek in dayObj)[dayOfWeek];

		const dayCur = working_times?.filter((time) => {
			if (time.working_day.en_name == nameDay) {
				return time;
			}
		});

		console.log("dayCur", dayCur);
		console.log("nameDay", nameDay);
		isOff = dayCur[0]?.is_off;
		is_all_day = dayCur[0]?.is_all_day;
		if (isOff == 0) {
			start_time = dayCur[0]?.start_time;
			let hours_count =
				dayCur[0]?.hours_count == 24 ? 23 : dayCur[0]?.hours_count;

			const [hours_, minutes_, seconds_] = start_time
				?.split(":")
				.map(Number);

			let totalHours = Number(hours_) + Number(hours_count);
			let totalHours_show = Number(hours_) + Number(hours_count);

			if (totalHours_show >= 24) {
				totalHours_show = totalHours_show - 24;
			}

			end_time = `${String(totalHours).padStart(2, "0")}:${String(
				minutes_
			).padStart(2, "0")}:${String(seconds_).padStart(2, "0")}`;
			end_time_show = dayCur[0]?.end_time_12;
			start_time_show = dayCur[0]?.start_time_12;
		} else {
			start_time = "00:00:00";
			end_time = "23:00:00";
			end_time_show = "11:59 PM";
			start_time_show = "12:00 AM";
		}
		// timeref.current.max = end_time;
		// timeref.current.min = start_time;

		setDateInput(dateInput);
	};
	useEffect(() => {
		window.scrollTo({
			top: 0,
			left: 0,
		});
	}, []);
	useEffect(() => {
		const currentTime = new Date();

		// Format the time as HH:mm (hours and minutes)
		let hours = (currentTime.getHours() + 1).toString().padStart(2, "0");

		const minutes = currentTime.getMinutes().toString().padStart(2, "0");

		const currentDate = new Date();
		// console.log("formattedTime", formattedTime);
		// Format the date as YYYY-MM-DD (required by input type="date")

		const formattedDate = currentDate.toISOString().slice(0, 10);
		let day = currentDate.getDate().toString().padStart(2, "0");
		const month = (currentDate.getMonth() + 1).toString().padStart(2, "0");
		const year = currentDate.getFullYear().toString().padStart(2, "0");
		const formatDate = `${year}-${month}-${day}`;
		// console.log("hours", hours);
		if (hours == "24") {
			hours = "01";
			// console.log("day", day);
			day = day + 1;
		}
		const formattedTime = `${hours}:${minutes}`;
		setDateInput(formatDate);

		// console.log("formatDate", formatDate);
		setTimeInput(formattedTime);
	}, []);
	// console.log("newDat", new Date(timeInput).toLocaleTimeString());
	// const currentTimem = new Date(
	//   `${dateInput}T${new Date(timeInput).toLocaleTimeString()}`
	// );
	// const dubaiTime = currentTimem.toLocaleString("en-US", {
	//   timeZone: "Asia/Dubai",
	// });

	// console.log("currentTimem", currentTimem);
	console.log("setTimeInputShift0000", sh_time);
	const dateObj = new Date(timeInput);
	// console.log("new Date(timeInput)", timeInput);
	const hoursD = hourShow;
	const minutesD = minShow;
	const time = `${hoursD}:${minutesD}`;
	console.log("localTime", time);

	const [errorEmail, setErrorEmail] = useState("");
	const [errorName, setErrorName] = useState("");
	const [errorPhone, setErrorPhone] = useState("");

	const checkValEmail = email.length > 0 && !/\S+@\S+\.\S+/.test(email);

	const checkInputs = () => {
		if (email.length > 0 && !/\S+@\S+\.\S+/.test(email)) {
			setErrorEmail("The email must be a valid email address.");
		} else {
			setErrorEmail("");
		}

		if (name.length < 3 && use_shifts_logic) {
			setErrorName("The name must be a valid name");
		} else {
			setErrorName("");
		}
		if (phone.length < 3 && use_shifts_logic) {
			setErrorPhone("The phone number must be a valid phone number");
		} else {
			setErrorPhone("");
		}
	};

	useEffect(() => {
		const day_ = new Date(dateInput).getDate().toString().padStart(2, "0");
		const month = (new Date(dateInput).getMonth() + 1)
			.toString()
			.padStart(2, "0");
		const year = new Date(dateInput)
			.getFullYear()
			.toString()
			.padStart(2, "0");
		const formatDate = `${year}-${month}-${day_}`;
		console.log("dateInputdateInputdateInput", dateInput);
		if (working_times) {
			handleDate();
		}
		setDateInput(formatDate);
	}, [dateInput]);

	useEffect(() => {
		if (booking_button_link) {
			const urlParams = new URLSearchParams(
				booking_button_link.split("?")[1]
			);
			const ref = urlParams.get("reservation_ref");

			axios
				.post(`/${room_uuid}/restaurant/table-check/store-ref`, {
					restaurant_id: params.restaurant_id,
					ref: ref,
				})
				.then((res) => {
					console.log("res", res);
				})
				.catch((err) => {
					console.log("err", err);
				});
		}
	}, [booking_button_link, params.restaurant_id, room_uuid]);

	const dataForm = {
		date: dateInput,
		name: name,
		time: use_shifts_logic ? sh_time : timeInput,
		people_no: numberPeople,
		restaurant_id: params.restaurant_id,
		email: email,
		phone: phone,
		note: note,
	};

	const handelBook = (e) => {
		e.preventDefault();

		if (isOff == 0) {
			if (!use_shifts_logic && timeInput > end_time) {
				toast.current.show({
					severity: "warn",
					summary: "Warning",

					detail: `${t(
						"The booking time must be between"
					)} ${start_time_show} ${t("and")} ${end_time_show}`,
					life: 5000,
				});
			} else if (!use_shifts_logic && start_time > timeInput) {
				toast.current.show({
					severity: "warn",
					summary: "Warning",

					detail: `${t(
						"The booking time must be between"
					)} ${start_time_show} ${t("and")} ${end_time_show}`,
					life: 5000,
				});
			} else if (sh_time == null && shifts?.length > 0) {
				toast.current.show({
					severity: "warn",
					summary: "Warning",

					detail: `Please select a time`,
					life: 5000,
				});
			} else {
				checkInputs();
				if (use_shifts_logic) {
					if (
						!checkValEmail &&
						phone.length > 2 &&
						name?.length > 2
					) {
						setLoading(true);
						axios
							.post(
								`/${room_uuid}/restaurant/book_table`,
								dataForm
							)
							.then((res) => {
								console.log("res", res);
								if (res.data.status == false) {
									toast.current.show({
										severity: "error",
										summary: "Error",
										detail: t(`${res.data.message}`),
										life: 5000,
									});
								}
								if (res.data.status == true) {
									ReactGA.event({
										category: "Book table ",
										action: "Click",
										value: ``,
									});
									navigate(
										`/${room_uuid}/HKOrderPlaced?theme=true`
									);
								}
								setLoading(false);
							})
							.catch((err) => {
								console.log("err", err);
								setLoading(false);
							});
					}
				} else {
					if (!checkValEmail) {
						setLoading(true);
						axios
							.post(
								`/${room_uuid}/restaurant/book_table`,
								dataForm
							)
							.then((res) => {
								console.log("res", res);
								if (res.data.status == false) {
									toast.current.show({
										severity: "error",
										summary: "Error",
										detail: t(`${res.data.message}`),
										life: 5000,
									});
								}
								if (res.data.status == true) {
									ReactGA.event({
										category: "Book table ",
										action: "Click",
										value: ``,
									});
									navigate(
										`/${room_uuid}/HKOrderPlaced?theme=true`
									);
								}
								setLoading(false);
							})
							.catch((err) => {
								console.log("err", err);
								setLoading(false);
							});
					}
				}
			}
		} else {
			toast.current.show({
				severity: "warn",
				summary: "Warning",
				detail: `We are off on ${day_name} at ${timeInput}, please choose another time`,
				life: 5000,
			});
		}
	};
	// console.log("cur", new Date() );
	// console.log("cur___", new Date(Date.now() + (3600 * 1000 * 25)) );

	useEffect(() => {
		console.log("timeInput", timeInput);

		const time = `${new Date(timeInput)
			.getHours()
			.toString()
			.padStart(2, "0")}:${new Date(timeInput)
			.getMinutes()
			.toString()
			.padStart(2, "0")}`;

		// setTimeInput(time);
		console.log("time 000", time);
		console.log("end 000", end_time);
		console.log("hourShow 000", hourShow);
		console.log("minShow 000", minShow);
		const time_ = time.split(":");

		sethourShow(time_[0]);
		setMinShow(time_[1]);
	}, []);

	const [showInput, setShowInput] = useState(false);
	// console.log("The timeInput", timeInput);

	const [isloading, setisLoading] = useState(true);

	const handleLoad = () => {
		setisLoading(false);
	};

	return (
		<div className={theme ? "food book-table" : "book-table"}>
			{theme ? (
				<Title title={"Restaurant Booking"} />
			) : (
				<HeaderInner
					headerName={"Restaurant Booking"}
					bgColor={bgColor}
				/>
			)}
			{isLoading ? (
				<CustomBusinessServiceLoader
					businessName={businessName}
					loader={"spa"}
				/>
			) : booking_button_link ? (
				<div className="res_book" style={{ marginTop: "70px" }}>
					{isloading && (
						<div
							style={{
								position: "absolute",
								top: 0,
								left: 0,
								right: 0,
								bottom: 0,
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
							}}
						>
							<div className="loader"></div>
						</div>
					)}
					<Iframe
						url={booking_button_link}
						// url="https://www.wikipedia.org/"
						width="100%"
						// height="80vh"
						id=""
						// className="iframe_layar"
						display="block"
						onLoad={handleLoad}

						// position="relative"
					/>
				</div>
			) : (
				<main className="bg-white  pad-top-0 back-white book_">
					<Toast ref={toast} position="bottom-center" />

					{theme ? (
						<BannerHome
							className={"bot_rad"}
							headerImages={images}
							isVideo={isVideo}
							isOverllay={true}
						/>
					) : (
						<Carosle headerImages={images} isVideo={isVideo} />
					)}

					<div className="container p-2 mt-2">
						<form>
							<CalendarComponent
								date={dateInput}
								setDate={setDateInput}
							/>
							{use_shifts_logic &&
							showSlotMsg &&
							shifts?.length == 0 ? (
								<h6 className="slot_text">
									{" "}
									{t("No available slots")}
								</h6>
							) : null}
							{use_shifts_logic > 0 ? (
								<ShiftsData
									date={dateInput}
									shifts={shifts}
									loading={shLoading}
									setTimeInput={setShTime}
								/>
							) : (
								<div className="time_res">
									<div className="time_box">
										<p className="">{t("Booking Time")}</p>
										<div className="div_time">
											<span className="hour">
												{hourShow}
											</span>
											<span className="doots">:</span>
											<span className="hour">
												{minShow}
											</span>
										</div>
									</div>

									<div className="time_box mr">
										<label
											onClick={() => {
												if (!showInput) {
													setShowInput(true);
												}
												// Ensure the input is rendered before focusing
												setTimeout(() => {
													if (tineRif.current) {
														// tineRif.current.focus();
														tineRif.current.click();
													}
													// tineRif.current.addEventListener(
													//   "change",
													//   setShowInput(false)
													// );

													// return () => {
													//   // Cleanup: remove event listener
													//   tineRif.current.removeEventListener(
													//     "change",
													//     setShowInput(false)
													//   );
													// };
												}, 0);
											}}
											// htmlFor="time-input"
											className={`input_time_change ${
												showInput ? "notOpac" : ""
											}`}
										>
											<span className="btn">
												{t("Change")}
											</span>
											{showInput && (
												<Form.Control
													type="time"
													className="hide_inp"
													value={timeInput}
													ref={tineRif}
													// onSelect={() => setShowInput(false)}
													onBlur={() =>
														setShowInput(false)
													}
													onChange={handleTime}
												/>
											)}
										</label>
									</div>
								</div>
							)}

							<div className="people_count all_width">
								<p className="">{t("Number of People")}</p>

								<ul>
									<li
										className={
											numberPeople == "1" ? "active" : ""
										}
										onClick={() => setNumberPeople("1")}
									>
										1
									</li>
									<li
										className={
											numberPeople == "2" ? "active" : ""
										}
										onClick={() => setNumberPeople("2")}
									>
										2
									</li>
									<li
										className={
											numberPeople == "3" ? "active" : ""
										}
										onClick={() => setNumberPeople("3")}
									>
										3
									</li>
									<li
										className={
											numberPeople == "4" ? "active" : ""
										}
										onClick={() => setNumberPeople("4")}
									>
										4
									</li>
									<li
										className={
											numberPeople == "" ? "active" : ""
										}
									>
										<DropdownButton
											id="dropdown-basic-button"
											title="+"
											className={
												Number(numberPeople) > 4
													? "active"
													: ""
											}
										>
											<Dropdown.Item
												onClick={() =>
													setNumberPeople("5")
												}
											>
												5
											</Dropdown.Item>
											<Dropdown.Item
												onClick={() =>
													setNumberPeople("6")
												}
											>
												6
											</Dropdown.Item>
											<Dropdown.Item
												onClick={() =>
													setNumberPeople("7")
												}
											>
												7
											</Dropdown.Item>
											<Dropdown.Item
												onClick={() =>
													setNumberPeople("8")
												}
											>
												8
											</Dropdown.Item>
											<Dropdown.Item
												onClick={() =>
													setNumberPeople("9")
												}
											>
												9
											</Dropdown.Item>
											<Dropdown.Item
												onClick={() =>
													setNumberPeople("10")
												}
											>
												10
											</Dropdown.Item>
										</DropdownButton>
									</li>
									{/* {showList ? (
                    <>
                      <li
                        className={numberPeople == "4" ? "active" : ""}
                        onClick={() => setNumberPeople("4")}
                      >
                        4
                      </li>
                      <li
                        className={numberPeople == "5" ? "active" : ""}
                        onClick={() => setNumberPeople("5")}
                      >
                        5
                      </li>
                      <li
                        className={numberPeople == "6" ? "active" : ""}
                        onClick={() => setNumberPeople("6")}
                      >
                        6
                      </li>
                      <li
                        className={numberPeople == "7" ? "active" : ""}
                        onClick={() => setNumberPeople("7")}
                      >
                        7
                      </li>
                      <li
                        className={numberPeople == "8" ? "active" : ""}
                        onClick={() => setNumberPeople("8")}
                      >
                        8
                      </li>
                      <li
                        className={numberPeople == "9" ? "active" : ""}
                        onClick={() => setNumberPeople("9")}
                      >
                        9
                      </li>
                      <li
                        className={numberPeople == "10" ? "active" : ""}
                        onClick={() => setNumberPeople("10")}
                      >
                        10
                      </li>
                    </>
                  ) : (
                    <li onClick={() => setShowList(true)}>+</li>
                    )} */}
								</ul>
							</div>

							<Row className="px-2">
								<Col xs="12">
									<Form.Group
										className="mb-3 dark-placeholder"
										controlId="formBasicname"
									>
										<Form.Control
											type="text"
											className=""
											onChange={(e) =>
												setName(e.target.value)
											}
											placeholder={t(
												`${
													use_shifts_logic
														? "Your Name"
														: "Your Name (Optional)"
												}`
											)}
										/>
									</Form.Group>
									{/* {errorEmail != "" && (
                    <span className="err_msg">{errorEmail}</span>
                  )} */}
									{errorName != "" && (
										<span className="err_msg">
											{t(`${errorName}`)}
										</span>
									)}
								</Col>
								<Col xs="12">
									<Form.Group
										className="mb-3 dark-placeholder"
										controlId="formBasicname"
									>
										<Form.Control
											type="email"
											className=""
											onChange={(e) =>
												setEmail(e.target.value)
											}
											placeholder={t(
												"Your Email (Optional)"
											)}
										/>
									</Form.Group>
									{errorEmail != "" && (
										<span className="err_msg">
											{t(`${errorEmail}`)}
										</span>
									)}
								</Col>
								<Col xs="12">
									<Form.Group
										className="mb-3 dark-placeholder"
										controlId="formBasicname"
									>
										<PhoneInputComponent
											value={phone}
											onChange={(e) => setPhone(e)}
										/>
									</Form.Group>
									{errorPhone != "" && (
										<span className="err_msg">
											{t(`${errorPhone}`)}
										</span>
									)}
								</Col>
								<Col xs="12">
									<Form.Group
										className="mb-2 dark-placeholder"
										controlId="formBasicname"
									>
										<Form.Control
											rows={2}
											onChange={(e) =>
												setNote(e.target.value)
											}
											as="textarea"
											placeholder={t(
												"Note/Special Request (Optional)"
											)}
											style={{ height: "100px" }}
										/>
									</Form.Group>
								</Col>
							</Row>
							<div className="padd-div text-center ">
								<Button
									className="reg-btn order-btn less-pad bg-text"
									disabled={
										loading ||
										(use_shifts_logic &&
											shifts?.length == 0)
									}
									onClick={handelBook}
								>
									{t("Book")}
								</Button>
							</div>
						</form>
					</div>
				</main>
			)}
			{theme && <NewFooter />}
		</div>
	);
};

export default BookTable;
