import React, { useEffect, useRef, useState } from "react";
import {
	Link,
	useLocation,
	useNavigate,
	useParams,
	useSearchParams,
} from "react-router-dom";
import HeaderInner from "../../Components/HeaderInner";

import Carosle from "../../Components/Carosle";
import CarRentalLoader from "../../Components/Loaders/CarRentalLoader";
import {
	ShowRestaurantCategories,
	ShowRestaurantSubCategories,
} from "../../API/In-Room-DiningAPI";
import Search from "../../Components/Search";
import { Fade, Zoom } from "react-reveal";
import CustomBusinessServiceLoader from "../../Components/Loaders/CustomBusinessServiceLoader";
import { useBusinessNameContext } from "./../../context/BusinessName";

function RestaurantSubCat() {
	const { businessName, setBusinessName } = useBusinessNameContext();

	const business_name = localStorage.getItem("business_name");
	const [enableClass, setEnableClass] = useState(true);
	// const [restaurantCategories, setRestaurantCategories] = useState();
	const [loading, setLoading] = useState(false);
	// const [bgColor, setBgColor] = useState();
	// const [color, setColor] = useState();
	const [nameHeader, setHeaderName] = useState();
	// const [images, setImages] = useState();
	const location = useLocation();
	const rawPath = location.pathname;
	const room_uuid = rawPath.split("/")[1];
	const params = useParams();
	const navigate = useNavigate();
	// console.log(params);
	// setTimeout(() => {
	//   setEnableClass(false);

	let isbooking = rawPath.includes("RestaurantBooking");
	// console.log("rawPath", rawPath.includes("ResteaurantBooking"));
	// }, 3000);
	const lang = localStorage.getItem("lang");
	let restaurant;
	let restaurantCategories;
	let images = [];
	let isVideo = false;
	let bgColor;
	let color;
	let terms_and_conditions;
	let text;
	let restaurant_info;
	const { data, isLoading, error } = ShowRestaurantSubCategories(
		room_uuid,
		params.id,
		lang,
		params.cat_id,
		(isbooking = isbooking ? "&link_type=menu" : "")
	);

	if (!isLoading) {
		if (data.data.status == false) {
			navigate(`/${room_uuid}/qr-scan`);
		} else if (!error) {
			restaurant = data.data.data;
			restaurantCategories = restaurant.categories;
			// setHeaderName=restaurant.center_header;
			// let restaurant_images = [...restaurant.restaurant?.images]
			// isVideo = false;
			// images = restaurant.banners;
			terms_and_conditions = restaurant.restaurant.terms_and_conditions;
			restaurant_info = restaurant.restaurant;
			isVideo = restaurant.restaurant.real_video ? true : false;
			if (restaurant.restaurant.real_video) {
				if (restaurant.banners?.length > 0) {
					images = [
						{ image: restaurant.restaurant.real_video },
						...restaurant?.banners,
					];
				}
			} else {
				if (restaurant.banners?.length > 0) {
					images = restaurant.banners;
				}
			}
			// console.log("imagesimagesimages", images);
			color = data.data.data.color;
			bgColor = data.data.data.header_color;
			text = data.data.data.center_header;

			if (restaurantCategories?.length == 1) {
				if (isbooking) {
					if (restaurantCategories[0]?.count_sub_categories != 0) {
						navigate(
							`/${room_uuid}/RestaurantBooking/RestaurantSubCat/${params.id}/${restaurantCategories[0].id}`,
							{ replace: true }
						);
					} else {
						navigate(
							`/${room_uuid}/RestaurantBooking/RestaurantSubMenu/${params.id}/${restaurantCategories[0].id}`,
							{ replace: true }
						);
					}
				} else {
					if (restaurantCategories[0]?.count_sub_categories != 0) {
						navigate(
							`/${room_uuid}/RestaurantSubCat/${params.id}/${restaurantCategories[0].id}`,
							{ replace: true }
						);
					} else {
						navigate(
							`/${room_uuid}/RestaurantSubMenu/${params.id}/${restaurantCategories[0].id}`,
							{ replace: true }
						);
					}
				}
			}
			console.log("restaurant_imagesrestaurant_images", images);
		}
	}

	// useEffect(() => {
	//   const getData = async () => {
	//     setLoading(true)
	//     axios
	//       .get(`/${room_uuid}/restaurant/categories/${params.id}`)
	//       .then((res) => {
	//         const data = res.data.data;
	//         setRestaurantCategories(data.categories);
	//         setHeaderName(data.center_header);
	//         setBgColor(data.header_color);
	//         setColor(data.color);
	//         setImages(data.restaurant?.images)
	//         setLoading(false)

	//       });
	//   };
	//   getData();
	// }, []);
	// console.log('imags', images);
	useEffect(() => {
		window.scrollTo({
			top: 0,
			left: 0,
		});
	}, []);
	const imageref = useRef();
	const [height_res, setheight_res] = useState();
	useEffect(() => {
		console.log("imageref00");
		if (imageref.current) {
			setheight_res(imageref.current.clientWidth);
			console.log("imageref", imageref.current.clientWidth);
		}
	}, [data]);
	return (
		<>
			<HeaderInner bgColor={bgColor} color={color} headerName={text} />
			<main className="bg-white pad-top-0 resturant_category">
				{isLoading ? (
					<CustomBusinessServiceLoader
						businessName={businessName}
						loader={"car_rental"}
					/>
				) : (
					<>
						{images?.length > 0 && (
							<header className="img-header m-0">
								<Carosle
									headerImages={images}
									isVideo={isVideo}
								/>
							</header>
						)}
						{/* <div className="Search-bar less mt-4">
              <input
                className="form-control"
                type="search"
                placeholder="Search for Dishes, Drinks ... "
                disabled
              />
              <i class="fa fa-search" aria-hidden="true"></i>
            </div> */}
						<Search
							placeholder="Search for Categories..."
							room_uuid={room_uuid}
							category="Restaurant"
						/>
						{terms_and_conditions != "" &&
							(terms_and_conditions != undefined ? (
								<>
									{/* <p className="text-center">Terms & Conditions</p> */}
									<div
										className="mx-4 divhtml"
										dangerouslySetInnerHTML={{
											__html: terms_and_conditions,
										}}
									/>
								</>
							) : null)}
						<ul
							className={`${
								restaurant_info?.squared_categories == 1
									? "res_sp"
									: ""
							}  display-list two-items restaurant-list mt-2 v2 ${
								restaurant_info?.resturant_name.includes(
									"McDonald"
								)
									? "mcdonald"
									: ""
							} `}
						>
							{restaurantCategories
								? restaurantCategories.map((category) => {
										return (
											<Zoom>
												<li
													className={
														category?.is_closed
															? "closed restaurant-option"
															: "restaurant-option"
													}
												>
													<Link
														to={
															!isbooking
																? category.count_sub_categories !=
																  0
																	? `/${room_uuid}/RestaurantSubCat/${params.id}/${category.id}`
																	: `/${room_uuid}/RestaurantSubMenu/${params.id}/${category.id}`
																: category.count_sub_categories !=
																  0
																? `/${room_uuid}/RestaurantBooking/RestaurantSubCat/${params.id}/${category.id}`
																: `/${room_uuid}/RestaurantBooking/RestaurantSubMenu/${params.id}/${category.id}`
														}
														ref={imageref}
														style={
															restaurant_info?.squared_categories ==
															1
																? {
																		height: `${height_res}px`,
																  }
																: {
																		height: `140px`,
																  }
														}
													>
														<div className="closed-tag"></div>{" "}
														<div></div>
														{category?.is_closed && (
															<span className="open_at">
																{category.opens_at
																	? `Opens at ${category.opens_at}`
																	: `Closed`}
															</span>
														)}
														<div
															className="img-pnl"
															// style={{
															//   backgroundImage: `url(${category.image})`,
															// }}
														>
															<img
																src={encodeURI(
																	category.image
																)}
																alt=""
															/>
														</div>
														{/* {console.log(category.image.replace(/ /g, "%20"))} */}
														<div className="bg-layer"></div>
														<p>{category.name}</p>
													</Link>
												</li>
											</Zoom>
										);
								  })
								: null}
						</ul>
					</>
				)}
			</main>
		</>
	);
}
export default RestaurantSubCat;
