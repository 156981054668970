import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Overllay from "../../assets/new_imgs/Rectangle 1072.png";

import { useEffect } from "react";
import SpaLoader from "../../Components/Loaders/SpaLoader";
import { ShowEntertainment } from "../../API/EntertainmentAPI";
import Title from "../../new-components/title/Title";

function EntertainmentService() {
	const [title, setTitle] = useState(false);
	// const [entertainmentCategories, setEntertainmentCategories] = useState();
	const location = useLocation();
	const rawPath = location.pathname;
	const room_uuid = rawPath.split("/")[1];
	const navigate = useNavigate();

	const lang = localStorage.getItem("lang");

	useEffect(() => {
		window.scrollTo({
			top: 0,
			left: 0,
		});
	}, []);
	let flowers;
	let entertainmentCategories;
	const { data, isLoading, error } = ShowEntertainment(room_uuid, lang);
	if (!isLoading) {
		if (error) {
			if (error?.response.status === 404) {
				navigate(`/not-found`);
			}
		} else {
			if (data.data.status === false) {
				navigate(`/${room_uuid}/qr-scan`);
			} else {
				flowers = data.data.data;
				entertainmentCategories = flowers?.entertainment_companies;
				console.log(entertainmentCategories);
				if (entertainmentCategories?.length === 1) {
					navigate(
						`/${room_uuid}/entertainment/categories/${entertainmentCategories[0].uuid}`,
						{ replace: true }
					);
				}
			}
		}
	}

	return (
		<div className="food">
			<Title title={title} />
			<div className="box_food">
				{isLoading ? (
					<SpaLoader />
				) : entertainmentCategories.length > 1 ? (
					entertainmentCategories.map((x, idx) => {
						return (
							<div key={idx}>
								<Link
									to={`/${room_uuid}/entertainment/categories/${x.uuid}`}
								>
									<img
										src={x?.logo}
										alt=""
										className="booking_img"
									/>
									<img
										src={Overllay}
										alt=""
										className="overlay"
									/>
									<div className="text_food">
										<h5 className="">{x.name}</h5>
									</div>{" "}
								</Link>
							</div>
						);
					})
				) : null}
			</div>
		</div>
	);
}
export default EntertainmentService;
