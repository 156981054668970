import React, { useEffect, useRef, useState } from "react";
import {
	Link,
	useLocation,
	useNavigate,
	useParams,
	useSearchParams,
} from "react-router-dom";
import HeaderInner from "../../Components/HeaderInner";
import Carosle from "../../Components/Carosle";
import CarRentalLoader from "../../Components/Loaders/CarRentalLoader";
import { ShowRestaurantCategories } from "../../API/In-Room-DiningAPI";
import Search from "../../Components/Search";
import { Fade, Zoom } from "react-reveal";
import CombImg from "../../assets/images/cambo_meal.jpeg";
import { t } from "i18next";
import CustomBusinessServiceLoader from "../../Components/Loaders/CustomBusinessServiceLoader";
import { useBusinessNameContext } from "./../../context/BusinessName";

function RestaurantMenu() {
	const { businessName, setBusinessName } = useBusinessNameContext();
	const location = useLocation();
	const rawPath = location.pathname;
	const room_uuid = rawPath.split("/")[1];
	const params = useParams();
	const navigate = useNavigate();
	let isbooking = rawPath.includes("RestaurantBooking");
	let lang = localStorage.getItem("lang");
	let restaurant;
	let restaurantCategories;
	let images = [];
	let isVideo = false;
	let bgColor;
	let color;
	let terms_and_conditions;
	let has_combo_meals;
	let text;
	let restaurant_info;
	const { data, isLoading, error } = ShowRestaurantCategories(
		room_uuid,
		params.id,
		lang,
		(isbooking = isbooking ? "?link_type=menu" : "")
	);

	if (!isLoading) {
		// console.log("stata", data.data.status);
		if (data.data.status == false) {
			navigate(`/${room_uuid}/qr-scan`);
		} else if (!error) {
			restaurant = data.data.data;
			restaurantCategories = restaurant.categories;
			has_combo_meals = restaurant.has_combo_meals;
			terms_and_conditions = restaurant.restaurant.terms_and_conditions;

			isVideo = restaurant.restaurant.real_video ? true : false;
			if (restaurant.restaurant.real_video) {
				if (restaurant.banners.length > 0) {
					images = [
						{ image: restaurant.restaurant.real_video },
						...restaurant.banners,
					];
				}
			} else {
				if (restaurant.banners.length > 0) {
					images = restaurant.banners;
				}
			}
			color = data.data.data.color;
			restaurant_info = restaurant.restaurant;
			bgColor = data.data.data.header_color;
			text = data.data.data.center_header;

			if (restaurantCategories?.length == 1) {
				if (isbooking) {
					if (restaurantCategories[0]?.count_sub_categories != 0) {
						navigate(
							`/${room_uuid}/RestaurantBooking/RestaurantSubCat/${params.id}/${restaurantCategories[0].id}`,
							{ replace: true }
						);
					} else {
						navigate(
							`/${room_uuid}/RestaurantBooking/RestaurantSubMenu/${params.id}/${restaurantCategories[0].id}`,
							{ replace: true }
						);
					}
				} else {
					if (restaurantCategories[0]?.count_sub_categories != 0) {
						navigate(
							`/${room_uuid}/RestaurantSubCat/${params.id}/${restaurantCategories[0].id}`,
							{ replace: true }
						);
					} else {
						navigate(
							`/${room_uuid}/RestaurantSubMenu/${params.id}/${restaurantCategories[0].id}`,
							{ replace: true }
						);
					}
				}
			}

			// window.scrollTo({
			//   top: 0,
			//   left: 0,
			// });
		}
	}

	useEffect(() => {
		window.scrollTo({
			top: 0,
			left: 0,
		});
	}, []);

	console.log("has_combo_meals", has_combo_meals);

	const imageref = useRef();
	const [height_res, setheight_res] = useState();
	useEffect(() => {
		console.log("imageref00");
		if (imageref.current) {
			setheight_res(imageref.current.clientWidth);
			console.log("imageref", imageref.current.clientWidth);
		}
	}, [data]);

	return (
		<>
			<HeaderInner bgColor={bgColor} color={color} headerName={text} />
			<main className="bg-white pad-top-0 resturant_category">
				{isLoading ? (
					<CustomBusinessServiceLoader
						businessName={businessName}
						loader={"car_rental"}
					/>
				) : (
					<>
						{images?.length > 0 && (
							<header className="img-header m-0">
								<Carosle
									headerImages={images}
									isVideo={isVideo}
								/>
							</header>
						)}

						<Search
							placeholder="Search for Categories..."
							room_uuid={room_uuid}
							category="Restaurant"
						/>
						{terms_and_conditions != "" &&
							(terms_and_conditions != undefined ? (
								<>
									{/* <p className="text-center">Terms & Conditions</p> */}
									<div
										className="mx-4 divhtml"
										dangerouslySetInnerHTML={{
											__html: terms_and_conditions,
										}}
									/>
								</>
							) : null)}
						<ul
							className={`${
								restaurant_info?.squared_categories == 1
									? "res_sp"
									: ""
							}  display-list two-items restaurant-list mt-2 v2 ${
								restaurant_info?.resturant_name.includes(
									"McDonald"
								)
									? "mcdonald"
									: ""
							} `}
						>
							{has_combo_meals && (
								<Zoom key={"001"}>
									<li className={"restaurant-option"}>
										<Link
											to={`/${room_uuid}/restaurant/combo-meals/${params.id}`}
										>
											<div className="closed-tag"></div>{" "}
											<div></div>
											<div className="img-pnl">
												<img src={CombImg} alt="" />
											</div>
											<div className="bg-layer"></div>
											<p>{t("Combo Meals")}</p>
										</Link>
									</li>
								</Zoom>
							)}
							{restaurantCategories
								? restaurantCategories.map((category, idx) => {
										// console.log(category.name, encodeURI(category.image));
										return (
											<Zoom key={idx}>
												<li
													className={
														category?.is_closed
															? "closed restaurant-option"
															: "restaurant-option"
													}
													// /:room_uuid/RestaurantBooking/RestaurantMenu/:id/:restaurnat_id/:cat_id
												>
													<Link
														to={
															!isbooking
																? category.count_sub_categories !=
																  0
																	? `/${room_uuid}/RestaurantSubCat/${params.id}/${category.id}`
																	: `/${room_uuid}/RestaurantSubMenu/${params.id}/${category.id}`
																: category.count_sub_categories !=
																  0
																? `/${room_uuid}/RestaurantBooking/RestaurantSubCat/${params.id}/${category.id}`
																: `/${room_uuid}/RestaurantBooking/RestaurantSubMenu/${params.id}/${category.id}`
														}
														ref={imageref}
														style={
															restaurant_info?.squared_categories ==
															1
																? {
																		height: `${height_res}px`,
																  }
																: {
																		height: `140px`,
																  }
														}
													>
														<div className="closed-tag"></div>{" "}
														<div></div>
														{category?.is_closed && (
															<span className="open_at">
																{category.opens_at
																	? `Opens at ${category.opens_at}`
																	: `Closed`}
															</span>
														)}
														<div
															className="img-pnl"
															// style={{
															//   backgroundImage: `url(${category.image})`,
															// }}
														>
															<img
																src={encodeURI(
																	category.image
																)}
																alt=""
															/>
														</div>
														{/* {console.log(category.image.replace(/ /g, "%20"))} */}
														<div className="bg-layer"></div>
														<p>{category.name}</p>
													</Link>
												</li>
											</Zoom>
										);
								  })
								: null}
						</ul>
					</>
				)}
			</main>
		</>
	);
}
export default RestaurantMenu;
