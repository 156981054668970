import React, {useContext, useEffect} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import HeaderInner from "../../Components/HeaderInner";
import SpaLoader from "../../Components/Loaders/SpaLoader";
import {Zoom} from "react-reveal";
import {ShowEntertainments} from "../../API/EntertainmentAPI";
import {ShowLuxurioss} from "../../API/LuxuriosAPI";
import Title from "../../new-components/title/Title";
import NewFooter from "../../NewFooter";
import Overllay from "../../assets/new_imgs/Rectangle 1072.png";
import {ThemeContext} from "../../context/ThemeContext";
import CustomBusinessServiceLoader from "../../Components/Loaders/CustomBusinessServiceLoader";
import {useBusinessNameContext} from "./../../context/BusinessName";

function Luxurioss() {
	const {businessName} = useBusinessNameContext();
	const location = useLocation();
	const rawPath = location.pathname;
	const room_uuid = rawPath.split("/")[1];
	const euuid = rawPath.split("categories/")[1];
	const navigate = useNavigate();
	// const new_design = localStorage.getItem("new_design");
	// const theme = new_design == "true";
	const {theme} = useContext(ThemeContext);
	const lang = localStorage.getItem("lang");

	let flowers;
	let bgColor;
	let color;
	let text;
	let entertainmentCategories;
	const {data, isLoading, error} = ShowLuxurioss(room_uuid, euuid, lang);
	if (!isLoading) {
		if (error) {
			if (error?.response.status == 404) {
				navigate(`/not-found`);
			}
		} else {
			if (data.data.status === false) {
				navigate(`/${room_uuid}/qr-scan`);
			} else {
				flowers = data.data.data;
				entertainmentCategories = flowers.luxurios_services;
				color = flowers.color;
				bgColor = flowers.header_color;
				text = flowers.title;

				console.log("ShowEntertainmentss", flowers);
			}
		}
	}

	// useEffect(() => {
	//   const getData = async () => {
	//     setLoading(true)
	//     axios
	//       .get(
	//         `/${room_uuid}/entertainment/categories/1bb893d8-e5b0-42ba-a684-7f563c2e8ca5`
	//       )
	//       .then((res) => {
	//         const data = res.data.data;
	//         setEntertainmentCategories(data.entertainment_categories);
	//         setTitle(data?.title)
	//         console.log("data,entertainment/companies", data);
	//         setLoading(false)
	//       });
	//   };
	//   getData();
	// }, []);
	useEffect(() => {
		window.scrollTo({
			top: 0,
			left: 0,
		});
	}, []);

	return (
		<div className={theme ? "food pb-4" : ""}>
			{theme ? <Title title={text} /> : <HeaderInner headerName={text} />}
			<main className="home-main bg-white pt-3">
				<div className="">
					{isLoading ? (
						<CustomBusinessServiceLoader
							businessName={businessName}
							loader={"spa"}
						/>
					) : entertainmentCategories ? (
						theme ? (
							<div className="box_food">
								{entertainmentCategories?.map((item, idx) => {
									return (
										<span
											key={idx}
											onClick={() =>
												navigate(
													`/${room_uuid}/luxurios/categories/${euuid}/${item.id}`,
													{
														state: {theme: true},
													}
												)
											}
										>
											<div
												style={{
													position: "relative",
													marginBottom: "25px",
												}}
											>
												<img
													src={item?.image}
													alt=""
													className="booking_img"
												/>
												<img
													src={Overllay}
													alt=""
													className="overlay"
												/>
												<div className="text_food">
													<h5 className="">
														{item.name}
													</h5>
												</div>{" "}
											</div>
										</span>
									);
								})}
							</div>
						) : (
							entertainmentCategories.map((x, idx) => {
								return (
									<div id={x.name} key={idx}>
										<Zoom>
											<div
												className="con-img-container v3"
												onClick={() =>
													navigate(
														`/${room_uuid}/luxurios/categories/${euuid}/${x.id}`
													)
												}
											>
												<div className="con-img">
													<img src={x.image} alt="" />
													<p></p>
												</div>
											</div>
											<div className="con-text-container">
												<p>{x.name}</p>
												<hr className="mb-0 dark" />
											</div>
										</Zoom>
									</div>
								);
							})
						)
					) : null}
				</div>
			</main>
			{theme && <NewFooter />}
		</div>
	);
}
export default Luxurioss;
