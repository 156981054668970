import React, { useRef, useState } from "react";
import { Button, Form } from "react-bootstrap";
import HeaderInner from "../../Components/HeaderInner";
import { useLocation, useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import { useEffect } from "react";
import { ShowLuggagePickup } from "../../API/Concierge";
import { useTranslation } from "react-i18next";
import axios from "../../axios";
import { Toast } from "primereact/toast";
import ReactGA from "react-ga4";

import SpaLoader from "../../Components/Loaders/SpaLoader";
import "../../new-pages/hotel-services/HotelServices.scss";
import Title from "../../new-components/title/Title";
import NewFooter from "../../NewFooter";
import BannerServices from "../../new-components/banner-home/BannerServices";
import { phoneFormat } from "../../constants/api";
import PhoneInputComponent from "../../Components/PhoneInputComponent";
import { useBusinessNameContext } from "./../../context/BusinessName";

import CustomBusinessServiceLoader from "../../Components/Loaders/CustomBusinessServiceLoader";

function ConciergeOrder() {
	const { businessName, setBusinessName } = useBusinessNameContext();
	const toast = useRef(null);
	const navigate = useNavigate();
	const location = useLocation();
	const rawPath = location.pathname;
	// console.log("params", location.search);
	// const theme = location.search;
	const new_design = localStorage.getItem("new_design");
	const theme = new_design === "true";
	const room_uuid = rawPath.split("/")[1];
	const [count, setCount] = useState(1);
	const [phone, setPhone] = useState();
	const [email, setEmail] = useState("");
	const [isDisabled, setIsDisabled] = useState(false);
	const [timeInput, setTimeInput] = useState();
	const [dateInput, setDateInput] = useState();
	const { t } = useTranslation();
	const handleDecrease = () => {
		setCount((prev) => {
			if (prev <= 0) {
				return 0;
			}
			return prev - 1;
		});
	};
	const handleIncrease = () => {
		setCount((prev) => prev + 1);
	};

	const handleTime = (e) => {
		setTimeInput(e.target.value);
	};
	const handleDate = (e) => {
		setDateInput(e.target.value);
	};

	useEffect(() => {
		const currentTime = new Date();

		// Format the time as HH:mm (hours and minutes)
		currentTime.setMinutes(currentTime.getMinutes() + 1);
		const hours = new Date(Date.now())
			.getHours()
			.toString()
			.padStart(2, "0");
		const minutes = currentTime.getMinutes().toString().padStart(2, "0");
		const formattedTime = `${hours}:${minutes}`;

		const currentDate = new Date();

		// Format the date as YYYY-MM-DD (required by input type="date")

		const formattedDate = currentDate.toISOString().slice(0, 10);

		setDateInput(formattedDate);
		setTimeInput(formattedTime);
	}, []);
	const currentTimem = new Date(`${dateInput}T${timeInput}`);
	// make the time by user device time
	const dubaiTime = currentTimem.toLocaleString("en-US", {
		// timeZone: "Asia/Dubai",
	});

	const dateObj = new Date(dubaiTime);
	const hoursD = dateObj.getHours().toString().padStart(2, "0");
	const minutesD = (dateObj.getMinutes() + 1).toString().padStart(2, "0");
	const time = `${hoursD}:${minutesD}`;

	const [errorEmail, setErrorEmail] = useState("");

	const checkValEmail = email.length > 0 && !/\S+@\S+\.\S+/.test(email);
	// console.log("checkemail", !/\S+@\S+\.\S+/.test(spaService.email));
	const checkInputs = () => {
		if (email.length > 0 && !/\S+@\S+\.\S+/.test(email)) {
			setErrorEmail("The email must be a valid email address.");
		} else {
			setErrorEmail("");
		}
	};

	const sendData = {
		type_id: "luggage-pickup",
		luggage_count: count,
		time: time,
		date: dateInput,
		email,
		phone,
	};
	const handelBook = () => {
		checkInputs();
		if (!checkValEmail) {
			ReactGA.event({
				category: "Book service concierge ",
				action: "Click",
				value: `concierge type: luggage-pickup`,
			});
			setIsDisabled(true);
			axios
				.post(`/${room_uuid}/concierge/book-service`, sendData, {
					headers: {
						lang: lang,
					},
				})
				.then((res) => {
					if (res.data.status === false) {
						toast.current.show({
							severity: "error",
							summary: t("Error"),
							detail: t(`${res.data.message}`),
							life: 4000,
						});
					}
					// console.log("res", res);
					if (res.data.status) {
						navigate(`/${room_uuid}/HKOrderPlaced`, {
							state: {
								metadata: {
									res: res.data.data,
								},
							},
						});
					}
					setIsDisabled(false);
				})
				.catch((err) => {
					setIsDisabled(false);
					console.log(err);
				});
		}
	};
	const lang = localStorage.getItem("lang");
	let luggage_pickup;
	let text;
	const { data, isLoading } = ShowLuggagePickup(room_uuid, lang);
	if (!isLoading) {
		let newData = data.data.data;
		luggage_pickup = newData;
		text = newData?.center_header;

		// console.log("ShowConcierge", newData);
	}
	return (
		<div className={theme ? "food pb-0" : ""}>
			{theme ? <Title title={text} /> : <HeaderInner headerName={text} />}
			<main className="home-main bg-white back-white book-table pad-top-0  luggage_pickup">
				<Toast ref={toast} position="bottom-center" />

				{isLoading ? (
					<CustomBusinessServiceLoader
						businessName={businessName}
						loader={"spa"}
					/>
				) : (
					<>
						{theme ? (
							<BannerServices
								headerImages={[luggage_pickup.image]}
							/>
						) : (
							<div className="con-order-img">
								<img src={luggage_pickup.image} alt="" />
							</div>
						)}
						<p className="bk-title mt-3">
							{t(`${luggage_pickup?.title}`)}
						</p>
						<div className="bk-date">
							<Form.Group
								className="mb-3 row align-items-center justify-content-start"
								controlId="formBasicname"
							>
								<p className="col-3 m-0 pl-4"> {t("Date")} </p>
								<Form.Control
									type="date"
									value={dateInput}
									onChange={handleDate}
									className="col-8 ml-2 date-input dt-input"
								/>
							</Form.Group>
						</div>
						<div className="bk-time mb-4">
							<Form.Group
								className="mb-3 row align-items-center justify-content-start "
								controlId="formBasicname"
							>
								<p className="col-3 m-0 pl-4 no-break">
									{" "}
									{t("Time")}{" "}
								</p>
								<Form.Control
									type="time"
									value={timeInput}
									onChange={handleTime}
									className="col-8 ml-2 time-input dt-input"
								/>
							</Form.Group>
						</div>
						<div className="d-flex number_luggage align-items-center pr-3 mb-3">
							<p
								class={`m-0 pl-2 ${
									lang == "AR" ? "pr-4" : "pr-0"
								}`}
							>
								{" "}
								{t("Number of Luggage")}{" "}
							</p>
							<div className="num quantity no-br">
								<button
									className="removeItem"
									onClick={handleDecrease}
									id="plus"
								>
									<i className="fa fa-minus txt-primary"></i>
								</button>
								<input
									type="text"
									className="purchase_quantity up"
									id="purchase_quantity"
									min="1"
									max="99"
									delta="0"
									cost=""
									name="people_no"
									value={count}
									onChange={(e) =>
										setCount(parseInt(e.target.value))
									}
								/>
								<button
									className=" addItem"
									id="dec"
									onClick={handleIncrease}
								>
									<i className="fa fa-plus txt-primary"></i>
								</button>
							</div>
						</div>

						<div className="con-email px-2">
							<Form.Group
								className="mb-3  align-items-center dark-placeholder"
								controlId="formBasicname"
							>
								<Form.Control
									type="email"
									placeholder={t("Your Email (Optional)")}
									className="w-100 text-center py-3"
									onChange={(e) => setEmail(e.target.value)}
								/>
							</Form.Group>
							{errorEmail !== "" && (
								<span className="err_msg">
									{t(`${errorEmail}`)}
								</span>
							)}
						</div>
						<div className="con-phone">
							<Form.Group className="mb-3 w-100 px-2">
								<PhoneInputComponent
									className="ml-4"
									value={phone}
									onChange={(phone) => setPhone(phone)}
								/>
							</Form.Group>
						</div>
						<div className="padd-div text-center mt-5">
							<Button
								className="reg-btn book lg"
								// to={`/${room_uuid}/HKOrderPlaced`}
								onClick={handelBook}
								disabled={isDisabled}
							>
								{t("Book")}
							</Button>
						</div>
						{/* <div className="padd-div text-center">
              <h3 className="text-center">
                <TaxInclusive />
              </h3>
            </div> */}
						<div className="spacer-40 my-5"></div>
					</>
				)}
			</main>
			{theme && <NewFooter />}
		</div>
	);
}
export default ConciergeOrder;
