import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import HeaderInner from "../../Components/HeaderInner";
import Entertainment1 from "../../assets/images/Entertainment/Entertainment-1.png";
import Entertainment2 from "../../assets/images/Entertainment/Entertainment-2.png";
import Entertainment3 from "../../assets/images/Entertainment/Entertainment-3.png";
import Entertainment4 from "../../assets/images/Entertainment/Entertainment-4.png";
import axios from "../../axios";
import { useEffect } from "react";
import SpaLoader from "../../Components/Loaders/SpaLoader";
import { Zoom } from "react-reveal";
import { ShowEntertainment } from "../../API/EntertainmentAPI";
import { ShowHH_HouseKeeping } from "../../API/HH_HouseKeepingAPI";
import CustomBusinessServiceLoader from "../../Components/Loaders/CustomBusinessServiceLoader";
// import { useBusinessNameContext } from "./context/BusinessName";
import { useBusinessNameContext } from "./../../context/BusinessName";

function HH_HouseKeeping() {
	const { businessName, setBusinessName } = useBusinessNameContext();
	const [enableClass, setEnableClass] = useState(true);
	const [loading, setLoading] = useState(false);
	const [title, setTitle] = useState(false);
	// const [entertainmentCategories, setEntertainmentCategories] = useState();
	const location = useLocation();
	const rawPath = location.pathname;
	const room_uuid = rawPath.split("/")[1];
	const navigate = useNavigate();

	// console.log("rawPath", rawPath);
	const lang = localStorage.getItem("lang");

	// setTimeout(() => {
	//   setEnableClass(false);
	// }, 3000);

	let flowers;
	let bgColor;
	let color;
	let text;
	let entertainmentCategories;
	const { data, isLoading, error } = ShowHH_HouseKeeping(room_uuid, lang);
	console.log(isLoading, "isLoading");
	if (!isLoading && data) {
		console.log("ShowHH_HouseKeeping", data);
		if (error) {
			if (error?.response.status == 404) {
				navigate(`/not-found`);
			}
		} else {
			if (data.data.status === false) {
				navigate(`/${room_uuid}/qr-scan`);
			} else {
				flowers = data.data.data;
				entertainmentCategories = flowers?.companies;
				color = flowers?.color;
				bgColor = flowers?.header_color;
				text = flowers?.title;
				if (entertainmentCategories?.length == 1) {
					navigate(
						`/${room_uuid}/hh-house-keeping-companies/services/${entertainmentCategories[0].uuid}`,
						{ replace: true }
					);
				}
			}
			// console.log("ShowEntertainment", flowers);
		}
	}
	useEffect(() => {
		window.scrollTo({
			top: 0,
			left: 0,
		});
	}, []);
	if (isLoading) {
		return (
			<CustomBusinessServiceLoader
				businessName={businessName}
				loader={"spa"}
			/>
		);
	}
	return (
		<>
			<HeaderInner headerName={title} />
			<main className="home-main bg-white pt-3">
				<div className="">
					{entertainmentCategories &&
					entertainmentCategories.length > 1
						? entertainmentCategories.map((x, idx) => {
								return (
									<div id={x.name} key={idx}>
										<Zoom>
											<div
												className="con-img-container v3"
												onClick={() =>
													navigate(
														`/${room_uuid}/hh-house-keeping-companies/services/${x.uuid}`
													)
												}
											>
												<div className="con-img">
													<img src={x.logo} alt="" />
													<p></p>
												</div>
											</div>
											<div className="con-text-container">
												<p>{x.name}</p>
												<hr className="mb-0 dark" />
											</div>
										</Zoom>
									</div>
								);
						  })
						: null}
				</div>
				{/* <ul className="display-list one-item px-0 ">
          <li>
            <Link to="/EntertainmentDetail">
              <div
                className={enableClass ? "animated-background bg-skimmer" : ""}
              ></div>
              <div
                className="img-pnl"
                style={{
                  backgroundImage: `url(https://tdhbucket.s3.me-central-1.amazonaws.com/uploaded_files/images/entertainment_categories/images/jtyuvCwPw57u3i8yvfaPcRAjMYtR4luZ1pyP9dsN.png)`,
                }}
              ></div>
              <p>DESERT SAFARI</p>
            </Link>
            <hr className="mb-1 mt-4 dark" />
          </li>
          <li>
            <Link to="/EntertainmentDetail">
              <div
                className={enableClass ? "animated-background bg-skimmer" : ""}
              ></div>
              <div
                className="img-pnl"
                style={{ backgroundImage: `url(${Entertainment2})` }}
              ></div>
              <p>MARINA DHOW CRUISE</p>
            </Link>
            <hr className="mb-1 mt-4 dark" />
          </li>
          <li>
            <Link to="/EntertainmentDetail">
              <div
                className={enableClass ? "animated-background bg-skimmer" : ""}
              ></div>
              <div
                className="img-pnl"
                style={{ backgroundImage: `url(${Entertainment3})` }}
              ></div>
              <p>DUBAI CITY TOUR</p>
            </Link>
            <hr className="mb-1 mt-4 dark" />
          </li>
          <li>
            <Link to="/EntertainmentDetail">
              <div
                className={enableClass ? "animated-background bg-skimmer" : ""}
              ></div>
              <div
                className="img-pnl"
                style={{ backgroundImage: `url(${Entertainment4})` }}
              ></div>
              <p>MEGA YACHT DINNER CRUISE</p>
            </Link>
            <hr className="mb-1 mt-4 dark" />
          </li>
        </ul> */}
				{/* <div className="bg-layer"></div> */}
			</main>
		</>
	);
}
export default HH_HouseKeeping;
