import React, { useState, useCallback, useRef, useEffect } from "react";
import {
	Autocomplete,
	DirectionsRenderer,
	GoogleMap,
	Marker,
	StandaloneSearchBox,
	useJsApiLoader,
	useLoadScript,
} from "@react-google-maps/api";
import { FaRegCircle, FaPlusCircle, FaRoute } from "react-icons/fa";
import { IoTimeOutline } from "react-icons/io5";
import { useBusinessNameContext } from "./../../context/BusinessName";

import LocationTaxi from "../../assets/images/Location_taxi.svg";
import axios from "../../axios";
import SpaLoader from "../../Components/Loaders/SpaLoader";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import PhoneInputComponent from "../../Components/PhoneInputComponent";
import useDraggableScroll from "use-draggable-scroll";
import { Modal } from "react-bootstrap";

import Otp from "../../Components/Otp";
import { validateOTP } from "./../../utils/validation";
import CustomBusinessServiceLoader from "../../Components/Loaders/CustomBusinessServiceLoader";
const center = {
	lat: 31.7683, // موقع افتراضي (يمكن تغييره حسب موقعك)
	lng: 35.2137,
};

const TaxiNew = () => {
	const { isLoaded } = useJsApiLoader({
		googleMapsApiKey: "AIzaSyCGRRVL7FlEoJNCN9Ljk8xo4dF9k2pNu_I",
		libraries: ["places"],
	});

	const navigate = useNavigate();
	const location = useLocation();
	const params = useParams();
	const rawPath = location.pathname;
	const room_uuid = rawPath.split("/")[1];
	const { businessName, setBusinessName } = useBusinessNameContext();
	const [loading, setLoading] = useState(false);
	const [loadingPage, setLoadingPage] = useState(false);
	const [name, setName] = useState("");
	const [phone, setPhone] = useState("");
	const [email, setEmail] = useState("");

	const [carSelected, setCarSelected] = useState(null);
	const [time, setTime] = useState(null);
	const [addPoint, setAddPoint] = useState(false);
	const lang = localStorage.getItem("lang");

	const [addressMark, setAddressMark] = useState({
		address: "",
		lat: 0,
		lng: 0,
	});
	const [showOtp, setShowOtp] = useState(false);
	const [company, setCompany] = useState(null);
	const [verificationCode, setVerificationCode] = useState(null);
	const [otpError, setOtpError] = useState(null);
	useEffect(() => {
		const getData = async () => {
			setLoading(true);
			axios
				.get(
					`/${room_uuid}/hh_taxi/booking-page/${params?.company_uuid}`,
					{}
				)
				.then((res) => {
					if (res.data.status === false) {
						navigate(`/${room_uuid}/qr-scan`);
					} else {
						const data = res.data.data;
						console.log("ress", res);
						setCompany(res.data.data.company);
						setAddressMark({
							lat: Number(data?.lat),
							lng: Number(data?.long),
							address: data?.address,
						});
						map.panTo(addressMark);

						setLoading(false);
					}
				})
				.catch((err) => {
					if (err?.response.status == 404) {
						navigate(`/not-found`);
					}
				});
		};
		getData();
	}, []);

	console.log("addressMark", addressMark);

	const bookCar = () => {
		setLoadingPage(true);
		const ob = {
			company_name: company?.name,
			company_id: Number(company?.id),
			name: name,
			phone: phone,
			email: email,
			address: desMarkers.address,
			lat: desMarkers.lat.toString(),
			lng: desMarkers.lng.toString(),
			taxi_type: carSelected,
			verification_code: verificationCode,
			stops: points,
			redirect_url: `http://${window.location.host}/${room_uuid}/RedirectTaxi`,
		};

		axios
			.post(`/${room_uuid}/hh_taxi/hh-taxibooking-book`, ob, {})
			.then((res) => {
				const data = res.data.data;
				console.log("planned-order", res);

				if (data === "OTP Required") {
					setShowOtp(true);
				}

				if (res.data.message === "Wrong OTP") {
					setLoadingPage(false);
					setShowOtp(true);
					setOtpError(res.data.message);
				}
				if (res?.data?.data?.payment_url) {
					setShowOtp(false);
					window.location.replace(res?.data?.data?.payment_url);
				}
				setLoadingPage(false);
				// setStep("tow")
			})
			.catch((err) => {
				setLoadingPage(false);

				if (err?.response.status == 404) {
					navigate(`/not-found`);
				}
			});
	};
	const requestCar = () => {
		setLoadingPage(true);
		if (!validateEmail(email)) {
			setError("Invalid email address.");
			setLoadingPage(false);
			return;
		}
		axios
			.post(
				`/${room_uuid}/hh_taxi/planned-order`,
				{ ...desMarkers, email: email, name: name, phone: phone },
				{ headers: { lang: lang } },
				{}
			)
			.then((res) => {
				const data = res.data.data;
				console.log("planned-order", res);
				setOrderDetail(data);
				setCarSelected(data?.service_levels[0]?.type_id);
				setStops(data?.stops);
				setPoints(data?.stops);
				setTime(data?.service_levels[0]?.waiting_time_message);

				calculateRoute();

				setLoadingPage(false);
				setStep("tow");
			})
			.catch((err) => {
				if (err?.response.status == 404) {
					navigate(`/not-found`);
				}
			});
	};

	const handleClose = () => {
		setShowOtp(false);
	};
	const [step, setStep] = useState("one");

	const [map, setMap] = useState(/** @type google.maps.Map */ (null));
	const [directionsResponse, setDirectionsResponse] = useState(null);
	const [orderDetail, setOrderDetail] = useState({});
	const [duration, setDuration] = useState("");
	const [stops, setStops] = useState([]);
	const [error, setError] = useState("");
	const [desMarkers, setDesMarkers] = useState({
		lat: 0,
		lng: 0,
		long: 0,
		address: "",
	});

	const calculateRoute = async () => {
		if (!addressMark || !desMarkers) return;

		const directionsService = new window.google.maps.DirectionsService();
		const directionsRenderer = new window.google.maps.DirectionsRenderer(); // لإظهار المسار على الخريطة

		// تحويل مصفوفة النقاط إلى waypoints ديناميكيًا
		const waypoints = points.map((point) => ({
			location: new window.google.maps.LatLng(point.lat, point.lng),
			stopover: true,
		}));

		const result = await directionsService.route({
			origin: addressMark, // النقطة الأصلية
			destination: desMarkers, // الوجهة
			waypoints: waypoints, // نقاط إضافية على المسار
			travelMode: window.google.maps.TravelMode.DRIVING, // وضع القيادة
			optimizeWaypoints: true, // تحسين ترتيب النقاط
		});

		// عرض المسار على الخريطة
		directionsRenderer.setDirections(result);
		directionsRenderer.setMap(map); // map هو الخريطة التي أنشأتها سابقاً
	};

	/** @type React.MutableRefObject<HTMLInputElement> */
	const originRef = useRef();
	/** @type React.MutableRefObject<HTMLInputElement> */
	const pointRef = useRef();

	const ref = useRef(null);
	const { onMouseDown } = useDraggableScroll(ref);
	const handelPlaceChange = () => {
		const [place] = originRef.current.getPlaces();
		console.log("place", place);
		setDesMarkers({
			lat: place.geometry.location.lat(),
			lng: place.geometry.location.lng(),
			long: place.geometry.location.lng(),
			address: place.formatted_address,
		});

		map.panTo({
			lat: place.geometry.location.lat(),
			lng: place.geometry.location.lng(),
			address: place.formatted_address,
		});
		map.setZoom(17);
	};
	const [points, setPoints] = useState([]);

	console.log();

	const handelPlacePointChange = () => {
		const [place] = pointRef.current.getPlaces();
		console.log("place", place);
		setPoints([
			...points,
			{
				lat: place.geometry.location.lat(),
				lng: place.geometry.location.lng(),
				long: place.geometry.location.lng(),
				address: place.formatted_address,
			},
		]);

		if (points?.length > 0) {
			setLoadingPage(true);
			axios
				.post(
					`/${room_uuid}/hh_taxi/planned-order`,
					{
						...desMarkers,
						email: email,
						name: name,
						phone: phone,
						stops: points,
					},
					{ headers: { lang: lang } },
					{}
				)
				.then((res) => {
					const data = res.data.data;
					calculateRoute();
					console.log("planned-order", res);
					// setOrderDetail(data)
					// setCarSelected(data?.service_levels[0]?.type_id)
					// setTime(data?.service_levels[0]?.waiting_time_message)

					setLoadingPage(false);
					// setStep("tow")
					setAddPoint(false);
					setStops(points);
				})
				.catch((err) => {
					if (err?.response.status == 404) {
						navigate(`/not-found`);
					}
				});
		}
	};
	console.log("points", points);
	console.log("desMarkers", desMarkers);

	// if (loadingPage) {
	//     return (
	//         <div className="taxi_loader">
	//             <div className="loader"></div>
	//         </div>
	//     )
	// }

	console.log("orderDetail", orderDetail);

	const mapContainerStyle = {
		width: "100%",
		height: step == "one" ? "60vh" : "100vh",
	};
	const validateEmail = (email) => {
		const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Basic email format validation
		return emailRegex.test(email);
	};

	if (!isLoaded) {
		return (
			<CustomBusinessServiceLoader
				businessName={businessName}
				loader={"car_rental"}
			/>
		);
	}
	const handelBookCar = () => {
		if (!validateOTP(verificationCode, setOtpError)) return;

		setShowOtp(false);
		bookCar();
	};
	return (
		<div className="yango-page">
			<GoogleMap
				mapContainerStyle={mapContainerStyle}
				zoom={17}
				center={addressMark}
				options={{
					zoomControl: false,
					streetViewControl: false,
					mapTypeControl: false,
					fullscreenControl: false,
				}}
				onLoad={(map) => setMap(map)}
			>
				{step == "one" && (
					<>
						<Marker position={addressMark} />

						{desMarkers.lat != 0 && desMarkers.lng != 0 ? (
							<Marker position={desMarkers} />
						) : null}
					</>
				)}
				{directionsResponse && (
					<DirectionsRenderer
						directions={directionsResponse}
						options={{
							suppressMarkers: true, // إخفاء علامات البداية والنهاية
						}}
					/>
				)}
			</GoogleMap>
			{step == "tow" && (
				<div className="taxi-step-tow">
					<div className="st-point mb-1">
						<FaRegCircle /> {orderDetail?.source?.address}
					</div>
					{stops?.map((item) => {
						return (
							<div className="st-point mb-1">
								<FaRegCircle /> {item?.address}
							</div>
						);
					})}
					{addPoint ? (
						<div className="point-dev">
							<StandaloneSearchBox
								onLoad={(ref) => (pointRef.current = ref)}
								// onPlacesChanged={handelPlacePointChange}
							>
								<input
									placeholder="Where to?"
									className="point"
								/>
							</StandaloneSearchBox>
							<div className="taxi-bt">
								<button onClick={handelPlacePointChange}>
									Add <FaPlusCircle />
								</button>
							</div>
						</div>
					) : (
						<div className="taxi-bt">
							<button onClick={() => setAddPoint(true)}>
								Add <FaPlusCircle />
							</button>
						</div>
					)}
					<div className="end-point">
						<FaRegCircle /> {orderDetail?.destination?.address}
					</div>
					<span className="border-l-d"></span>
				</div>
			)}

			{step == "one" && (
				<div
					className="location_taxi"
					onClick={() => {
						map.panTo(addressMark);
						map.setZoom(17);
					}}
				>
					<img src={LocationTaxi} alt="" />
				</div>
			)}

			<div className="yango-taxi">
				{step == "one" && (
					<>
						<label htmlFor="">Where to?</label>

						<StandaloneSearchBox
							onLoad={(ref) => (originRef.current = ref)}
							onPlacesChanged={handelPlaceChange}
						>
							<input placeholder="Where to?" />
						</StandaloneSearchBox>

						<input
							type="text"
							placeholder="Your Name"
							value={name}
							onChange={(e) => setName(e.target.value)}
						/>
						<input
							type="text"
							placeholder="Your Email"
							value={email}
							onChange={(e) => {
								const value = e.target.value;
								setEmail(e.target.value);
							}}
						/>
						{error && <p className="text-danger">{error}</p>}
						<PhoneInputComponent
							placeholder="01214254"
							value={phone}
							onChange={(e) => setPhone(e)}
						/>
						<div>
							<button
								disabled={
									phone?.length < 11 || name.length == 0
								}
								onClick={requestCar}
							>
								Request a Car
							</button>
						</div>
					</>
				)}
				{step == "tow" && (
					<>
						<div className="time">
							<span>
								<IoTimeOutline />
								{time}
							</span>
							{/* <span><FaRoute />23 km</span> */}
						</div>
						<ul
							className="taxi-list search"
							ref={ref}
							onMouseDown={onMouseDown}
						>
							{orderDetail?.service_levels?.map((item) => {
								return (
									<li
										onClick={() => {
											setCarSelected(item?.type_id);
											setTime(item?.waiting_time_message);
										}}
									>
										<button
											className={`${
												carSelected == item?.type_id
													? "act"
													: ""
											}`}
										>
											<div className="d-flex align-items-center">
												<img
													src={item.icon}
													alt=""
													className="taxi-ic"
												/>
												<div>
													<h3>{item?.type}</h3>
													<p>{item?.price}</p>
												</div>
											</div>
										</button>
									</li>
								);
							})}
						</ul>

						<div>
							<button onClick={bookCar}>Book now</button>
						</div>
						<Modal
							show={showOtp}
							// onHide={}
							backdrop="static"
							keyboard={false}
							// className={theme ? "food-modal" : ""}
							style={{
								minHeight: "150px",
								height: "auto",
								marginTop: "150px",
							}}
						>
							<Modal.Body
								style={{
									padding: "20px",
									height: "auto",
								}}
							>
								<div className="otp_div">
									<Otp
										onChange={(e) => {
											setVerificationCode(e);
										}}
										onClose={handleClose}
										handleBook={handelBookCar}
										email={email}
										phone={phone}
										value={verificationCode}
										error={otpError}
									></Otp>
								</div>
							</Modal.Body>
						</Modal>
					</>
				)}
			</div>

			{loadingPage && (
				<div className="taxi_loader">
					<div className="loader"></div>
				</div>
			)}
		</div>
	);
};

export default TaxiNew;
