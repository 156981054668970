import React, {
	useState,
	useRef,
	useEffect,
	useLayoutEffect,
	useContext,
} from "react";
import HeaderInner from "../../Components/HeaderInner";
import { Button, Modal } from "react-bootstrap";
import ViewPharmacyCartModal from "../../Components/ViewPharmacyCartModal";
import PharmacyCartPost from "../../Components/PharmacyCartPost";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import useDraggableScroll from "use-draggable-scroll";
import { useCartStore } from "../../store/useStore";
import { PharmacySubMenuAPI, ShowPharmacySubMenu } from "../../API/Pharmacy";
import PharmacySubMenuLoader from "../../Components/Loaders/PharmacySubMenuLoader";
import ViewCart from "../../Components/ViewCart";
import { useInfiniteQuery } from "react-query";
import Search from "../../Components/Search";
import ReactGA from "react-ga4";
import Title from "../../new-components/title/Title";
import { ThemeContext } from "../../context/ThemeContext";
import CustomBusinessServiceLoader from "../../Components/Loaders/CustomBusinessServiceLoader";
import { useBusinessNameContext } from "./../../context/BusinessName";

function PharmacySubMenu() {
	const { businessName, setBusinessName } = useBusinessNameContext();

	const params = useParams();
	const [show, setShow] = useState(false);
	const navigate = useNavigate();
	const [selected, setSelected] = useState(params.id);

	// const [pharmacyItems, setPharmacyItems] = useState();
	const [total, setTotal] = useState(0);
	let ref = useRef(null);
	const { onMouseDown } = useDraggableScroll(ref);
	const { cart } = useCartStore((state) => ({
		cart: state.cart,
	}));
	const location = useLocation();
	const rawPath = location.pathname;
	const room_uuid = rawPath.split("/")[1];
	console.log("sel", selected);
	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);
	// const theme = location.state?.theme;
	// const new_design = localStorage.getItem("new_design");
	// const theme = new_design == "true";
	const { theme } = useContext(ThemeContext);
	const pharmacy_uuid = params.pharmacy_uuid;
	const lang = localStorage.getItem("lang");

	const {
		data,
		isLoading,
		isError,
		error,
		hasNextPage,
		isFetchingNextPage,
		fetchNextPage,
		refetch,
	} = useInfiniteQuery(
		`PharmacySubMenu-${lang}-${selected}`,
		({ pageParam = 1 }) =>
			PharmacySubMenuAPI({
				room_uuid,
				pharmacy_uuid,
				selected,
				pageParam,
				lang,
			}),
		{
			getNextPageParam: (data) => {
				const lastPage = data?.data?.data?.items?.links?.last_page;
				const currentPage =
					data?.data?.data?.items?.links?.current_page;
				return currentPage < lastPage ? currentPage + 1 : undefined;
			},
		}
	);

	let title;
	let terms_and_conditions;

	if (!isLoading) {
		if (data.pages[0].data.status == false) {
			navigate(`/${room_uuid}/qr-scan`);
		} else {
			// console.log("data", data.pages[0]);
			title = data?.pages[0]?.data.data.title;
			terms_and_conditions =
				data?.pages[0]?.data.data.pharmacy.terms_and_conditions;
		}
	}
	let loadMoreRef = useRef();

	useEffect(() => {
		if (!hasNextPage) {
			return;
		}

		const observer = new IntersectionObserver(
			(entries) =>
				entries.forEach(
					(entry) => entry.isIntersecting && fetchNextPage()
				),
			{
				root: null,
				margin: "0px",
				treshold: 1.0,
			}
		);

		const el = loadMoreRef && loadMoreRef.current;

		if (!el) {
			return;
		}

		observer.observe(el);
	}, [loadMoreRef.current, hasNextPage]);

	let h_ref = useRef(null);
	let divRef = useRef(null);
	let heightDiv;
	useEffect(() => {
		if (ref.current) {
			// console.log("ref.current", ref.current.children);

			const lists = ref.current.children;
			// console.log("ref", ref.current.children);
			// ref.current.children;
			Object.values(lists).map((list, idx) => {
				if (list.classList.contains("active")) {
					const itemWidth = list.offsetWidth;
					const scrollPosition = idx * itemWidth;

					ref.current.scrollTo({
						left: scrollPosition,
						behavior: "smooth",
					});
					//     // Your logic when the "active" class is present
					//     // console.log('The first child has the "active" class');
				}
			});
		}
		// if (h_ref.current) {
		//   heightDiv = h_ref.current.clientHeight;
		//   divRef.current.style.marginTop = `${heightDiv}px`;
		//   // divRef.current
		// }
	}, [data]);
	const devRef = useRef(null);
	const pharmacyItemRef = useRef(null);
	useLayoutEffect(() => {
		if (devRef.current) {
			if (data?.pages[0].data?.data?.items?.data.length > 3) {
				console.log(devRef);
				const divAnimate =
					devRef.current.getBoundingClientRect().top - 70;
				const heightDiv = devRef.current.getBoundingClientRect().height;
				console.log("divAnimate", divAnimate);
				const onScroll = () => {
					if (divAnimate < window.scrollY) {
						console.log("ok");
						devRef.current.classList.add("fixedSearch");
						pharmacyItemRef.current.style.paddingTop = `${Math.floor(
							heightDiv
						)}px`;
					} else {
						devRef.current.classList.remove("fixedSearch");
						pharmacyItemRef.current.style.paddingTop = "0px";
					}
				};
				window.addEventListener("scroll", onScroll);
				return () => window.removeEventListener("scroll", onScroll);
			}
		}
	}, [data]);

	// console.log("h_ref", heightDiv);
	useEffect(() => {
		window.scrollTo({
			top: 0,
			left: 0,
		});
	}, []);
	useEffect(() => {
		let tempTotal = 0;
		cart.map((item) => {
			tempTotal += parseFloat(item.price * item.count);
		});
		setTotal(tempTotal);
	}, [cart]);

	return (
		<div className={theme ? "food" : ""}>
			{theme ? (
				<Title title={title} />
			) : (
				<HeaderInner headerName={title} />
			)}
			<main
				className="bg-white back-white pad-top-0 pt-6"
				style={{ paddingBottom: "70px" }}
			>
				{isLoading ? (
					<CustomBusinessServiceLoader
						businessName={businessName}
						loader={"pharmacySubMenu"}
					/>
				) : (
					<>
						<div ref={devRef}>
							<Search
								placeholder="Search for medicines"
								room_uuid={room_uuid}
								category="Pharmacy"
							/>
							{terms_and_conditions != "" &&
								(terms_and_conditions != undefined ? (
									<>
										<div
											className="mx-4 divhtml"
											dangerouslySetInnerHTML={{
												__html: terms_and_conditions,
											}}
										/>
									</>
								) : null)}
							<ul
								className="inline-list search mt-1"
								ref={ref}
								onMouseDown={onMouseDown}
							>
								{/* <li
                  className={selected === "all" && "active"}
                  onClick={() => setSelected("all")}
                >
                  <Button>All</Button>
                </li> */}
								{data?.pages[0]?.data?.data?.categories?.map(
									(cat, idx) => {
										return (
											<li
												className={
													selected == cat.id &&
													"active"
												}
												onClick={() => {
													setSelected(cat.id);
													ReactGA.event({
														category:
															"Select category in pharmacy",
														action: "Click",
														value: `category name: ${cat?.name}}`,
													});
												}}
												key={idx}
											>
												<Button>{cat?.name}</Button>
											</li>
										);
									}
								)}
							</ul>
						</div>
						<div ref={pharmacyItemRef}>
							{data?.pages?.map((page) =>
								page.data?.data?.items?.data.map((item) => {
									return (
										<div className="add-post-container add-cart">
											<PharmacyCartPost
												item={item}
												pharmacyId={params.pharmacy_id}
												phID={params.id}
												title={title}
												theme={theme}
											/>
										</div>
									);
								})
							)}
							<div
								className="ref_loading"
								ref={loadMoreRef}
								onClick={() => fetchNextPage()}
								disabled={!hasNextPage || isFetchingNextPage}
							></div>
							<div>
								{isFetchingNextPage ? (
									<div class="d-flex justify-content-center">
										<div
											class="spinner-border"
											role="status"
										>
											<span class="visually-hidden"></span>
										</div>
									</div>
								) : hasNextPage ? (
									<div class="d-flex justify-content-center">
										<div
											class="spinner-border"
											role="status"
										>
											<span class="visually-hidden"></span>
										</div>
									</div>
								) : (
									""
								)}
							</div>
						</div>
						{/* <div
                className="viewcart"
                onClick={() => navigate(`/${room_uuid}/Checkout`)}
              >
                <div className="view-cart-header">
                  <p>
                    <span>{cart.length}</span> View cart
                  </p>
                  <p> {total}</p>
                </div>
                <div className="view-cart-body text-center">
                  <h3 className="footer-vat">All Prices are inclusive of VAT</h3>
                </div>
              </div> */}
						<ViewCart room_uuid={room_uuid} theme={theme} />
					</>
				)}
			</main>
			<Modal
				show={show}
				size="lg"
				onHide={handleClose}
				className="view-cart-modal"
			>
				<Modal.Body className={`${lang == "ar" ? "ar_lang" : ""}`}>
					<Button className="close-btn" onClick={handleClose}>
						<i className="fa fa-times-circle-o"></i>
					</Button>
					<ViewPharmacyCartModal />
				</Modal.Body>
			</Modal>
		</div>
	);
}
export default PharmacySubMenu;
