import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { Button, Modal } from "react-bootstrap";
import Post1 from "../assets/images/Pharmacy/Medicine.png";
import { AiOutlineCloseCircle } from "react-icons/ai";
import axios from "../axios";
import { useQueryClient } from "react-query";
import { useTranslation } from "react-i18next";
import { Zoom } from "react-reveal";
import ImageSuperMarket from "./ImageSuperMarket";
import ReactGA from "react-ga4";
import Sar from "../assets/images/icons/Sar";
import Currency from "./Currency";
import Price from "./Price";

function SupermarketCartPost({ item, pharmacyId, flowerId, phID, theme }) {
	const [loading, setLoading] = useState(false);
	const [show, setShow] = useState(false);
	const [modalImage, setModalImage] = useState(Post1);
	const [showCart, setShowCart] = useState(false);
	const [count, setCount] = useState(1);
	const location = useLocation();
	const rawPath = location.pathname;
	const room_uuid = rawPath.split("/")[1];

	const queryClient = useQueryClient();
	const currency = localStorage.getItem("currency");

	const { t } = useTranslation();

	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);
	const handleCartClose = () => {
		setShowCart(false);
	};
	const handleCartShow = () => {
		setShowCart(true);
		ReactGA.event({
			category: "Choose an item from the supermarket ",
			action: "Click",
			value: `item name: ${item?.name}}`,
		});
	};

	const handleIncrease = () => {
		setCount((prev) => {
			if (prev <= 1) {
				return 1;
			}
			return prev - 1;
		});
	};
	const handleDecrease = () => {
		setCount((prev) => prev + 1);
	};
	const addToCartV2 = () => {
		// console.log(item);
		setLoading(true);
		axios
			.post(`/${room_uuid}/supermarket/order-item`, {
				supermarket_id: parseInt(pharmacyId),
				item_id: item.id,
				quantity: count,
			})
			.then((res) => {
				setLoading(false);
				queryClient.invalidateQueries(`checkout`);
				const data = res.data;
				handleCartClose();
				// setRestaurantItems(data.items);
				// setHeaderName(data.title);
				console.log(data);
			});
	};
	return (
		<>
			<Zoom>
				<div className="add-cart-post new v2">
					<div className="add-cart-post-inner">
						<span
							className="img-pnl navigate"
							onClick={() => {
								handleShow();
								setModalImage(item.image);
							}}
						>
							<ImageSuperMarket src={item.image} />
						</span>
						<div
							className="txt-pnl navigate"
							// onClick={() => navigate("/PharmacyOrderPayment")}
							onClick={handleCartShow}
						>
							<h2>{item.name}</h2>
							{item?.supermarket_name && (
								<h6 className="s_title">
									{item?.supermarket_name}
								</h6>
							)}

							<p className="f-12 fw-400 mt-0 ">
								{item.description || item.description === ""
									? item.description
									: item.translations[0].description
									? item.translations[0].description
									: ""}
							</p>
							<span className="uom">
								{item.uom_packing_info
									? item.uom_packing_info
									: ""}
							</span>
							<div className="flex-div">
								<h3
									className={`${
										item?.supermarket_name ? "" : "mt7"
									}`}
								>
									{currency === "SAR" ? (
										<Sar color="currentcolor" />
									) : (
										<span> {currency}</span>
									)}{" "}
									{item.price}
								</h3>

								<Button
									className={`add-button ${
										item?.supermarket_name ? "mtop-7" : ""
									}`}
								>
									{t("Add To Cart")}
								</Button>
							</div>
						</div>
					</div>
				</div>
			</Zoom>

			{item && (
				<Modal
					show={show}
					// style={{ maxWidth: "430px" }}
					className={`custom-width img v2 ${
						theme ? "food-modal" : ""
					}`}
					onHide={handleClose}
				>
					<Button className="close-btn img" onClick={handleClose}>
						<div className="close-btn-wrap"></div>
						<AiOutlineCloseCircle />
					</Button>
					<Modal.Body>
						<div className="modal-img-container v2">
							<ImageSuperMarket src={item.image} />
						</div>
					</Modal.Body>
				</Modal>
			)}

			{item && (
				<Modal
					show={showCart}
					className={`custom-width  v2 ${theme ? "food-modal" : ""}`}
					onHide={handleCartClose}
				>
					<Button className="close-btn" onClick={handleCartClose}>
						<AiOutlineCloseCircle />
					</Button>
					<Modal.Body>
						<div className="modal-img-container br">
							<ImageSuperMarket src={item.image} />
						</div>
						<div className="modal-text-container sub-menu">
							<div className="pl-4">
								<h2>{item.name}</h2>
								<p className="text-gray">
									{item.description ? item.description : ""}
								</p>
								<p className="">
									{item.uom_packing_info
										? item.uom_packing_info
										: ""}
								</p>
							</div>
							<div className="price-container px-4 pt-2 pb-3">
								<p>
									<Price
										price={item.price}
										currency={currency}
									/>
								</p>
								<div className="count-order-amount">
									<Button
										className="small"
										onClick={handleIncrease}
									>
										<i className="fa fa-minus"></i>
									</Button>
									<p>{count}</p>
									<Button
										className=" small"
										onClick={handleDecrease}
									>
										<i className="fa fa-plus"></i>
									</Button>
								</div>
							</div>
							<div className="full-div padd-div mt-4">
								<Button
									// onClick={() => {
									//   addToCart({
									//     props: { item, cart, setCart, count, handleCartClose },
									//   });
									// }}
									onClick={addToCartV2}
									className="reg-btn big w-100 flex-div"
									disabled={loading}
								>
									<div>
										<i className="fa fa-plus-square mx-2"></i>
										{t("Add To Cart")}
									</div>
									<div>
										<Currency currency={currency} />
										{/* {currency} */}
										<span className="fw-600">
											{parseFloat(
												item.price * count
											).toFixed(2)}
										</span>
									</div>
								</Button>
							</div>
						</div>
					</Modal.Body>
				</Modal>
			)}
		</>
	);
}
export default SupermarketCartPost;
