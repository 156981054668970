import React, { useEffect, useState } from "react";
import { Link, useFetcher, useLocation, useNavigate } from "react-router-dom";
import HeaderInner from "../../Components/HeaderInner";
import RestaurantsLoader from "../../Components/Loaders/RestaurantsLoader";
import Bounce from "react-reveal/Bounce";
import { ShowRoomDining } from "../../API/In-Room-DiningAPI";
import CarosleRestaurant from "../../Components/CarosleRestaurant";
import CustomBusinessServiceLoader from "../../Components/Loaders/CustomBusinessServiceLoader";
import { useBusinessNameContext } from "./../../context/BusinessName";

function Restaurants() {
	const { businessName, setBusinessName } = useBusinessNameContext();

	const location = useLocation();
	const rawPath = location.pathname;
	const room_uuid = rawPath.split("/")[1];

	const navigate = useNavigate();
	const lang = localStorage.getItem("lang");
	let restaurants;
	let bgColor;
	let color;
	let text;
	let headerImg = [];
	const { data, isLoading, error } = ShowRoomDining(room_uuid, lang);

	if (!isLoading) {
		if (data.data.status == false) {
			navigate(`/${room_uuid}/qr-scan`);
		} else if (!error) {
			restaurants = data.data.data.restaurants;
			color = data.data.data.color;
			bgColor = data.data.data.header_color;
			text = data.data.data.center_header;
			// console.log("restaurants", restaurants);
			// headerImg = restaurants

			if (restaurants?.length == 1) {
				navigate(
					`/${room_uuid}/RestaurantMenu/${restaurants[0].uuid}/${restaurants[0].id}`,
					{ replace: true }
				);
			}
		}
	}

	// const getData = async () => {
	//   setLoading(true)
	//   axios.get(`/${room_uuid}/restaurants`).then((res) => {
	//     const data = res.data.data;
	//     setLoading(false)
	//     // setRestaurants(data.restaurants);
	//   });
	// };
	// useEffect(() => {

	//   getData();
	// }, []);
	useEffect(() => {
		window.scrollTo({
			top: 0,
			left: 0,
		});
	}, []);
	return (
		<>
			<HeaderInner bgColor={bgColor} color={color} headerName={text} />
			<main
				className="bg-white "
				style={{ paddingTop: "100px!important" }}
			>
				{isLoading ? (
					<CustomBusinessServiceLoader
						businessName={businessName}
						loader={"restaurant"}
					/>
				) : (
					<>
						{restaurants?.length > 1
							? restaurants.map((restaurant, idx) => {
									return (
										<div
											className={`general-post-container restaurant-post ${
												restaurant.is_closed
													? "closed restaurant-option"
													: "restaurant-option"
											}`}
											key={idx}
										>
											<Bounce left>
												<Link
													to={`/${room_uuid}/RestaurantMenu/${restaurant.uuid}/${restaurant.id}`}
													className="general-post"
												>
													<div className="closed-tag"></div>
													{restaurant?.is_closed && (
														<span className="open_at">
															{restaurant.opens_at
																? `Opens at ${restaurant.opens_at}`
																: `Closed`}
														</span>
													)}
													<div className="img-pnl ">
														{/* <img
                                src={restaurant.image}
                                alt="Post"
                                className="visible"
                              /> */}
														{
															// headerImg.push(restaurant.image)
														}
														<CarosleRestaurant
															headerImages={[
																restaurant.image,
															]}
														/>
													</div>
													<div className="txt-pnl">
														<h2>
															{lang == "EN"
																? restaurant.display_name
																: restaurant.name}
														</h2>
														<p>
															{restaurant?.cuisines?.map(
																(
																	cuisine,
																	idx
																) => {
																	if (
																		idx +
																			1 <
																		restaurant
																			?.cuisines
																			?.length
																	) {
																		return `${cuisine.name}  |  `;
																	} else {
																		return `${cuisine.name}  `;
																	}
																}
															)}
														</p>
													</div>
												</Link>
											</Bounce>
										</div>
									);
							  })
							: null}
					</>
				)}

				{/*    <div className="general-post-container restaurant-post">
          <Link to="/RestaurantMenu" className="general-post">
            <div className="closed-tag"></div>
            <div
              className={
                timer ? "img-pnl smooth" : "img-pnl animated-background smooth"
              }
            >
              <img src={Post1} alt="Post" className={timer ? "visible" : ""} />
            </div>
            <div className="txt-pnl">
              <h2 className={!timer && "animated-background"}>
                {timer && "In-Room Dining"}
              </h2>
              <p className={!timer && "animated-background"}>
                {timer && "International"}
              </p>
            </div>
          </Link>
        </div>
        <div className="general-post-container restaurant-post">
          <Link to="/RestaurantMenu" className="general-post">
            <div className="closed-tag"></div>
            <div
              className={
                timer ? "img-pnl smooth" : "img-pnl animated-background smooth"
              }
            >
              <img src={Post2} alt="Post" className={timer ? "visible" : ""} />
            </div>
            <div className="txt-pnl">
              <h2 className={!timer && "animated-background"}>
                {timer && "Cafe Society"}
              </h2>
              <p className={!timer && "animated-background"}>
                {timer && "Pizza, Eastern, Western"}
              </p>
            </div>
          </Link>
        </div>
        <div className="general-post-container restaurant-post">
          <Link to="/RestaurantMenu" className="general-post">
            <div className="closed-tag"></div>
            <div
              className={
                timer ? "img-pnl smooth" : "img-pnl animated-background smooth"
              }
            >
              <img src={Post3} alt="Post" className={timer ? "visible" : ""} />
            </div>
            <div className="txt-pnl">
              <h2 className={!timer && "animated-background"}>
                {timer && "Hawa Bahri Restaurant"}
              </h2>
              <p className={!timer && "animated-background"}>
                {timer && "Eastern Cuisine"}
              </p>
            </div>
          </Link>
        </div> */}
			</main>
		</>
	);
}
export default Restaurants;
