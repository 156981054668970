import { useLocation } from "react-router-dom";
import HeaderInner from "./HeaderInner";
import { Document, Page, pdfjs } from "react-pdf";
import { useState, useEffect, useRef } from "react";

// Set the workerSrc for pdfjs to use a local copy
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

const PdfViewer = () => {
	const location = useLocation();
	const params = new URLSearchParams(location.search);
	const serviceName = params.get("name") || "";
	const fileUrl = params.get("pdf") || "";

	// Log the URL for debugging

	const [numPages, setNumPages] = useState(null);
	const [error, setError] = useState(null);
	const [containerWidth, setContainerWidth] = useState(window.innerWidth);
	const [scale, setScale] = useState(1);
	const [isLoading, setIsLoading] = useState(true);
	const [pdfBlob, setPdfBlob] = useState(null);
	const containerRef = useRef(null);
	const touchStartDistance = useRef(null);
	const currentScale = useRef(1);

	useEffect(() => {
		const updateDimensions = () => {
			if (containerRef.current) {
				const width = containerRef.current.offsetWidth;
				setContainerWidth(width);

				// Adjust initial scale based on screen width
				if (width < 480) {
					setScale(1.0); // Balanced scale for mobile
				} else if (width < 768) {
					setScale(1.2); // Balanced for tablets
				} else {
					setScale(1.4); // Balanced for desktop
				}
				currentScale.current = scale;
			}
		};

		const handleTouchStart = (e) => {
			if (e.touches.length === 2) {
				const distance = Math.hypot(
					e.touches[0].clientX - e.touches[1].clientX,
					e.touches[0].clientY - e.touches[1].clientY
				);
				touchStartDistance.current = distance;
			}
		};

		const handleTouchMove = (e) => {
			if (e.touches.length === 2 && touchStartDistance.current !== null) {
				e.preventDefault();
				const distance = Math.hypot(
					e.touches[0].clientX - e.touches[1].clientX,
					e.touches[0].clientY - e.touches[1].clientY
				);

				const delta = distance / touchStartDistance.current;
				const newScale = Math.min(
					Math.max(currentScale.current * delta, 1.2), // Minimum scale
					3.0 // Maximum scale
				);
				setScale(newScale);
			}
		};

		const handleTouchEnd = () => {
			touchStartDistance.current = null;
			currentScale.current = scale;
		};

		const element = containerRef.current;
		if (element) {
			element.addEventListener("touchstart", handleTouchStart);
			element.addEventListener("touchmove", handleTouchMove, {
				passive: false,
			});
			element.addEventListener("touchend", handleTouchEnd);
		}

		updateDimensions();
		window.addEventListener("resize", updateDimensions);

		return () => {
			window.removeEventListener("resize", updateDimensions);
			if (element) {
				element.removeEventListener("touchstart", handleTouchStart);
				element.removeEventListener("touchmove", handleTouchMove);
				element.removeEventListener("touchend", handleTouchEnd);
			}
		};
	}, [scale]);

	function onDocumentLoadSuccess({ numPages }) {
		setNumPages(numPages);
		setError(null);
		setIsLoading(false);
	}

	function onDocumentLoadError(error) {
		console.error(error);
		setError(error.message);
		setIsLoading(false);
	}

	const LoadingMessage = () => (
		<div
			style={{
				display: "",
				justifyContent: "",
				alignItems: "center",
				backgroundColor: "#f5f5f5",
				borderRadius: "8px",
				margin: "10px",
			}}
		>
			<div>Loading PDF...</div>
		</div>
	);

	return (
		<div className="mubadala" ref={containerRef}>
			<div
				className="container"
				style={{
					width: "100%",
					maxWidth: "100%",
					overflowX: "auto",
					overflowY: "auto",
					WebkitOverflowScrolling: "touch",
					msOverflowStyle: "-ms-autohiding-scrollbar",
					padding: "10px",
					display: "flex",
					flexDirection: "column",
					alignItems: "center", // Center content horizontally
				}}
			>
				<HeaderInner headerName={serviceName} />
				<div
					style={{
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
						width: "100%",
						maxWidth: "100vw", // Ensure it doesn't exceed viewport width
					}}
				>
					{error ? (
						<div
							style={{
								padding: "20px",
								backgroundColor: "#fee",
								color: "#c00",
								borderRadius: "8px",
								margin: "10px",
							}}
						>
							Error loading PDF: {error}
						</div>
					) : (
						<>
							<Document
								file={fileUrl}
								onLoadSuccess={onDocumentLoadSuccess}
								onLoadError={(error) => {
									console.error("PDF Error:", error);
									onDocumentLoadError(error);
								}}
								loading={<LoadingMessage />}
							>
								{isLoading ? (
									<LoadingMessage />
								) : (
									Array.from(
										new Array(numPages),
										(el, index) => (
											<Page
												key={index}
												pageNumber={index + 1}
												scale={scale}
												width={Math.min(
													containerWidth * 0.95,
													1000
												)} // 95% of container width with max limit
												renderTextLayer={false}
												renderAnnotationLayer={false}
												loading={
													<div
														style={{
															width: "100%",
															height: "400px",
															display: "flex",
															justifyContent:
																"center",
															alignItems:
																"center",
															backgroundColor:
																"#f5f5f5",
															borderRadius: "8px",
															margin: "10px 0",
														}}
													>
														Loading page {index + 1}
														...
													</div>
												}
											/>
										)
									)
								)}
							</Document>
						</>
					)}
				</div>
			</div>
		</div>
	);
};

export default PdfViewer;
