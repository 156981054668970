import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ReactGA from "react-ga4";

import SpaLoader from "../../Components/Loaders/SpaLoader";
import { ShowSPA } from "../../API/SPAAPI";
import { useTranslation } from "react-i18next";
import Title from "../../new-components/title/Title";
import NewFooter from "../../NewFooter";
import BannerHome from "../../new-components/banner-home/BannerHome";
import Sar from "../../assets/images/icons/Sar";

function SpaService() {
	const [modalImage, setModalImage] = useState();
	const [showCart, setShowCart] = useState(false);
	const [show, setShow] = useState(false);
	const currency = localStorage.getItem("currency");

	const location = useLocation();
	const navigate = useNavigate();
	const rawPath = location.pathname;
	const room_uuid = rawPath.split("/")[1];
	const spa_uuid = rawPath.split("/spa/")[1];
	const lang = localStorage.getItem("lang");

	const handleShow = () => setShow(true);
	const { t } = useTranslation();

	const handleCartShow = () => setShowCart(true);

	let spa;
	let images;
	let text;
	let spaServices;
	let discount_percentage;
	const { data, isLoading, error } = ShowSPA(room_uuid, spa_uuid, lang);

	if (!isLoading) {
		if (error) {
			if (error?.response.status === 404) {
				navigate(`/not-found`);
			}
		} else {
			if (data.data.status === false) {
				navigate(`/${room_uuid}/qr-scan`);
			} else {
				spa = data.data.data.spa;
				images = spa?.header_images;
				text = data.data.data.header_title;
				spaServices = spa.services;
				discount_percentage = spa.discount_percentage;
			}
		}
	}
	useEffect(() => {
		window.scrollTo({
			top: 0,
			left: 0,
		});
	}, []);

	return (
		<div className="food pb-2">
			<Title title={text} />
			<main className="home-main bg-white back-white pad-top-0">
				{isLoading ? (
					<SpaLoader />
				) : (
					<>
						<BannerHome className={"px-2"} headerImages={images} />
						{/* <Carosle className={"dot-bar"} headerImages={images} /> */}
						{spaServices &&
							spaServices.map(
								({ image, name, price, id, extras }) => {
									return (
										<div className="add-post-container add-cart">
											<div className="add-cart-post new spa ">
												<div className="">
													<Link
														to={`/${room_uuid}/spa/${spa_uuid}/service/${id}?theme=true`}
														state={id}
														onClick={() => {
															ReactGA.event({
																category:
																	"Open SPA card",
																action: "Click",
																value: `SPA: ${name}`,
															});
														}}
														className="add-cart-post-inner"
													>
														<span
															className="img-pnl navigate"
															onClick={() => {
																handleShow();
																setModalImage(
																	image
																);
															}}
														>
															<img
																src={image}
																alt="Post"
															/>
														</span>
														<div
															className="txt-pnl navigate"
															onClick={
																handleCartShow
															}
															// onClick={() => navigate("/SpaAddService")}
														>
															<h2>{name}</h2>
															<p></p>
															<div className="flex-div align-item-center">
																<h3>
																	{extras[0]
																		?.options
																		.length >
																	1 ? (
																		<span
																			style={{
																				fontSize:
																					"12px",
																			}}
																		>
																			{t(
																				"Price upon selection"
																			)}
																		</span>
																	) : discount_percentage ? (
																		<span className="spa_price">
																			<span className="discount_spa">{`${currency} ${Number(
																				price
																			)}`}</span>
																			<span>{`${currency} ${
																				Number(
																					price
																				) -
																				(Number(
																					price
																				) *
																					Number(
																						discount_percentage
																					)) /
																					100
																			}`}</span>
																		</span>
																	) : (
																		<span>
																			{" "}
																			{currency ===
																			"SAR" ? (
																				<Sar color="currentcolor" />
																			) : (
																				`${currency} `
																			)}{" "}
																			{Number(
																				price
																			)}
																		</span>
																	)}
																</h3>
																<span className="reg-btn service">
																	{t(
																		"Add Service"
																	)}
																</span>
															</div>
														</div>
													</Link>
												</div>
											</div>
										</div>
									);
								}
							)}
					</>
				)}
			</main>
			<NewFooter />
		</div>
	);
}
export default SpaService;
