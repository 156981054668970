import React, { useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { Button, Modal } from "react-bootstrap";
import Image from "react-bootstrap/Image";
import Post1 from "../../assets/images/Pharmacy/Medicine.png";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { addToCart } from "../../utils/cartUtils";
import axios from "../../axios";
import { useCartStore } from "../../store/useStore";
import { useQueryClient } from "react-query";
import { useTranslation } from "react-i18next";
import { Zoom } from "react-reveal";
import { ShowFlowerItem } from "../../API/FlowersAPI";
import HeaderInner from "../../Components/HeaderInner";
import Search from "../../Components/Search";
import { ShowPharmacyItem } from "../../API/Pharmacy";
import { ShowSupermarketItem } from "../../API/Supermarket";
import SpaLoader from "../../Components/Loaders/SpaLoader";
import Sar from "../../assets/images/icons/Sar";

function SupermarketItem() {
	const [enableClass, setEnableClass] = useState(true);
	const [loading, setLoading] = useState(false);
	const [show, setShow] = useState(false);
	const [modalImage, setModalImage] = useState(Post1);
	const [showCart, setShowCart] = useState(true);
	const [count, setCount] = useState(1);
	const location = useLocation();
	const rawPath = location.pathname;
	const params = useParams();
	const room_uuid = rawPath.split("/")[1];
	const { cart, setCart } = useCartStore((state) => ({
		cart: state.cart,
		setCart: state.setCart,
	}));
	let item;
	const { data, isLoading } = ShowSupermarketItem(room_uuid, params.f_id);
	const currency = localStorage.getItem("currency");

	if (!isLoading) {
		const fData = data.data.data;
		item = fData.item;
		// console.log("dataaa", fData);
	}
	const queryClient = useQueryClient();

	const { t, i18n } = useTranslation();

	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);
	const handleCartClose = () => {
		setShowCart(false);
	};
	const handleCartShow = () => setShowCart(true);

	const handleIncrease = () => {
		setCount((prev) => {
			if (prev <= 1) {
				return 1;
			}
			return prev - 1;
		});
	};
	const handleDecrease = () => {
		setCount((prev) => prev + 1);
	};

	const lang = localStorage.getItem("lang");
	const addToCartV2 = () => {
		// console.log(item);
		setLoading(true);
		axios
			.post(`/${room_uuid}/supermarket/order-item`, {
				supermarket_id: parseInt(params.c_id),
				item_id: item.id,
				quantity: count,
			})
			.then((res) => {
				setLoading(false);
				queryClient.invalidateQueries(`checkout`);
				const data = res.data;
				handleCartClose();
				// setRestaurantItems(data.items);
				// setHeaderName(data.title);
				console.log(data);
			});
	};
	return (
		<>
			<HeaderInner headerName={"Supermarket"} />
			<main
				className="home-main bg-white   flowers"
				style={{ paddingTop: "100px !important" }}
			>
				{isLoading ? (
					<SpaLoader />
				) : (
					<>
						<Search
							placeholder={item.name}
							room_uuid={room_uuid}
							category="Supermarket"
						/>
						<div className="add-cart-post new v2">
							<div className="add-cart-post-inner">
								<span
									className="img-pnl navigate"
									onClick={() => {
										handleShow();
										setModalImage(item.image);
									}}
								>
									<img src={item.image} alt="Post" />
								</span>
								<div
									className="txt-pnl navigate"
									// onClick={() => navigate("/PharmacyOrderPayment")}
									onClick={handleCartShow}
								>
									<h2>{item.name}</h2>

									<p className="f-12 fw-400 mt-0 ">
										{item.description ||
										item.description === ""
											? item.description
											: item.translations[0].description
											? item.translations[0].description
											: ""}
									</p>
									<div className="flex-div">
										<h3>
											{currency === "SAR" ? (
												<Sar color="currentcolor" />
											) : (
												`${currency} `
											)}{" "}
											{item.price}
										</h3>

										<Button className="add-button">
											{t("Add To Cart")}
										</Button>
									</div>
								</div>
							</div>
						</div>
					</>
				)}
			</main>
			{item && (
				<Modal
					show={show}
					// style={{ maxWidth: "430px" }}
					className="custom-width img v2"
					onHide={handleClose}
				>
					<Button className="close-btn img" onClick={handleClose}>
						<div className="close-btn-wrap"></div>
						<AiOutlineCloseCircle />
					</Button>
					<Modal.Body>
						<div className="modal-img-container v2">
							<Image src={item.image} alt="Post" />
						</div>
					</Modal.Body>
				</Modal>
			)}

			{item && (
				<Modal
					show={showCart}
					className="custom-width v2"
					onHide={handleCartClose}
				>
					<Button className="close-btn" onClick={handleCartClose}>
						<AiOutlineCloseCircle />
					</Button>
					<Modal.Body>
						<div className="modal-img-container br">
							<Image src={item.image} alt="Post" />
						</div>
						<div className="modal-text-container sub-menu">
							<div className="pl-4">
								<h2>{item.name}</h2>
								<p className="text-gray">
									{item.description ? item.description : ""}
								</p>
							</div>
							<div className="price-container px-4 pt-2 pb-3">
								<p>
									{currency === "SAR" ? (
										<Sar color="currentcolor" />
									) : (
										`${currency} `
									)}{" "}
									{item.price}
								</p>
								<div className="count-order-amount">
									<Button
										className="small"
										onClick={handleIncrease}
									>
										<i className="fa fa-minus"></i>
									</Button>
									<p>{count}</p>
									<Button
										className=" small"
										onClick={handleDecrease}
									>
										<i className="fa fa-plus"></i>
									</Button>
								</div>
							</div>
							<div className="full-div padd-div mt-4">
								<Button
									onClick={addToCartV2}
									className="reg-btn big w-100 flex-div"
									disabled={loading}
								>
									<div>
										<i className="fa fa-plus-square mx-2"></i>
										{t("Add To Cart")}
									</div>
									<div>
										{currency === "SAR" ? (
											<Sar color="currentcolor" />
										) : (
											`${currency} `
										)}
										<span className="fw-600">
											{parseFloat(
												item.price * count
											).toFixed(2)}
										</span>
									</div>
								</Button>
							</div>
						</div>
					</Modal.Body>
				</Modal>
			)}
		</>
	);
}
export default SupermarketItem;
