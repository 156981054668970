import React, { useState } from "react";
import { Button, Modal, Dropdown } from "react-bootstrap";
import HeaderInner from "../../Components/HeaderInner";
import CarBookModal from "../../Components/CarBookModal";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ShowConcierge } from "../../API/Concierge";
import SpaLoader from "../../Components/Loaders/SpaLoader";
import { useTranslation } from "react-i18next";
import ReactGA from "react-ga4";
import CustomLoader from "../../Components/Loaders/CustomLoader";
import CustomBusinessServiceLoader from "../../Components/Loaders/CustomBusinessServiceLoader";
import { useBusinessNameContext } from "./../../context/BusinessName";

function Concierge() {
	const { businessName, setBusinessName } = useBusinessNameContext();
	const navigate = useNavigate();
	const location = useLocation();
	const rawPath = location.pathname;

	const { t, i18n } = useTranslation();

	const room_uuid = rawPath.split("/")[1];

	const lang = localStorage.getItem("lang");
	let concierges;
	let bgColor;
	let color;
	let text;

	const { data, isLoading, error } = ShowConcierge(room_uuid, lang);
	if (!isLoading) {
		if (data.data.status === false) {
			navigate(`/${room_uuid}/qr-scan`);
		} else {
			let newData = data.data.data;
			concierges = newData?.concierge;
			color = newData?.color;
			bgColor = newData?.header_color;
			text = newData?.title;

			// console.log("ShowConcierge", newData);
		}
	}
	return (
		<>
			<HeaderInner headerName={text} />
			<main className="home-main bg-white back-white pad-top-0 pb-4">
				{isLoading ? (
					<CustomBusinessServiceLoader
						businessName={businessName}
						loader="concierge"
					/>
				) : (
					concierges?.map((x, idx) => {
						return (
							<div
								className="con-img-container"
								id={x.text}
								key={idx}
								onClick={() => {
									navigate(`/${room_uuid}/${x.slug}`);
									ReactGA.event({
										category: "Select concierge",
										action: "Click",
										value: `concierge: ${x.slug}`,
									});
								}}
							>
								<div className="con-img">
									<img src={x.image} alt="" />
									<p>{t(`${x.name}`)}</p>
								</div>
							</div>
						);
					})
				)}
			</main>
		</>
	);
}
export default Concierge;
