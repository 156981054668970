import React, { useEffect } from "react";
import Hero from "../../assets/images/mubadala/1_1.png";
import One from "../../assets/images/mubadala/2.png";
import Tow from "../../assets/images/mubadala/3.png";
import Three from "../../assets/images/mubadala/4.png";
import Four from "../../assets/images/mubadala/5.png";
import Five from "../../assets/images/mubadala/6.png";
import Six from "../../assets/images/mubadala/7.png";
import Seven from "../../assets/images/mubadala/8.png";
import Eight from "../../assets/images/mubadala/9.png";
import Nine from "../../assets/images/mubadala/10.png";
import Ten from "../../assets/images/mubadala/11.png";
import C1 from "../../assets/images/mubadala/12.png";
import C2 from "../../assets/images/mubadala/13.png";
import C3 from "../../assets/images/mubadala/14.png";
import C4 from "../../assets/images/mubadala/15.png";
import Skip from "../../assets/images/mubadala/16.png";
import "./mubadala.scss";
import MubadalaLogin from "./MubadalaLogin";
import MubadalaRegister from "./MubadalaRegister";
import MubadalaApprove from "./MubadalaApprove";
import MubadalaAccountDetali from "./MubadalaAccountDetali";
import MubadalaConfirmation from "./MubadalaConfirmation";
import HeaderInner from "../../Components/HeaderInner";
import MubadalaWifiInstructions from "./MubadalaWifiInstructions";
import { t } from "i18next";
const MubadalaMain = () => {
	useEffect(() => {
		window.scrollTo({
			top: 0,
			left: 0,
		});
	}, []);
	const businessName = localStorage.getItem("business_name");
	console.log("businessName", businessName);
	return businessName === "Mubadala Abu Dhabi" || "Mubadala London" ? (
		<>
			<HeaderInner headerName={"Wi-Fi Access"} />
			<div className="mubadala-page">
				<MubadalaWifiInstructions
					businessName={businessName}
				></MubadalaWifiInstructions>
			</div>
		</>
	) : (
		<>
			<HeaderInner headerName={"Wi-Fi Access"} />
			<div className="mubadala">
				<img src={Hero} alt="" />
				<div className="head">
					<h2>{t("GUEST WI-FI ACCESS GUIDE")}</h2>
					<h6>{t("How to access Mubadala’s Wi-Fi")}</h6>
					<p>
						{t(
							"How to access Mubadala’s Wi-Fi as a guest user guide"
						)}
					</p>
				</div>
				<div className="images_box mar_top">
					<div className="m_image">
						<img src={One} alt="" />
						<p>
							Select <span>MIC-Guest</span> wireless network and
							click on <span>Connect</span>
						</p>
					</div>
					<div className="m_skip">
						<img src={Skip} alt="" />
					</div>
					<div className="m_image">
						<img src={Tow} alt="" />
						<p>
							{t("You will be redirected to Guest Portal:")}{" "}
							<br />
							<span>
								<a href="https://guestaccessmamoura.mubadala.ae">
									https://guestaccessmamoura.mubadala.ae
								</a>
							</span>
							{t("Click on Register for Guest access")}
						</p>
					</div>
					<div className="m_skip">
						<img src={Skip} alt="" />
					</div>
					<div className="m_image">
						<img src={Three} alt="" />
						<p>
							{t(
								"Enter guest details along with sponsor email address and click on register"
							)}
						</p>
						{/* <p>Enter <span>guest details</span> along with <span>sponsor
                            email</span> address and click on <span>register</span></p> */}
					</div>
				</div>
				<div className="images_box mar_top_">
					<div className="m_skip">
						<img src={Skip} alt="" />
					</div>
					<div className="m_image">
						<img src={Four} alt="" />
						<p>
							{t(
								"Email will be sent to the sponsor to Approve or Deny. Click on Approve"
							)}
						</p>
					</div>
					<div className="m_skip">
						<img src={Skip} alt="" />
					</div>
					<div className="m_image">
						<img src={Five} alt="" />
						<p>
							{t(
								"Guest will receive an email with password to login"
							)}
						</p>
					</div>
				</div>

				<div className="head">
					<h2>{t("GUEST WI-FI ACCESS GUIDE")}</h2>
					<h6>
						{t(
							"How to access Mubadala’s Wi-Fi as a guest user guide"
						)}
					</h6>
					<p>
						{t(
							"How to access Mubadala’s Wi-Fi as a guest user guide"
						)}
					</p>
				</div>
				<div className="images_box mar_top">
					<div className="m_image">
						<img src={Six} alt="" />
						<p>
							{t(
								"Click on “Back to Login page” on the Guest Partial"
							)}
							l
						</p>
					</div>
					<div className="m_skip">
						<img src={Skip} alt="" />
					</div>
					<div className="m_image">
						<img src={Seven} alt="" />
						<p>
							{t(
								"Enter your Username & Password from the approval email, select I Agree to the terms and conditions and click Sign On"
							)}
						</p>
						{/* <p>Enter you <span>Username & Password</span> from the
                            approval email, select <span>I Agree</span> to the terms and
                            conditions and click <span>Sign On</span></p> */}
					</div>
					<div className="m_skip">
						<img src={Skip} alt="" />
					</div>
					<div className="m_image">
						<img src={Eight} alt="" />
						<p>{t("Click Continue on the welcome page")}</p>
						{/* <p>Click <span>Continue</span> on the welcome page</p> */}
					</div>
				</div>
				<div className="images_box mar_top_">
					<div className="m_skip">
						<img src={Skip} alt="" />
					</div>
					<div className="m_image">
						<img src={Nine} alt="" />
						<p>
							{t(
								"You will be redirected to Mubadala.com with Internet access"
							)}{" "}
						</p>
						{/* <p>You will be redirected to <span>Mubadala.com</span>
                            with Internet access
                        </p> */}
					</div>
					<div className="m_skip">
						<img src={Skip} alt="" />
					</div>
					<div className="m_image">
						<img src={Ten} alt="" />
						<p>{t("MIC-Guest will show as Connected")} </p>
						{/* <p><span>MIC-Guest</span> will show as Connected</p> */}
					</div>
				</div>

				<div className="head">
					{/* <h2>How To Contact Us </h2> */}
					<h2>{t("How To Contact Us")}</h2>
				</div>

				<div className="contact__box">
					<div className="contact_sub_box">
						<img src={C1} alt="" />
						<h4 className="tit">{t("PROBLEM OR A REQUEST")} </h4>
						{/* <p className="des">D&TS’ dedicated Abu Dhabi based Digital Care Team is ready to take your call or email 24/7 a week, 365 days of the year.</p> */}
						<p className="des">
							{t(
								"D&TS’ dedicated Abu Dhabi based Digital Care Team is ready to take your call or email 24/7 a week, 365 days of the year."
							)}
						</p>

						<h6>
							+971 2 413 8888 <br />
							<a href="mailto:digitalcare@mubadala.ae">
								digitalcare@mubadala.ae
							</a>
						</h6>
						<span className="border__"></span>
						<span className="border__b"></span>
					</div>
					<div className="contact_sub_box">
						<img src={C2} alt="" />
						<h4 className="tit">
							{t("ESCALATION, COMPLAINT OR TECHNOLOGY EMERGENCY")}
						</h4>
						<p className="des">
							{t(
								"An escalation number to further support your requirements."
							)}{" "}
						</p>
						<h6>
							+971 55 7000 DTS (387) <br />
							<a href="mailto:digitalescalations@mubadala.ae">
								digitalescalations@mubadala.ae
							</a>
						</h6>
						<span className="border__b"></span>
					</div>
					<div className="contact_sub_box">
						<img src={C3} alt="" />
						<h4 className="tit">
							{t("NEW BUSINESS REQUIREMENT OR DEMAND")}{" "}
						</h4>
						<p className="des">
							{t(
								"D&TS’ Digital Outreach Team is ready for any new requests or demands. Each unit has a dedicated partner, please visit the D&TS Canvas page for further information."
							)}{" "}
						</p>
						<h6>
							<a
								style={{ marginTop: "14px" }}
								href="mailto:digitalpartners@mubadala.ae"
							>
								digitalpartners@mubadala.ae
							</a>
						</h6>
						<span className="border__"></span>
					</div>
					<div className="contact_sub_box">
						<img src={C4} alt="" />
						<h4 className="tit">
							{t("CYBERSECURITY CONCERN OR QUERY")}{" "}
						</h4>
						<p className="des">
							{t(
								"D&TS’ Cybersecurity Team is available to assist with any seurity related inquiries"
							)}{" "}
						</p>
						<h6>
							+971 2 413 8888 <br />
							<a href="mailto:cybersecurity@mubadala.ae">
								cybersecurity@mubadala.ae
							</a>
						</h6>
					</div>
				</div>
			</div>
		</>
	);
};

export default MubadalaMain;
