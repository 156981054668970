import React from "react";
import Currency from "./Currency";

function Price({ price, currency }) {
	return (
		<>
			<Currency currency={currency} /> {price}
		</>
	);
}

export default Price;
