import React, { useContext, useRef, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Button, Form, Modal } from "react-bootstrap";
import Image from "react-bootstrap/Image";
import Post1 from "../assets/images/Pharmacy/Medicine.png";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { addToCart } from "../utils/cartUtils";
import axios from "../axios";
import { useCartStore } from "../store/useStore";
import { useQueryClient } from "react-query";
import { useTranslation } from "react-i18next";
import { Zoom } from "react-reveal";
import ImageSuperMarket from "./ImageSuperMarket";
import ReactGA from "react-ga4";
import { ThemeContext } from "../context/ThemeContext";
import Currency from "./Currency";

function PharmacyCartPost({
	item,
	pharmacyId,
	flowerId,
	phID,
	search,
	subTitle,
	type,
	title,
}) {
	// const new_design = localStorage.getItem("new_design");
	// const theme = new_design == "true";

	const { theme } = useContext(ThemeContext);

	const [enableClass, setEnableClass] = useState(true);
	const [loading, setLoading] = useState(false);
	const [show, setShow] = useState(false);
	const [modalImage, setModalImage] = useState(Post1);
	const [showCart, setShowCart] = useState(false);
	const [extras, setExtras] = useState([]);
	const [optionsIds, setOptionsIds] = useState({});
	const inputRef = useRef([]);
	const [priceOption, setPriceOption] = useState(0);
	const [count, setCount] = useState(1);
	const location = useLocation();
	const rawPath = location.pathname;
	const room_uuid = rawPath.split("/")[1];
	const lang = localStorage.getItem("lang");
	const { cart, setCart } = useCartStore((state) => ({
		cart: state.cart,
		setCart: state.setCart,
	}));
	const queryClient = useQueryClient();
	// console.log("phIDphID", decodeURIComponent(location.search));

	// setTimeout(() => {
	//   setEnableClass(false);
	// }, 3000);
	const { t, i18n } = useTranslation();
	const handleInvalid = (event, index) => {
		event.target.setCustomValidity("Please select 1 option at least");
	};
	const handleHandleOptionsChanges = (
		event,
		optionName,
		optionId,
		type,
		price,
		idx,
		max_op
	) => {
		event.target.setCustomValidity("");
		// console.log("max_op", max_op);

		if (type == "radio") {
			// if (state == "remove") {
			//   const { [optionName]: _, ...rest } = optionsIds;

			//   setOptionsIds(rest);
			// } else {
			setOptionsIds((prev) => ({
				...prev,
				[optionName]: optionId,
			}));
			// }
		} else {
			const idex = document.getElementById(`idx${idx}`);
			var inputNodes = idex.getElementsByTagName("INPUT");
			var inputLang = idex.querySelectorAll(
				'input[type="checkbox"]:checked'
			).length;
			// console.log("inputNodes", inputNodes);
			Object.values(inputNodes).map((inp) => {
				if (inputLang >= 1) {
					inp.required = false;
					inp.setCustomValidity("");
				} else if (inputLang == 0) {
					inp.required = true;
					inp.setCustomValidity("Please select 1 option at least");
				}
				if (inputLang == max_op) {
					// console.log("inputLang", inputLang);
					if (inp.checked == false) {
						inp.disabled = true;
					}
				} else {
					if (inp.checked == false) {
						inp.disabled = false;
					}
				}
			});
			if (extras.includes(optionId)) {
				setPriceOption(priceOption - Number(price));

				const tempExtras = JSON.parse(JSON.stringify(extras));
				const filtered = tempExtras.filter((temp) => {
					return temp !== optionId;
				});
				setExtras(filtered);
			} else {
				setPriceOption(priceOption + Number(price));

				setExtras((prev) => [optionId, ...prev]);
			}
		}
	};

	const handleClose = () => setShow(false);
	const handleShow = () => {
		if (title == "Pharmacy") {
			ReactGA.event({
				category: "Choose an item from the pharmacy ",
				action: "Click",
				value: `item name: ${item?.name}}`,
			});
		} else if (title == "Florist") {
			ReactGA.event({
				category: "Choose an item from the florist ",
				action: "Click",
				value: `item name: ${item?.name}}`,
			});
		}
		setShow(true);
	};
	const handleCartClose = () => {
		if (title == "Pharmacy") {
			ReactGA.event({
				category: "Choose an item from the pharmacy ",
				action: "Click",
				value: `item name: ${item?.name}}`,
			});
		} else if (title == "Florist") {
			ReactGA.event({
				category: "Choose an item from the florist ",
				action: "Click",
				value: `item name: ${item?.name}}`,
			});
		}
		setShowCart(false);
	};
	const handleCartShow = () => {
		setShowCart(true);
	};

	const handleIncrease = () => {
		setCount((prev) => {
			if (prev <= 1) {
				return 1;
			}
			return prev - 1;
		});
	};
	const handleDecrease = () => {
		setCount((prev) => prev + 1);
	};
	let text_extras = [];

	const addToCartV2 = (e) => {
		e.preventDefault();

		setLoading(true);
		if (pharmacyId) {
			axios
				.post(
					`/${room_uuid}/pharmacy/order-item`,
					{
						pharmacy_id: parseInt(pharmacyId),
						item_id: item.id,
						quantity: count,
					},
					{
						headers: {
							lang: lang,
						},
					}
				)
				.then((res) => {
					setLoading(false);
					queryClient.invalidateQueries(`checkout`);
					const data = res.data;
					handleCartClose();
					// setRestaurantItems(data.items);
					// setHeaderName(data.title);
				});
		} else if (flowerId) {
			let extraText = document.getElementsByClassName("ex_class");
			if (extraText.length > 0) {
				Object.values(extraText).map((item) => {
					if (item.value != "") {
						text_extras.push({
							extra_id: item.id,
							text: item.value,
						});
					}
				});
			}
			if (text_extras.length > 0) {
				axios
					.post(`/${room_uuid}/florists/order-item`, {
						florist_id: parseInt(flowerId),
						item_id: item.id,
						quantity: count,
						extras: [...Object.values(optionsIds), ...extras],
						text_extras: text_extras,
					})
					.then((res) => {
						queryClient.invalidateQueries(`checkout`);
						queryClient.invalidateQueries("checkout_order");
						const data = res.data;
						setLoading(false);
						handleCartClose();
						// setRestaurantItems(data.items);
						// setHeaderName(data.title);
					});
			} else {
				axios
					.post(`/${room_uuid}/florists/order-item`, {
						florist_id: parseInt(flowerId),
						item_id: item.id,
						quantity: count,
						extras: [...Object.values(optionsIds), ...extras],
					})
					.then((res) => {
						setLoading(false);
						queryClient.invalidateQueries(`checkout`);
						const data = res.data;
						handleCartClose();
						// setRestaurantItems(data.items);
						// setHeaderName(data.title);
					});
			}
		}
	};
	const currency = localStorage.getItem("currency");
	return (
		<>
			<Zoom>
				<div className="add-cart-post new v2">
					<div className="add-cart-post-inner" key={item.id}>
						<span
							className="img-pnl navigate"
							onClick={() => {
								handleShow();
								setModalImage(item.image);
							}}
						>
							{type == "supermarket" ? (
								<ImageSuperMarket src={item.image} />
							) : (
								<img src={item.image} />
							)}
						</span>
						<div
							className="txt-pnl navigate"
							// onClick={() => navigate("/PharmacyOrderPayment")}
							onClick={handleCartShow}
						>
							<h2>{item.name}</h2>
							{type !== "supermarket" && subTitle && (
								<h6 className="s_title">{subTitle}</h6>
							)}

							{item.description || item.description === "" ? (
								<p className="f-12 fw-400 mt-0 ">
									{item.description}
								</p>
							) : item.translations[0].description ? (
								<p className="f-12 fw-400 mt-0 ">
									{item.translations[0].description}
								</p>
							) : (
								""
							)}
							<div className="flex-div">
								<h3 className={`${subTitle ? "" : "mt7"}`}>
									{" "}
									{item.price == "0.00" ? (
										<span style={{ fontSize: "10px" }}>
											{t("Price upon selection")}
										</span>
									) : (
										<>
											<Currency currency={currency} />
											{item.price}
										</>
									)}
								</h3>
								{!search && (
									<Button
										className={`add-button ${
											subTitle ? "mtop-7" : ""
										}`}
									>
										{t("Add To Cart")}
									</Button>
								)}
							</div>
						</div>
					</div>
				</div>
			</Zoom>

			{item && (
				<Modal
					show={show}
					// style={{ maxWidth: "430px" }}
					className={`custom-width img  ${theme ? "food-modal" : ""}`}
					onHide={handleClose}
				>
					<Button className="close-btn img" onClick={handleClose}>
						<div className="close-btn-wrap"></div>
						<AiOutlineCloseCircle />
					</Button>
					<Modal.Body>
						<div className="modal-img-container ">
							{type == "supermarket" ? (
								<ImageSuperMarket src={item.image} />
							) : (
								<Image src={item.image} />
							)}
						</div>
					</Modal.Body>
				</Modal>
			)}

			{item && (
				<Modal
					show={showCart}
					className={`custom-width   ${theme ? "food-modal" : ""}`}
					onHide={handleCartClose}
				>
					<Button className="close-btn" onClick={handleCartClose}>
						<AiOutlineCloseCircle />
					</Button>
					<Modal.Body>
						<div className="modal-img-container br">
							{type == "supermarket" ? (
								<ImageSuperMarket src={item.image} />
							) : (
								<Image src={item.image} />
							)}
						</div>
						<div className="modal-text-container sub-menu">
							<div className="pl-4">
								<h2>{item.name}</h2>
								<p className="text-gray">
									{item.description ? item.description : ""}
								</p>
							</div>
							<div className="price-container px-4 pt-2 pb-3">
								<p>
									<Currency currency={currency} />{" "}
									{item.price}
								</p>
								<div className="count-order-amount">
									<Button
										className="small"
										onClick={handleIncrease}
									>
										<i className="fa fa-minus"></i>
									</Button>
									<p>{count}</p>
									<Button
										className=" small"
										onClick={handleDecrease}
									>
										<i className="fa fa-plus"></i>
									</Button>
								</div>
							</div>
							<form action="" onSubmit={addToCartV2}>
								{item?.extras_with_options?.length > 0
									? item?.extras_with_options.map(
											(opti, idx) => {
												return (
													<>
														<div
															className="extras-heading"
															key={idx}
														>
															<p className="text">
																{opti.name}
															</p>
															<p className="required">
																{" "}
																{opti?.is_required
																	? t(
																			"Required"
																	  )
																	: ""}
															</p>
														</div>
														{opti.extra_type_name ==
															"text" && (
															<div className="extra_input">
																<input
																	type="text"
																	id={
																		opti.extra_id
																	}
																	className="ex_class"
																/>
															</div>
														)}
														<div
															className="extras-items"
															id={`idx${idx}`}
														>
															{opti?.option
																? opti?.option.map(
																		(
																			extra,
																			index
																		) => {
																			return (
																				<div
																					className="extras-item"
																					key={
																						index
																					}
																				>
																					<p className="item-text">
																						{
																							extra.name
																						}
																					</p>
																					<div
																						className="res_options"
																						key={
																							index
																						}
																					>
																						{extra?.price >
																							0 && (
																							<p
																								style={{
																									marginRight:
																										"5px !important",
																								}}
																							>
																								+
																								<Currency
																									currency={
																										extra.currency
																									}
																								/>
																								{
																									extra.price
																								}
																							</p>
																						)}

																						{opti.extra_type_name ==
																						"radio" ? (
																							<Form.Check
																								type={
																									opti.extra_type_name
																								}
																								name={
																									opti.name
																								}
																								id={
																									opti.name
																								}
																								className="item-check"
																								required={
																									opti.is_required
																								}
																								// disabled={
																								//   extras.length >= 2 &&
																								//   !extras.includes(extra.name)
																								// }
																								onChange={(
																									event
																								) =>
																									handleHandleOptionsChanges(
																										event,
																										opti.name,
																										extra.id,
																										opti.extra_type_name,
																										extra.price
																									)
																								}
																								aria-label="radio 1"
																							/>
																						) : (
																							<Form.Check
																								type={
																									opti.extra_type_name
																								}
																								name={
																									extra.name
																								}
																								id={
																									extra.name
																								}
																								className="item-check"
																								required={
																									index ==
																									0
																										? opti.is_required
																										: false
																								}
																								onInvalid={
																									handleInvalid
																								}
																								onChange={(
																									event
																								) =>
																									handleHandleOptionsChanges(
																										event,
																										opti.name,
																										extra.id,
																										opti.extra_type_name,
																										extra.price,
																										idx,
																										opti.max_options
																									)
																								}
																								aria-label="radio 1"
																							/>
																						)}
																					</div>
																				</div>
																			);
																		}
																  )
																: null}
														</div>
													</>
												);
											}
									  )
									: null}
								<div className="full-div mt-4 modal-add-cart">
									<Button
										// onClick={() =>
										//   addToCart({
										//     props: { item, cart, setCart, count, handleCartClose },
										//   })
										// }
										type="submit"
										className="reg-btn big w-100 flex-div"
										disabled={loading}
									>
										<div className="plus-container">
											<i className="fa fa-plus-square"></i>{" "}
											<span>{t("Add To Cart")}</span>
										</div>
										<div>
											{item.price == "0.00" &&
											priceOption == 0 ? (
												<span
													style={{ fontSize: "12px" }}
												>
													{t("Price upon selection")}
												</span>
											) : (
												<>
													<Currency
														currency={currency}
													/>
													{parseFloat(
														item.price * count +
															priceOption
													).toFixed(2)}
												</>
											)}
										</div>
									</Button>
								</div>
							</form>
							{/* <div className="full-div padd-div mt-4">
                {!search && (
                  <Button
                    onClick={addToCartV2}
                    className="reg-btn big w-100 flex-div"
                    disabled={loading}
                  >
                    <div>
                      <i className="fa fa-plus-square mx-2"></i>
                      {t("Add To Cart")}
                    </div>
                    <div>
                      <span className="fw-600">
                        {parseFloat(item.price * count).toFixed(2)}
                      </span>
                    </div>
                  </Button>
                )}
              </div> */}
						</div>
					</Modal.Body>
				</Modal>
			)}
		</>
	);
}
export default PharmacyCartPost;
