import React, { useContext, useEffect, useRef, useState } from "react";
import ImgGIF from "./assets/images/QRExpired.gif";
import animation from "./assets/images/animation.mp4";
import HeaderInner from "./Components/HeaderInner";
// import { QrReader } from "react-qr-reader";

import { Html5QrcodeScanner, Html5Qrcode } from "html5-qrcode";
import { useLocation, useNavigate } from "react-router-dom";
import { t } from "i18next";
import { ThemeContext } from "./context/ThemeContext";

const ScanQR = () => {
	const location = useLocation();
	const navigate = useNavigate();
	const rawPath = location.pathname;
	const room_uuid = rawPath.split("/")[1];
	const qrConfig = { fps: 10, qrbox: { width: 300, height: 300 } };
	const brConfig = { fps: 10, qrbox: { width: 300, height: 150 } };
	var html5QrCode;
	const fileRef = useRef(null);
	const [cameraList, setCameraList] = useState([]);
	const [activeCamera, setActiveCamera] = useState();
	const [check, setCheck] = useState(false);

	// useEffect(() => {
	//   if (!check) {
	//     setCheck(true);
	//   }
	//   // html5QrCode = new Html5Qrcode("reader");
	//   getCameras();
	//   // const oldRegion = document.getElementById("qr-shaded-region");
	//   // oldRegion && oldRegion.remove();
	// }, []);

	// if (check) {
	//   html5QrCode = new Html5Qrcode("reader");
	// }

	// const handleClickAdvanced = () => {
	//   const qrCodeSuccessCallback = (decodedText, decodedResult) => {
	//     console.info(decodedResult, decodedText);
	//     //  props.onResult(decodedText);
	//     // alert(`decoded:__ ${decodedText}`);
	//     if (decodedText.includes("http")) {
	//       setLoading(true);
	//       window.location.replace(decodedText);
	//     }

	//     handleStop();
	//   };
	//   html5QrCode
	//     .start({ facingMode: "environment" }, qrConfig, qrCodeSuccessCallback)
	//     .then(() => {
	//       // const oldRegion = document.getElementById("qr-shaded-region");
	//       // if (oldRegion) oldRegion.innerHTML = "";
	//     });
	// };
	// const getCameras = () => {
	//   Html5Qrcode.getCameras()
	//     .then((devices) => {
	//       /**
	//        * devices would be an array of objects of type:
	//        * { id: "id", label: "label" }
	//        */
	//       console.info(devices);
	//       if (devices && devices.length) {
	//         setCameraList(devices);
	//         setActiveCamera(devices[0]);
	//       }
	//     })
	//     .catch((err) => {
	//       console.error(err);
	//       setCameraList([]);
	//     });
	// };

	// const handleStop = () => {
	//   try {
	//     html5QrCode
	//       .stop()
	//       .then((res) => {
	//         html5QrCode.clear();
	//       })
	//       .catch((err) => {
	//       });
	//   } catch (err) {
	//   }
	// };

	const [data, setData] = useState("No result");
	const [scannerData, setScannerData] = useState("");
	const [openCamera, setOpenCamera] = useState(false);
	const [loading, setLoading] = useState(false);

	// const new_design = localStorage.getItem("new_design");
	// const theme = new_design == "true";
	const { theme } = useContext(ThemeContext);

	return (
		<div className={theme ? "food" : ""}>
			<div className="scan">
				<img src={ImgGIF} alt="" />

				<p>{t("Please Scan the QR Code Again")}</p>

				{!loading && (
					<button
						onClick={() => navigate(`/${room_uuid}/scan-qr-code`)}
					>
						{t("Start Camera")}
					</button>
				)}
			</div>
		</div>
	);
};

export default ScanQR;
