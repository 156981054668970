import React, { useContext } from "react";
import HeaderInner from "../../Components/HeaderInner";
import Textheader from "../../Components/Textheader";
import Clientinfo from "../../Components/Clientinfo";
import Post1 from "../../assets/images/Spa/Post-3.png";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Gif from "../../assets/images/add-to-cart.gif";
import NewFooter from "../../NewFooter";
import Title from "../../new-components/title/Title";
import "../../new-pages/hotel-services/HotelServices.scss";
import { ThemeContext } from "../../context/ThemeContext";
import Sar from "../../assets/images/icons/Sar";

function SpaOrderPlaced() {
	const currency = localStorage.getItem("currency");

	const { t, i18n } = useTranslation();
	const location = useLocation();
	const rawPath = location.pathname;
	const room_uuid = rawPath.split("/")[1];
	const salon = location.state.salon;
	const {
		orderNumber,
		paymentMethodText,
		roomNumber,
		orderedAt,
		order_number,
		total_price,
		price,
		servises,
		salonAtHomeOrder,
		order,
	} = location.state?.metadata;
	const datameta = location.state?.metadata;
	// const theme = location.state?.theme;
	// const new_design = localStorage.getItem("new_design");
	// const theme = new_design == "true";
	const { theme } = useContext(ThemeContext);
	console.log("location.state?.metadata", location.state?.theme);
	return (
		<div className={theme ? "food" : ""}>
			{theme ? <Title title={""} /> : <HeaderInner headerName={""} />}
			<main className="bg-white back-white pad-top-0 spa-order-placed">
				<div className="img-pnl">
					<img src={Gif} alt="Place Order" />
				</div>
				<h2 className="order-placed">
					{t("Your booking is confirmed")}
				</h2>
				<Clientinfo
					className={"spa"}
					roomNumber={roomNumber}
					orderNumber={orderNumber ? orderNumber : order_number}
					orderedAt={orderedAt}
				/>

				<div className="full-heading grey-bg flex">
					<h2 className="blck co-header">{t("Order Details")} </h2>
				</div>
				<div className="full-div padd-div">
					{salon ? (
						<div className="booking-order-detail">
							<div className="img-pnl">
								<img
									src={
										salonAtHomeOrder?.image ||
										datameta?.salonAtHomeOrder?.image
									}
									alt="Spa"
								/>
							</div>
							<div className="txt-pnl">
								<p>
									{salonAtHomeOrder?.name ||
										datameta?.salonAtHomeOrder?.name}
								</p>
								<h3>
									{total_price != 0 && (
										<>
											{currency === "SAR" ? (
												<>
													<Sar color="currentColor" />
													{""}
												</>
											) : (
												`${currency} `
											)}
											<span style={{ marginLeft: "2px" }}>
												{total_price}
											</span>
										</>
									)}
								</h3>
							</div>
						</div>
					) : order ? (
						<div className="booking-order-detail">
							<div className="img-pnl">
								<img
									src={
										order.image ||
										datameta?.salonAtHomeOrder?.image
									}
									alt="Spa"
								/>
							</div>
							<div className="txt-pnl">
								<p>{order.name}</p>
								<h3> </h3>
							</div>
						</div>
					) : (
						<div className="booking-order-detail">
							<div className="img-pnl">
								<img
									src={
										servises?.image ||
										datameta?.salonAtHomeOrder?.image
									}
									alt="Spa"
								/>
							</div>
							<div className="txt-pnl">
								<p>
									{servises?.name ||
										datameta?.salonAtHomeOrder?.name}
								</p>
								<h3>
									{currency === "SAR" ? (
										<>
											<Sar color="currentcolor" />
										</>
									) : (
										`${currency} `
									)}{" "}
									{price || datameta?.total_price}
								</h3>
							</div>
						</div>
					)}

					{/* <div className="booking-order-detail">
            <div className="img-pnl">
              <img src={Post1} alt="Spa" />
            </div>
            <div className="txt-pnl">
              <p>REVIVING JOURNEY</p>
            </div>
          </div> */}
				</div>
				<div className="spacer-40"></div>
				<div className="full-div padd-div text-center">
					<Link className="reg-btn big" to={`/${room_uuid}/qr-code`}>
						{" "}
						{t("Back to Home")}
					</Link>
				</div>
			</main>
			{theme && <NewFooter />}
		</div>
	);
}
export default SpaOrderPlaced;
