import React, { useEffect, useState } from "react";
import HeaderInner from "../../Components/HeaderInner";
import { Button, Modal } from "react-bootstrap";
import LaundryCarosle from "../../Components/LaundryCarosle";
import ViewLaundryCartModal from "../../Components/ViewLaundryCartModal";
import { useLocation, useNavigate, useNavigation } from "react-router-dom";
import { RxCross2 } from "react-icons/rx";
import axios from "../../axios";
import LaundryLoader from "../../Components/Loaders/LaundryLoader";
import { ShowLaundry } from "../../API/LaundryAPI";
import { useTranslation } from "react-i18next";
import { Accordion, AccordionTab } from "primereact/accordion";
import ReactGA from "react-ga4";
import TaxInclusive from "../../Components/TaxInclusive.";
import CustomBusinessServiceLoader from "../../Components/Loaders/CustomBusinessServiceLoader";
import { useBusinessNameContext } from "./../../context/BusinessName";

function Laundry() {
	const { businessName, setBusinessName } = useBusinessNameContext();
	// const [laundryId, setLaundryId] = useState();
	const location = useLocation();
	const rawPath = location.pathname;
	const room_uuid = rawPath.split("/")[1];
	const handleClose = () => setShow(false);
	const [show, setShow] = useState(false);
	const handleShow = () => {
		setShow(true);
		ReactGA.event({
			category: "Click on Add Item in laundry page ",
			action: "Click",
			value: ``,
		});
	};
	const laundry_uuid = rawPath.split("laundry/")[1];
	const lang = localStorage.getItem("lang");
	const { t, i18n } = useTranslation();
	const navigate = useNavigate();
	let la;
	let images;
	let bgColor;
	let color;
	let text;
	let price_expression;
	let laundryId;
	let service_fee;
	let discount_percentage;
	let vat;
	let min_amount;
	let use_banners_in_popup = false;
	const { data, isLoading, error } = ShowLaundry(
		room_uuid,
		laundry_uuid,
		lang
	);
	if (!isLoading) {
		if (data.data.status === false) {
			navigate(`/${room_uuid}/qr-scan`);
		} else {
			la = data.data.data;
			images = la?.header_images;
			use_banners_in_popup = la?.use_banners_in_popup;
			price_expression = la?.price_expression;
			color = data.data.data.color;
			bgColor = data.data.data.header_color;
			text = data.data.data.header_title;
			laundryId = la.laundry.id;
			vat = la.laundry.vat;
			min_amount = la.laundry.min_amount;
			service_fee = la.laundry.service_fee;
			discount_percentage = la.laundry.discount_percentage;
		}
	}

	return (
		<>
			<HeaderInner headerName={text} />
			<main className="bg-white back-white pad-top-0">
				{isLoading ? (
					<CustomBusinessServiceLoader
						businessName={businessName}
						loader={"laundry"}
					/>
				) : (
					<>
						<LaundryCarosle headerImages={images} />
						<div
							className="full-div padd-div"
							style={{ marginTop: "45px" }}
						>
							<Button
								className="reg-btn big d-flex justify-content-center align-items-center laundary-add-btn"
								onClick={handleShow}
							>
								<i className="fa-solid fa-plus"></i>
								<span>{t("Add Item")}</span>
								<span></span>
							</Button>
							<div className="view-cart-body text-center">
								<h3 className="text-center">
									<TaxInclusive />
								</h3>
							</div>
							<Accordion
								multiple
								expandIcon="fa fa-plus"
								collapseIcon="fa fa-minus"
								className="mb-4"
								onClick={() => {
									ReactGA.event({
										category:
											"Click on buttons [Disclaimer, Terms and Conditions] in laundry page ",
										action: "Click",
										value: ``,
									});
								}}
							>
								{la.laundry.disclaimers_name &&
								la.laundry.disclaimers_name?.length > 0 ? (
									<AccordionTab
										key={"Disclaimer"}
										header={t("Disclaimer")}
										disabled={false}
									>
										<div
											dangerouslySetInnerHTML={{
												__html: la.laundry
													.disclaimers_name,
											}}
										/>
									</AccordionTab>
								) : null}
								{la.laundry.taxiDisclaimer_name &&
								la.laundry.taxiDisclaimer_name != "" ? (
									<AccordionTab
										key={"Disclaimer1"}
										header={t("Disclaimer Name")}
										disabled={false}
									>
										<div
											dangerouslySetInnerHTML={{
												__html: la.laundry
													.taxiDisclaimer_name,
											}}
										/>
									</AccordionTab>
								) : null}
								{la.laundry.termsAndCondition_name &&
								la.laundry.termsAndCondition_name != "" ? (
									<AccordionTab
										key={"Disclaimer3"}
										header={t("Terms and Conditions")}
										disabled={false}
									>
										<div
											dangerouslySetInnerHTML={{
												__html: la.laundry
													.termsAndCondition_name,
											}}
										/>
									</AccordionTab>
								) : null}
							</Accordion>
						</div>
					</>
				)}

				<ViewLaundryCartModal
					show={show}
					handleClose={handleClose}
					laundryId={laundryId}
					laundry_uuid={laundry_uuid}
					itemChange={null}
					vat={vat}
					service_fee={service_fee}
					discount_percentage={discount_percentage}
					min_amount={min_amount}
					data={data}
					images={images}
					use_banners_in_popup={use_banners_in_popup}
				/>
			</main>
		</>
	);
}
export default Laundry;
