import React, { useState } from "react";
import { Accordion } from "react-bootstrap";
import Payment1 from "../../assets/images/Payments/Payment-3.png";
import Textheader from "../../Components/Textheader";
import Clientinfo from "../../Components/Clientinfo";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Currency from "../../Components/Currency";

function RestaurantOrder() {
	const location = useLocation();
	const rawPath = location.pathname;
	const room_uuid = rawPath.split("/")[1];

	const [drop, setDrop] = useState(false);
	const toggleDrop = () => {
		setDrop((prev) => (prev === true ? false : true));
	};

	const {
		orderNumber,
		payment_method_text,
		paymentMethodText,
		roomNumber,
		room_number,
		total,
		ordered_at,
		order_price,
		discount,
		order,
		subtotal,
		order_rows,
		combo_meals,
		order_number,
		deliveryCharge,
		orderedAt,
	} = location.state?.metadata;
	const currency = localStorage.getItem("currency");
	const has_extra_services = localStorage.getItem("has_extra_services");

	const { t } = useTranslation();
	const tempItems = order?.map((order) => {
		return order.items.map((aa) => aa);
	});
	let items;
	if (tempItems) {
		items = [].concat(...tempItems);
	} else {
		items = order_rows;
	}
	const tempcombo_meals = order?.map((order) => {
		return order.combo_meals.map((aa) => aa);
	});
	let combo_mealsdata;
	if (tempcombo_meals) {
		combo_mealsdata = [].concat(...tempcombo_meals);
	} else {
		combo_mealsdata = combo_meals;
	}
	console.log("tempItems", items);
	return (
		<div className="food">
			<main className="">
				<Textheader />
				<Clientinfo
					roomNumber={roomNumber || room_number}
					orderNumber={orderNumber || order_number}
					orderedAt={orderedAt || ordered_at}
				/>

				<div className="full-heading grey-bg flex">
					<h2 className="blck co-header">{t("Order Details")}:</h2>
				</div>
				<div className="padd-div order-accordian">
					<Accordion className="v2" orders>
						{items?.length > 0 ? (
							items?.map((item) => {
								return (
									<Accordion.Item eventKey={item.id}>
										<Accordion.Header>
											<p>
												<b>
													×{" "}
													{item.quantity
														? item.quantity
														: 1}
												</b>{" "}
												{item.name}
											</p>
											{item?.price !== 0 && (
												<p>
													<Currency
														currency={currency}
													></Currency>{" "}
													{item.price}{" "}
													<svg
														xmlns="http://www.w3.org/2000/svg"
														width="12.297"
														height="7.563"
														viewBox="0 0 12.297 7.563"
													>
														<g
															id="Group_38233"
															data-name="Group 38233"
															transform="translate(3338.363 10297.648) rotate(-90)"
														>
															<line
																id="Line_40"
																data-name="Line 40"
																x1="4.734"
																y2="4.734"
																transform="translate(10291.5 -3336.949)"
																fill="none"
																stroke="#006390"
																stroke-linecap="round"
																stroke-width="2"
															></line>
															<line
																id="Line_41"
																data-name="Line 41"
																x1="4.734"
																y1="4.734"
																transform="translate(10291.5 -3332.215)"
																fill="none"
																stroke="#006390"
																stroke-linecap="round"
																stroke-width="2"
															></line>
														</g>
													</svg>
												</p>
											)}
										</Accordion.Header>
										<Accordion.Body>
											<div className="d-flex">
												<div className="img-pnl">
													<img
														src={item.image}
														alt="Pizza"
													/>
												</div>
												<div className="text-pnl">
													<div
														className="full-div d-flex"
														style={{
															justifyContent:
																"space-between",
															alignItems: "start",
															marginTop: "15px",
														}}
													>
														<h6>
															{item?.quantity}{" "}
															{item?.name}
															{item.option
																?.length >
																0 && (
																<div class="middel middel2">
																	<a
																		onClick={
																			toggleDrop
																		}
																		class="arrowdown"
																	>
																		<span className="extra">
																			Extra
																		</span>
																		<svg
																			xmlns="http://www.w3.org/2000/svg"
																			width="12.297"
																			height="7.563"
																			viewBox="0 0 12.297 7.563"
																		>
																			<g
																				id="Group_38233"
																				data-name="Group 38233"
																				transform="translate(3338.363 10297.648) rotate(-90)"
																			>
																				<line
																					id="Line_40"
																					data-name="Line 40"
																					x1="4.734"
																					y2="4.734"
																					transform="translate(10291.5 -3336.949)"
																					fill="none"
																					stroke="#006390"
																					stroke-linecap="round"
																					stroke-width="2"
																				></line>
																				<line
																					id="Line_41"
																					data-name="Line 41"
																					x1="4.734"
																					y1="4.734"
																					transform="translate(10291.5 -3332.215)"
																					fill="none"
																					stroke="#006390"
																					stroke-linecap="round"
																					stroke-width="2"
																				></line>
																			</g>
																		</svg>
																	</a>

																	<div
																		className={
																			drop
																				? "ex-d mystyle d-block"
																				: "ex-d d-none"
																		}
																		// style={{ display: "none" }}
																	>
																		{item.option &&
																			item.option.map(
																				(
																					extra
																				) => {
																					return (
																						<p
																							style={{
																								height: "unset",
																							}}
																						>
																							{
																								extra.name
																							}
																						</p>
																					);
																				}
																			)}
																		{item.text &&
																			Object.values(
																				item.text
																			).map(
																				(
																					extra
																				) => {
																					return (
																						<p
																							style={{
																								height: "unset",
																							}}
																						>
																							{
																								extra
																							}
																						</p>
																					);
																				}
																			)}
																	</div>
																</div>
															)}
															{item.text?.length >
																0 &&
															item.option
																?.length ===
																0 ? (
																<div class="middel middel2">
																	<a
																		onClick={
																			toggleDrop
																		}
																		class="arrowdown"
																	>
																		<span className="extra">
																			Extra
																		</span>
																		<svg
																			xmlns="http://www.w3.org/2000/svg"
																			width="12.297"
																			height="7.563"
																			viewBox="0 0 12.297 7.563"
																		>
																			<g
																				id="Group_38233"
																				data-name="Group 38233"
																				transform="translate(3338.363 10297.648) rotate(-90)"
																			>
																				<line
																					id="Line_40"
																					data-name="Line 40"
																					x1="4.734"
																					y2="4.734"
																					transform="translate(10291.5 -3336.949)"
																					fill="none"
																					stroke="#006390"
																					stroke-linecap="round"
																					stroke-width="2"
																				></line>
																				<line
																					id="Line_41"
																					data-name="Line 41"
																					x1="4.734"
																					y1="4.734"
																					transform="translate(10291.5 -3332.215)"
																					fill="none"
																					stroke="#006390"
																					stroke-linecap="round"
																					stroke-width="2"
																				></line>
																			</g>
																		</svg>
																	</a>

																	<div
																		className={
																			drop
																				? "ex-d mystyle d-block"
																				: "ex-d d-none"
																		}
																	>
																		{item.text &&
																			Object.values(
																				item.text
																			).map(
																				(
																					extra
																				) => {
																					return (
																						<p
																							style={{
																								height: "unset",
																							}}
																						>
																							{
																								extra
																							}
																						</p>
																					);
																				}
																			)}
																	</div>
																</div>
															) : null}
														</h6>
														{has_extra_services ===
														"false"
															? item?.price !==
																	0 && (
																	<p>
																		<Currency
																			currency={
																				currency
																			}
																		></Currency>{" "}
																		{
																			item?.price
																		}
																	</p>
															  )
															: null}
													</div>

													<div className="flex-div"></div>
												</div>
											</div>
										</Accordion.Body>
									</Accordion.Item>
								);
							})
						) : (
							<Accordion.Item eventKey={"items.id"}>
								<Accordion.Header>
									<p>
										<b>× {1}</b>{" "}
										{items?.service_name
											? items?.service_name
											: items?.type}
									</p>
									{has_extra_services === "false"
										? items?.price && (
												<p>
													<Currency
														currency={currency}
													></Currency>{" "}
													{items?.price}
													<svg
														xmlns="http://www.w3.org/2000/svg"
														width="12.297"
														height="7.563"
														viewBox="0 0 12.297 7.563"
													>
														<g
															id="Group_38233"
															data-name="Group 38233"
															transform="translate(3338.363 10297.648) rotate(-90)"
														>
															<line
																id="Line_40"
																data-name="Line 40"
																x1="4.734"
																y2="4.734"
																transform="translate(10291.5 -3336.949)"
																fill="none"
																stroke="#006390"
																stroke-linecap="round"
																stroke-width="2"
															></line>
															<line
																id="Line_41"
																data-name="Line 41"
																x1="4.734"
																y1="4.734"
																transform="translate(10291.5 -3332.215)"
																fill="none"
																stroke="#006390"
																stroke-linecap="round"
																stroke-width="2"
															></line>
														</g>
													</svg>
												</p>
										  )
										: null}
								</Accordion.Header>
								<Accordion.Body>
									<div className="d-flex">
										<div className="img-pnl">
											<img
												src={order_rows?.image}
												alt="Laundry"
											/>
										</div>
										<div className="text-pnl">
											<div
												className="full-div d-flex"
												style={{
													justifyContent:
														"space-between",
												}}
											>
												<p>
													{" "}
													{order_rows?.service_name}
												</p>
												{order_rows?.price && (
													<p>{order_rows?.price}</p>
												)}
											</div>
										</div>
									</div>
								</Accordion.Body>
							</Accordion.Item>
						)}
						{combo_mealsdata?.length > 0
							? combo_mealsdata?.map((item) => {
									return (
										<Accordion.Item eventKey={item.id}>
											<Accordion.Header>
												<p>
													<b>
														×{" "}
														{item.quantity
															? item.quantity
															: 1}
													</b>{" "}
													{item.name}
												</p>
												{has_extra_services === "false"
													? item.price && (
															<p>
																<Currency
																	currency={
																		currency
																	}
																></Currency>{" "}
																{item.price}{" "}
																<svg
																	xmlns="http://www.w3.org/2000/svg"
																	width="12.297"
																	height="7.563"
																	viewBox="0 0 12.297 7.563"
																>
																	<g
																		id="Group_38233"
																		data-name="Group 38233"
																		transform="translate(3338.363 10297.648) rotate(-90)"
																	>
																		<line
																			id="Line_40"
																			data-name="Line 40"
																			x1="4.734"
																			y2="4.734"
																			transform="translate(10291.5 -3336.949)"
																			fill="none"
																			stroke="#006390"
																			stroke-linecap="round"
																			stroke-width="2"
																		></line>
																		<line
																			id="Line_41"
																			data-name="Line 41"
																			x1="4.734"
																			y1="4.734"
																			transform="translate(10291.5 -3332.215)"
																			fill="none"
																			stroke="#006390"
																			stroke-linecap="round"
																			stroke-width="2"
																		></line>
																	</g>
																</svg>
															</p>
													  )
													: null}
											</Accordion.Header>
											<Accordion.Body>
												{console.log(
													"itemsitems",
													items
												)}

												<div className="d-flex">
													<div className="img-pnl">
														<img
															src={item.image}
															alt="Pizza"
														/>
													</div>
													<div className="text-pnl">
														<div
															className="full-div d-flex"
															style={{
																justifyContent:
																	"space-between",
																alignItems:
																	"start",
																marginTop:
																	"15px",
															}}
														>
															<h6>
																-
																{item?.quantity}{" "}
																{item?.name}
																{item?.items
																	?.length >
																	0 && (
																	<div class="middel middel2">
																		<a
																			onClick={
																				toggleDrop
																			}
																			class="arrowdown"
																		>
																			<span className="extra">
																				Items
																			</span>
																			<svg
																				xmlns="http://www.w3.org/2000/svg"
																				width="12.297"
																				height="7.563"
																				viewBox="0 0 12.297 7.563"
																			>
																				<g
																					id="Group_38233"
																					data-name="Group 38233"
																					transform="translate(3338.363 10297.648) rotate(-90)"
																				>
																					<line
																						id="Line_40"
																						data-name="Line 40"
																						x1="4.734"
																						y2="4.734"
																						transform="translate(10291.5 -3336.949)"
																						fill="none"
																						stroke="#006390"
																						stroke-linecap="round"
																						stroke-width="2"
																					></line>
																					<line
																						id="Line_41"
																						data-name="Line 41"
																						x1="4.734"
																						y1="4.734"
																						transform="translate(10291.5 -3332.215)"
																						fill="none"
																						stroke="#006390"
																						stroke-linecap="round"
																						stroke-width="2"
																					></line>
																				</g>
																			</svg>
																		</a>

																		<div
																			className={
																				drop
																					? "ex-d mystyle d-block"
																					: "ex-d d-none"
																			}
																		>
																			{item.items &&
																				item.items.map(
																					(
																						extra
																					) => {
																						return (
																							<p
																								style={{
																									height: "unset",
																								}}
																							>
																								{
																									extra.name
																								}
																							</p>
																						);
																					}
																				)}
																			{item.text &&
																				Object.values(
																					item.text
																				).map(
																					(
																						extra
																					) => {
																						return (
																							<p
																								style={{
																									height: "unset",
																								}}
																							>
																								{
																									extra
																								}
																							</p>
																						);
																					}
																				)}
																		</div>
																	</div>
																)}
															</h6>
															{has_extra_services ===
															"false"
																? item?.price && (
																		<p>
																			<Currency
																				currency={
																					currency
																				}
																			/>{" "}
																			{
																				item?.price
																			}
																		</p>
																  )
																: null}
														</div>

														<div className="flex-div"></div>
													</div>
												</div>
											</Accordion.Body>
										</Accordion.Item>
									);
							  })
							: null}
					</Accordion>
				</div>
				{has_extra_services === "false" && (
					<div className="padd-div order-accordian ">
						<Accordion
							className="v2"
							defaultActiveKey="0"
							ordertotal
						>
							<Accordion.Item eventKey="0">
								<Accordion.Header>
									<p>{t("Order Total")} </p>
									<p>
										<Currency currency={currency} />{" "}
										{total ? total : order_price?.total}{" "}
										<svg
											xmlns="http://www.w3.org/2000/svg"
											width="12.297"
											height="7.563"
											viewBox="0 0 12.297 7.563"
										>
											<g
												id="Group_38233"
												data-name="Group 38233"
												transform="translate(3338.363 10297.648) rotate(-90)"
											>
												<line
													id="Line_40"
													data-name="Line 40"
													x1="4.734"
													y2="4.734"
													transform="translate(10291.5 -3336.949)"
													fill="none"
													stroke="#006390"
													stroke-linecap="round"
													stroke-width="2"
												></line>
												<line
													id="Line_41"
													data-name="Line 41"
													x1="4.734"
													y1="4.734"
													transform="translate(10291.5 -3332.215)"
													fill="none"
													stroke="#006390"
													stroke-linecap="round"
													stroke-width="2"
												></line>
											</g>
										</svg>
									</p>
								</Accordion.Header>
								<Accordion.Body className="total-body">
									<div className="flex-div">
										<p>{t("Sub Total")} </p>
										<p>
											<Currency
												currency={currency}
											></Currency>
											{subtotal}
										</p>
									</div>

									<div className="flex-div">
										<p>{t("discount")} </p>
										<p>
											<Currency currency={currency} />{" "}
											{discount}
										</p>
									</div>

									{deliveryCharge !== "hidden" && (
										<div className="flex-div">
											<p>{t("Delivery Charge")} </p>
											<p>
												<Currency
													currency={currency}
												></Currency>{" "}
												{deliveryCharge}
											</p>
										</div>
									)}
									<div className="flex-div">
										<h6>{t("Order Total")}</h6>
										<h6>
											<Currency
												currency={currency}
											></Currency>
											{total}
										</h6>
									</div>
								</Accordion.Body>
							</Accordion.Item>
						</Accordion>
					</div>
				)}

				{paymentMethodText && (
					<>
						<div className="full-heading grey-bg flex">
							<h2 className="blck co-header">
								{t("Payment Method")}{" "}
							</h2>
							<h2 className="grey">
								<img src={Payment1} alt="cash" />
								{t(`${paymentMethodText}`)}
							</h2>
						</div>
					</>
				)}
				{payment_method_text && payment_method_text !== "" && (
					<>
						<div className="full-heading grey-bg flex">
							<h2 className="blck co-header">
								{t("Payment Method")}{" "}
							</h2>
							<h2 className="grey">
								<img src={Payment1} alt="cash" />
								{t(`${payment_method_text}`)}
							</h2>
						</div>
					</>
				)}
				<div className="full-div padd-div text-center">
					<Link
						className="reg-btn large"
						to={`/${room_uuid}/qr-code`}
					>
						{t("Back to Home")}
					</Link>
				</div>
			</main>
		</div>
	);
}
export default RestaurantOrder;
