import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { ShowCheckout } from "../API/CheckoutAPI";
import { useTranslation } from "react-i18next";
import TaxInclusive from "./TaxInclusive.";
import { ThemeContext } from "../context/ThemeContext";
import Currency from "./Currency";

const ViewCart = ({ room_uuid, dining, hideIcon }) => {
	const navigate = useNavigate();
	const { t, i18n } = useTranslation();
	// const new_design = localStorage.getItem("new_design");
	// const theme = new_design == "true";

	const { theme } = useContext(ThemeContext);
	let cart_count = 0;
	let check;
	let totalPrice = 0;
	const has_extra_services = localStorage.getItem("has_extra_services");
	console.log("has_extra_services", has_extra_services);
	const { data, isLoading, error } = ShowCheckout(room_uuid);
	const currency = localStorage.getItem("currency");
	if (!isLoading) {
		if (data?.data?.status == false) {
			// navigate(`/${room_uuid}/qr-scan`);
		} else {
			check = data?.data?.data;
			totalPrice = check["total-price"];
			const tempItems = check?.order?.map((order) => {
				return order.items.map((aa) => aa);
			});
			const flattenedArray = [].concat(...tempItems);
			cart_count =
				flattenedArray.length == 0
					? 0
					: flattenedArray
							?.map((item) => item.quantity)
							.reduce((acc, curr) => (acc += curr));
		}
	}
	return (
		<div
			className="viewcart"
			onClick={() => {
				if (hideIcon !== true) {
					navigate(
						dining == true
							? `/${room_uuid}/Checkout-dining`
							: theme
							? `/${room_uuid}/cart`
							: `/${room_uuid}/Checkout`
					);
				}
			}}
		>
			{hideIcon ? null : (
				<div className="view-cart-header">
					<p>
						<span>{cart_count}</span> {t("View cart")}
					</p>
					{has_extra_services == "false" && (
						<p>
							<Currency currency={currency} />
							{totalPrice}
						</p>
					)}
				</div>
			)}

			{has_extra_services == "false" && (
				<div className="view-cart-body text-center">
					<h3 className="footer-vat">
						<TaxInclusive />
					</h3>
				</div>
			)}
		</div>
	);
};

export default ViewCart;
