import React, { useContext, useEffect, useState, useMemo, useRef } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import HeaderInner from "../../Components/HeaderInner";
import PaymentOptionList from "../../Components/PaymentOptionList";
import { Form, Button, Modal, Toast } from "react-bootstrap";
import PhoneInput from "react-phone-input-2";
import { toast } from "react-toastify";

import axios from "../../axios";
import { useCartStore } from "../../store/useStore";
import { object, string } from "yup";
import CartItem from "../../Components/CartItem";
import { ShowCheckout } from "../../API/CheckoutAPI";
import SpaLoader from "../../Components/Loaders/SpaLoader";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import ReactGA from "react-ga4";
import AdditionalItems from "./AdditionalItems";
import HeaderInnerInDining from "../../Components/HeaderInnerInDining";
import { phoneFormat } from "../../constants/api";
import PhoneInputComponent from "../../Components/PhoneInputComponent";
import { ThemeContext } from "../../context/ThemeContext";
import Sar from "../../assets/images/icons/Sar";

function Checkout() {
	const [loadingPromo, setLoadingPromo] = useState(false);
	const [code, setCode] = useState("");
	const [phone, setPhone] = useState("");
	const [email, setEmail] = useState("");
	const [name, setName] = useState("");
	const [flowers_delivery_time, setFlowers_delivery_time] = useState("");
	const [isChecked, setIsChecked] = useState(false);
	const [isDisabled, setIsDisabled] = useState(false);
	const [openMin, setOpenMin] = useState(false);
	const [openAdditional, setOpenAdditional] = useState(false);
	const [is_guest, setIs_guest] = useState(0);
	const [umbrella_room_number, setUmbrella_room_number] = useState("");
	const [family_name, setFamily_name] = useState("");
	const [expressLoader, setExpressLoader] = useState(false);
	const [isRemoved, setIsRemoved] = useState(false);
	const [paymentUrl, setPaymentUrl] = useState("");
	const handleCloseMin = () => {
		setOpenMin(false);
	};

	// const [cartItems, setCartItems] = useState([]);
	// const [roomNumber, setRoomNumber] = useState();
	// const [totalPrice, setTotalPrice] = useState(0);
	const location = useLocation();
	const navigate = useNavigate();
	const queryClient = useQueryClient();
	const rawPath = location.pathname;
	const { t } = useTranslation();
	const metadata = location.state?.metadata;
	const currency = localStorage.getItem("currency");
	const [issExpress, setIsExpress] = useState(false);
	useEffect(() => {
		if (metadata != undefined) {
			// setOpenMin(true);
			toast.error(metadata, {
				position: "bottom-center",
				autoClose: 6000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				theme: "light",
			});
		}
	}, []);
	const loaderRef = useRef(null);
	const room_uuid = rawPath.split("/")[1];
	let roomNumber = "";
	let addItemsLink;
	let isUmbrella = 0;
	let cartItems = [];
	// let totalPrice = 0;
	let check;
	let required;
	let methodPaymant = [];
	let resturan_prie = 0;
	let resturan_min_price = 0;
	let flower_prie = 0;
	let flower_min_price = 0;
	let pharmacy_prie = 0;
	let pharmacy_min_price = 0;
	let supermarket_prie = 0;
	let supermarket_min_price = 0;
	let res_name;
	let ph_name;
	let fl_name;
	let su_name;
	let isPharmacy = false;
	let pharmacyId;
	let additional_items = [];
	let order_business;

	let florest_check = false;
	let detailed_address;
	let municipality;
	// let deliveryCharge;
	let shippingFee;
	let discount;
	let show_checkout_fields;
	let fl_disclaimer;

	const lang = localStorage.getItem("lang");

	const { data, isLoading } = ShowCheckout(room_uuid, lang);

	if (!isLoading) {
		if (data.data.status === false) {
			// navigate(`/${room_uuid}/qr-scan`);
		} else {
			check = data.data.data;
			cartItems = check.order;
			roomNumber = check.roomNumber;
			addItemsLink = check.addItemsLink;
			required = check.required;
			discount = check.discount;
			municipality = check["municipality-tax"];
			// deliveryCharge = check.deliveryCharge + (check.shippingFee == "hidden" ? 0 : check.shippingFee)
			shippingFee = check.shippingFee;
			methodPaymant = check.payment_method;
			show_checkout_fields = check.show_checkout_fields;
			detailed_address = check.room.detailed_address;
			isUmbrella = check.room.isUmbrella;

			// window.scrollTo({
			//   top: 0,
			//   left: 0,
			// });
			check?.order.map((item) => {
				if (item.order_type == "restaurant_order") {
					resturan_min_price = item["min-amount"];
					res_name = item.name;
					order_business = item.order_business;
					item?.items.map((it) => {
						resturan_prie = resturan_prie + it?.price;

						if (it?.additional_items.length > 0) {
							additional_items.push(it);
						}
					});

					// console.log("***", resturan_prie);
				}
				if (item.order_type == "florist_order") {
					florest_check = true;
					flower_min_price = item["min-amount"];
					fl_name = item.name;
					fl_disclaimer = item?.disclaimer;
					item?.items.map((it) => {
						flower_prie = flower_prie + it?.price;
					});
					// console.log("***", resturan_prie);
				}
				if (item.order_type == "pharmacy_order") {
					isPharmacy = true;
					pharmacyId = item?.order_business.id;
					ph_name = item.name;
					pharmacy_min_price = item["min-amount"];
					item?.items.map((it) => {
						pharmacy_prie = pharmacy_prie + it?.price;
					});
					// console.log("***", resturan_prie);
				}
				if (item.order_type == "supermarket_order") {
					supermarket_min_price = item["min-amount"];
					su_name = item.name;
					item?.items.map((it) => {
						supermarket_prie = supermarket_prie + it?.price;
					});
				}
			});
			// roomNumber = roomNumber.replace("Room Number ", "");
			// // console.log("price", check['total-price']);
			// const tempItems = check.order.map((order) => {
			//   return order.items.map((aa) => aa);
			// });
			// const flattenedArray = [].concat(...tempItems);
		}
	}
	const [selected, setSelected] = useState(methodPaymant[0]?.payment_method);
	const [first, serFirst] = useState(true);
	const [deliveryCharge, setdeliveryCharge] = useState(0);
	const [totalPrice, settotalPrice] = useState(0);
	useEffect(() => {
		if (data && first) {
			setIsExpress(data?.data?.data?.express_service);
			setdeliveryCharge(
				(data?.data?.data?.deliveryCharge == "hidden"
					? 0
					: data?.data?.data?.deliveryCharge) +
					(data?.data?.data?.shippingFee == "hidden"
						? 0
						: data?.data?.data?.shippingFee)
			);
			settotalPrice(check["total-price"]);
			serFirst(false);
		}
	}, [data]);

	const [order, setOrder] = useState({
		name: "",
		email: "",
		phone: "",
		note: "",
	});

	useEffect(() => {
		if (isUmbrella == 1) {
			setIs_guest(1);
		} else {
			setIs_guest(0);
		}
	}, [isUmbrella]);

	const handleInputChange = (e) => {
		const { name, value } = e.target;
		setOrder((prev) => ({
			...prev,
			[name]: value,
		}));
	};

	let chekoutSchema = object({
		payment_method: string().required(),
		name: string().required(),
		email: string().email(),
		phone: string().required(),
		note: string(),

		// createdOn: date().default(() => new Date()),
	});

	const { cart, setCart } = useCartStore((state) => ({
		cart: state.cart,
		setCart: state.setCart,
	}));
	// console.log("cart", cart);
	const [flowers_delivery_timeError, setFlowers_delivery_timeError] =
		useState("");
	const checkValEmail =
		order.email.length >= 0 && !/\S+@\S+\.\S+/.test(order.email);
	const checkInputs = () => {
		if (order.name == "") {
			setName("Name is a required field");
		} else {
			setName("");
		}
		if (florest_check && flowers_delivery_time == "") {
			setFlowers_delivery_timeError(
				"Flowers delivery time is a required field"
			);
		} else {
			setFlowers_delivery_timeError("");
		}
		if (order.name == "") {
			setName("Name is a required field");
		} else {
			setName("");
		}
		if (checkValEmail) {
			setEmail("The email must be a valid email address.");
		} else {
			setEmail("");
		}
		if (order.phone == "") {
			setPhone("Phone is a required field");
		} else {
			setPhone("");
		}
	};

	// const new_design = localStorage.getItem("new_design");
	// const theme = new_design == "true";
	const { theme } = useContext(ThemeContext);
	const isUmbrellaReq = () => {
		let ordered;

		if (selected == "pay_online") {
			ordered = {
				payment_method: selected,
				is_guest: is_guest,
				umbrella_room_number: umbrella_room_number,
				family_name: family_name,
				redirect_url: `https://${window.location.host}/${room_uuid}/CheckoutCallback`,
			};
		} else {
			ordered = {
				payment_method: selected,
				is_guest: is_guest,
				umbrella_room_number: umbrella_room_number,
				family_name: family_name,
			};
		}

		setIsDisabled(true);

		axios
			.post(`/${room_uuid}/orders/checkout`, ordered, {
				headers: {
					lang: lang,
				},
			})
			.then((res) => {
				setIsDisabled(false);
				ReactGA.event({
					category: "Order from checkout page",
					action: "Click",
					value: `type: umbrella_room`,
				});
				// console.log("rsjsskes", res?.data?.data?.payment_url);

				if (res.data.status) {
					if (res?.data?.data?.payment_url) {
						setPaymentUrl(res?.data?.data?.payment_url); // Set the payment URL to state
						// window.location.replace(res?.data?.data?.payment_url);
					} else {
						navigate(`/${room_uuid}/RestaurantOrderPlaced`, {
							state: { metadata: res.data.data },
						});
					}
				} else if (res.data?.status == false) {
					toast.error(res.data?.message);
				}
			})
			.catch((err) => {
				setIsDisabled(false);
				console.log(err);
			});
		// navigate("/HCOrderPlaced");
	};

	const POSTReq = (type) => {
		let ordered;
		if (type == true) {
			if (selected == "pay_online") {
				ordered = {
					payment_method: selected,
					name: order.name,
					email: order.email,
					phone: order.phone,
					redirect_url: `https://${window.location.host}/${room_uuid}/CheckoutCallback`,
					note: order.note,
					flowers_delivery_time: flowers_delivery_time,
				};
			} else {
				ordered = {
					payment_method: selected,
					name: order.name,
					email: order.email,
					phone: order.phone,
					note: order.note,
					flowers_delivery_time: flowers_delivery_time,
				};
			}
		} else {
			ordered = {
				payment_method: selected,
				flowers_delivery_time: flowers_delivery_time,
			};
			if (order.email != "") {
				ordered.email = order.email;
			}
			if (order.name != "") {
				ordered.name = order.name;
			}
			if (order.phone != "") {
				ordered.phone = order.phone;
			}
			if (order.note != "") {
				ordered.note = order.note;
			}
		}
		if (isUmbrella == 1) {
			// is_guest
			ordered.is_guest = is_guest;
		}

		setIsDisabled(true);

		axios
			.post(`/${room_uuid}/orders/checkout`, ordered, {
				headers: {
					lang: lang,
				},
			})
			.then((res) => {
				setIsDisabled(false);
				ReactGA.event({
					category: "Order from checkout page",
					action: "Click",
					value: ``,
				});

				// console.log("rsjsskes", res?.data?.data?.payment_url);

				if (res.data.status) {
					if (res?.data?.data?.payment_url) {
						setPaymentUrl(res?.data?.data?.payment_url); // Set the payment URL to state

						// window.location.replace(res?.data?.data?.payment_url);
					} else {
						navigate(`/${room_uuid}/RestaurantOrderPlaced`, {
							state: { metadata: res.data.data },
						});
					}
				} else if (res.data?.status == false) {
					toast.error(res.data?.message);
					queryClient.invalidateQueries(`checkout`);
				}
			})
			.catch((err) => {
				setIsDisabled(false);
				toast.error(err.message);

				console.log(err);
			});
		// navigate("/HCOrderPlaced");
	};
	// console.log("selected", selected);
	const checkPrice = () => {
		if (Number(flower_min_price) > flower_prie) {
			setOpenMin(true);
			return true;
		}
		if (Number(resturan_min_price) > resturan_prie) {
			setOpenMin(true);
			return true;
		}
		if (Number(supermarket_min_price) > supermarket_prie) {
			setOpenMin(true);
			return true;
		}
		if (Number(pharmacy_min_price) > pharmacy_prie) {
			setOpenMin(true);
			return true;
		}
	};
	const checkFlor = florest_check && flowers_delivery_time != "";

	useEffect(() => {
		if (show_checkout_fields == 0) {
			setIsChecked(true);
		}
	}, [show_checkout_fields]);
	const handleCheckout = async (e) => {
		e.preventDefault();

		if (isChecked) {
			// if (Number(totalPrice) >= Number(minAmount)) {

			if (show_checkout_fields == 0) {
				setIsDisabled(true);
				axios
					.post(
						`/${room_uuid}/orders/checkout`,
						{
							note: order.note,
							name: order.name,
						},
						{
							headers: {
								lang: lang,
							},
						}
					)
					.then((res) => {
						setIsDisabled(false);
						ReactGA.event({
							category: "Order from checkout page",
							action: "Click",
							value: ``,
						});
						// console.log("rsjsskes", res?.data?.data?.payment_url);
						if (res.data.status) {
							if (res?.data?.data?.payment_url) {
								setPaymentUrl(res?.data?.data?.payment_url); // Set the payment URL to state

								// window.location.replace(
								// 	res?.data?.data?.payment_url
								// );
							} else {
								navigate(
									`/${room_uuid}/RestaurantOrderPlaced`,
									{
										state: { metadata: res.data.data },
									}
								);
							}
						} else if (res.data?.status == false) {
							toast.error(res.data?.message);
							queryClient.invalidateQueries(`checkout`);
						}
					})
					.catch((err) => {
						setIsDisabled(false);
						toast.error(err.message);
						// toast.error("err.message");
						console.log(err);
					});
			} else {
				if (!checkPrice()) {
					if (isUmbrella == 1 && is_guest == 1) {
						isUmbrellaReq();
					} else if (selected == "pay_online" || required == true) {
						checkInputs();

						if (
							!checkValEmail &&
							order.name != "" &&
							order.phone != ""
						) {
							if (florest_check) {
								if (flowers_delivery_time != "") {
									POSTReq(true);
								}
							} else {
								POSTReq(true);
							}
						}
					} else {
						POSTReq(false);
					}
				}
			}
			// }
			// else {
			//   toast.error(`To proceed with you order for  you need to reach the amount of ${currency} ${minAmount} and you already have ${currency} ${totalPrice}`)
			// }
		} else {
			toast.error(t("Please agree to the terms and conditions"));
		}
	};

	const has_extra_services = localStorage.getItem("has_extra_services");
	useEffect(() => {
		window.scrollTo({
			top: 0,
			left: 0,
		});
	}, []);

	const sendPromoCode = () => {
		setLoadingPromo(true);
		axios
			.post(`/${room_uuid}/orders/apply-code`, { code })
			.then((res) => {
				if (res.data.status) {
					toast.success(res.data.message, {
						className: theme ? "custom-toast" : "",
						bodyClassName: theme ? "custom-toast" : "",
						progressClassName: theme ? "custom-toast" : "",
					});
					queryClient.invalidateQueries(`checkout`);
				} else if (res.data.status == false) {
					toast.error(res.data.message[0]);
				}
				ReactGA.event({
					category: "Send Promo code",
					action: "Click",
					value: `promo code: ${code}`,
				});
				setLoadingPromo(false);
			})
			.catch((err) => {
				setLoadingPromo(false);
			});
	};

	const handleExpressServices = (e) => {
		if (required) {
			if (order.name != "" && order.email != "" && order.phone != "") {
				setExpressLoader(true);

				axios
					.post(
						`/${room_uuid}/pharmacy/toggle-express`,
						{
							pharmacy_id: pharmacyId,
							express_service: e.target.checked ? 1 : 0,
							name: order.name,
							email: order.email,
							phone: order.phone,
						},
						{
							headers: {
								lang: lang,
							},
						}
					)
					.then((res) => {
						// shippingFee = res.data.data.price.shippingFee

						setdeliveryCharge(
							res.data.data.price.deliveryCharge +
								res.data.data.price.shippingFee
						);
						settotalPrice(res.data.data.price.total);
						// queryClient.invalidateQueries(`checkout`);
						setExpressLoader(false);
					})
					.catch((err) => {
						console.log(err);
						setExpressLoader(false);
					});

				setIsExpress(e.target.checked);
			} else {
				toast.error(t("Please fill the required fields below"));
			}
		} else {
			axios
				.post(
					`/${room_uuid}/pharmacy/toggle-express`,
					{
						pharmacy_id: pharmacyId,
						express_service: e.target.checked ? 1 : 0,
						name: order.name,
						email: order.email,
						phone: order.phone,
					},
					{
						headers: {
							lang: lang,
						},
					}
				)
				.then((res) => {
					// shippingFee = res.data.data.price.shippingFee
					queryClient.invalidateQueries(`checkout`);
				})
				.catch((err) => {
					console.log(err);
				});

			setIsExpress(e.target.checked);
		}
	};
	const businessName = localStorage.getItem("business_name");
	useMemo(() => {
		if (selected !== "pay_online") {
			setPaymentUrl("");
		}
	}, [selected]);
	const handleLoad = () => {
		// Hide the loading view when the iframe is loaded
		if (loaderRef.current) {
			loaderRef.current.style.display = "none"; // Hide the loader by setting display to 'none'
			loaderRef.current.style.visibility = "hidden"; // Hide the loader by setting visibility to 'hidden'

			loaderRef.current.remove();
		}
	};
	return (
		<div className={theme ? "food" : ""}>
			{theme ? (
				<HeaderInnerInDining theme={theme} />
			) : (
				<HeaderInner headerName={"Checkout"} />
			)}

			<main className="bg-white back-white v2">
				<Toast ref={toast} position="bottom-center" />
				{isLoading ? (
					<SpaLoader />
				) : isRemoved ? (
					<div className="checkout_loader mt-0">
						<div className="loader"></div>
					</div>
				) : (
					<>
						{cartItems?.map((order) => {
							return order.items.map((item) => {
								return (
									<CartItem
										item={item}
										v2={true}
										order_type={order.order_type}
										url_decrease={order.url_decrease}
										url_increase={order.url_increase}
										url_remove={order.url_remove}
										setIsRemoved={setIsRemoved}
										serFirst={serFirst}
										settotalPrice={settotalPrice}
									/>
								);
							});
						})}

						{cartItems?.map((order) => {
							return order?.combo_meals.map((item) => {
								return (
									<CartItem
										item={item}
										v2={true}
										order_type={order.order_type}
										url_decrease={order.url_meal_decrease}
										url_increase={order.url_meal_increase}
										url_remove={order.url_meal_remove}
										setIsRemoved={setIsRemoved}
										settotalPrice={settotalPrice}
										serFirst={serFirst}
									/>
								);
							});
						})}
					</>
				)}
				{cartItems?.length > 0 && addItemsLink != null ? (
					<div className="d-flex justify-content-center mt-2">
						<Link
							to={`/${addItemsLink.replace(
								"/restaurants?b=kit",
								"/restaurant/categories/10"
							)}`}
							className="reg-btn order-btn btn btn-primary"
							onClick={() => {
								ReactGA.event({
									category:
										"Click on button Add More Items in checkout page",
									action: "Click",
									value: `Add More Items`,
								});
							}}
						>
							{t("Add More Items")}
						</Link>
					</div>
				) : null}
				{show_checkout_fields == 1 && (
					<div className="code-parent mb-4">
						<div class="code-section">
							<div class="left">
								<svg
									xmlns="http://www.w3.org/2000/svg"
									width="19.375"
									height="15.5"
									viewBox="0 0 19.375 15.5"
								>
									<path
										id="Icon_awesome-tags"
										data-name="Icon awesome-tags"
										d="M15.074,6.84,8.66.426A1.453,1.453,0,0,0,7.632,0H1.453A1.453,1.453,0,0,0,0,1.453V7.632A1.453,1.453,0,0,0,.426,8.66L6.84,15.074a1.453,1.453,0,0,0,2.055,0L15.074,8.9a1.453,1.453,0,0,0,0-2.055Zm-11.684-2A1.453,1.453,0,1,1,4.844,3.391,1.453,1.453,0,0,1,3.391,4.844ZM18.949,8.9,12.77,15.074a1.453,1.453,0,0,1-2.055,0l-.011-.011,5.269-5.269a2.725,2.725,0,0,0,0-3.853L10.033,0h1.475a1.453,1.453,0,0,1,1.028.426L18.949,6.84a1.453,1.453,0,0,1,0,2.055Z"
										fill="#006390"
									></path>
								</svg>
								<p>{t("Promo Code")}</p>
							</div>
							<div class="middel">
								<input
									type="text"
									name="code"
									onChange={(e) => setCode(e.target.value)}
								/>
							</div>
							<div class="right">
								<Button
									disabled={loadingPromo}
									onClick={sendPromoCode}
								>
									{t("Apply")}{" "}
								</Button>
							</div>
						</div>
					</div>
				)}

				{expressLoader ? (
					<div className="checkout_loader">
						<div className="loader"></div>
					</div>
				) : (
					<>
						{/* {isPharmacy && (
                <div class="col-12">
                  <div class="mb-3 form-group laundray_check">
                    <input
                      class="accented"
                      type="checkbox"
                      id="is_express"
                      checked={issExpress}
                      name="is_express"
                      onChange={handleExpressServices}
                    />

                    <label class="form-check-label ms-1" for="is_express">
                      Pharmacy instant delivery (within 30 mins)
                    </label>
                  </div>
                </div>
              )} */}

						{discount != "0.0" && (
							<div className="full-heading discount flex m-0 mb-3">
								<h2> {t("Discount")}</h2>
								<h2>
									{currency === "SAR" ? (
										<Sar color="currentcolor" />
									) : (
										`${currency} `
									)}{" "}
									{discount}
								</h2>
							</div>
						)}
						{deliveryCharge != 0 && (
							<div className="full-heading discount flex m-0 mb-3">
								<h2> {t("Delivery Charge")}</h2>
								<h2>
									{currency === "SAR" ? (
										<Sar color="currentcolor" />
									) : (
										`${currency} `
									)}{" "}
									{deliveryCharge}
								</h2>
							</div>
						)}
						{/* {shippingFee != "hidden" && (
                <div className="full-heading discount flex m-0 mb-3">
                  <h2> {t("Shipping Fee")}</h2>
                  <h2>
                    {currency} {shippingFee}
                  </h2>
                </div>
              )} */}
						{municipality != "hidden" && (
							<div className="full-heading discount flex m-0 mb-3">
								<h2> {t("Municipality Tax")}</h2>
								<h2>
									{currency} {municipality}
								</h2>
							</div>
						)}
						{show_checkout_fields == 1 && (
							<div className="full-heading total flex mb-5">
								<h2> {t("Order Total")}</h2>
								<h2>
									{currency === "SAR" ? (
										<Sar color="currentcolor" />
									) : (
										`${currency} `
									)}{" "}
									{totalPrice}
								</h2>
							</div>
						)}
					</>
				)}

				<Form className="px-3 rounded-form" onSubmit={handleCheckout}>
					{show_checkout_fields == 1 ? (
						<>
							{isUmbrella == 1 ? (
								<div className="guest">
									<p>{t("Are you a hotel guest?")} </p>
									<div className="guest_container">
										<div className="padd-div d-flex  ">
											<Form.Check
												type="radio"
												label=""
												checked={is_guest == 1}
												onClick={() => setIs_guest(1)}
											/>
											<span>{t("Yes")}</span>
										</div>
										<div className="padd-div d-flex ">
											<Form.Check
												type="radio"
												label=""
												checked={is_guest == 0}
												onClick={() => setIs_guest(0)}
											/>
											<span>{t("No")}</span>
										</div>
									</div>
								</div>
							) : null}

							{is_guest == 1 && isUmbrella == 1 ? (
								<>
									<Form.Group
										className="mb-3"
										controlId="formBasicEmail"
									>
										<Form.Control
											type="number"
											placeholder={t("Room Number")}
											value={umbrella_room_number}
											required={is_guest == 1}
											onChange={(e) =>
												setUmbrella_room_number(
													e.target.value
												)
											}
										/>
									</Form.Group>
									<Form.Group
										className="mb-3"
										controlId="formBasicEmail"
									>
										<Form.Control
											type="text"
											placeholder={t("Family Name")}
											value={family_name}
											required={is_guest == 1}
											onChange={(e) =>
												setFamily_name(e.target.value)
											}
										/>
									</Form.Group>
								</>
							) : (
								<>
									<Form.Group
										className="mb-3"
										controlId="formBasicEmail"
									>
										<Form.Control
											type="text"
											disabled={true}
											value={`${
												roomNumber
													? `${
															isUmbrella != 1
																? roomNumber.includes(
																		"Name"
																  )
																	? ""
																	: t("")
																: ""
													  } ${roomNumber} `
													: t("Room number")
											}`}
										/>
									</Form.Group>
									{detailed_address != null && (
										<Form.Group
											className="mb-3"
											controlId="formBasicEmail"
										>
											<Form.Control
												type="text"
												disabled={true}
												value={detailed_address}
												// placeholder="Enter Your Room # (Optional)"
											/>
										</Form.Group>
									)}

									<Form.Group
										className="mb-3"
										controlId="formBasicname"
									>
										<Form.Control
											type="text"
											placeholder={t(
												required ||
													selected === "pay_online"
													? "Your Name"
													: "Your Name (Optional)"
											)}
											name="name"
											value={order.name}
											onChange={handleInputChange}
										/>
									</Form.Group>
									{name && (
										<span className="err_msg">
											{t(`${name}`)}{" "}
										</span>
									)}

									<Form.Group
										className="mb-3"
										controlId="formBasicname"
									>
										<Form.Control
											type="emial"
											placeholder={t(
												selected === "pay_online" ||
													required === true
													? "Your Email"
													: "Your Email (Optional)"
											)}
											name="email"
											value={order.email}
											onChange={handleInputChange}
										/>
									</Form.Group>
									{email && (
										<span className="err_msg">
											{t(`${email}`)}
										</span>
									)}

									{/*    <Form.Group className="mb-3" controlId="formBasicname">
              <Form.Select
                className="form-control"
                aria-label="Default select example"
              >
                <option>Date & Time</option>
              </Form.Select>
            </Form.Group> */}

									<Form.Group className="mb-3 w-100">
										<PhoneInputComponent
											value={order.phone}
											onChange={(phone) => {
												setOrder((prev) => ({
													...prev,
													phone,
												}));
											}}
										/>
									</Form.Group>
									{phone && (
										<span className="err_msg">
											{" "}
											{t(`${phone}`)}
										</span>
									)}
									{florest_check && (
										<>
											<Form.Group
												className="mb-3"
												controlId="formBasicname"
											>
												<select
													className="form-control"
													placeholder=""
													// required={true}
													value={
														flowers_delivery_time
													}
													onChange={(e) => {
														setFlowers_delivery_time(
															e.target.value
														);
														ReactGA.event({
															category:
																"Select Flowers_delivery_time in checkout page",
															action: "Click",
															value: `value: ${e.target.value}`,
														});
													}}
												>
													<option value="">
														{t(
															"Flowers Delivery Time"
														)}
													</option>
													<option value="10:00 AM - 12:00 PM">
														10:00 AM - 12:00 PM
													</option>
													<option value="12:00 PM - 02:00 PM">
														12:00 PM - 02:00 PM
													</option>
													<option value="02:00 PM - 04:00 PM">
														02:00 PM - 04:00 PM
													</option>
													<option value="04:00 PM - 06:00 PM">
														04:00 PM - 06:00 PM
													</option>
												</select>
											</Form.Group>
											{flowers_delivery_timeError && (
												<span className="err_msg">
													{t(
														`${flowers_delivery_timeError}`
													)}
												</span>
											)}
										</>
									)}
									<Form.Group
										className="mb-3"
										controlId="formBasicname"
									>
										<Form.Control
											type="text"
											placeholder={t(
												"Note/Special Request (Optional)"
											)}
											name="note"
											value={order.note}
											onChange={handleInputChange}
										/>
									</Form.Group>
								</>
							)}

							<div className="full-heading payment flex">
								<h2>{t("Payment Options")}</h2>
							</div>
							<PaymentOptionList
								selected={selected}
								setSelected={setSelected}
								methods={methodPaymant}
							/>
							{!paymentUrl && (
								<div className="padd-div d-flex agree my-4">
									<input
										type="checkbox"
										checked={isChecked}
										onClick={() => {
											setIsChecked((prev) => !prev);
											ReactGA.event({
												category:
													"click on checkbox in checkout page ",
												action: "Click",
												value: `I agree to Terms & Conditions: ${isChecked}`,
											});
										}}
									/>
									<a
										href="https://thedigitalhotelier.com/terms-and-conditions/"
										target="_blank"
									>
										{t("I agree to Terms & Conditions")}
									</a>
								</div>
							)}
						</>
					) : (
						<>
							<Form.Group
								className="mb-3"
								controlId="formBasicEmail"
							>
								<Form.Control
									type="text"
									disabled={true}
									value={`${
										businessName.includes("Mubadala")
											? roomNumber.replace(
													"Room Number ",
													""
											  )
											: roomNumber
									}`}
								/>
							</Form.Group>
							{t(
								required || selected === "pay_online"
									? "Your Name"
									: "Your Name (Optional)"
							)}
							<Form.Group
								className="mb-3"
								controlId="formBasicname"
							>
								<Form.Control
									type="text"
									placeholder={t("Your Name (Optional)")}
									name="name"
									value={order.name}
									onChange={handleInputChange}
								/>
							</Form.Group>
							<Form.Group
								className="mb-3"
								controlId="formBasicname"
							>
								<Form.Control
									type="text"
									placeholder={t(
										"Note/Special Request (Optional)"
									)}
									name="note"
									value={order.note}
									onChange={handleInputChange}
								/>
							</Form.Group>
						</>
					)}

					{!paymentUrl && (
						<div className="padd-div text-center">
							<Button
								type="submit"
								className="reg-btn order-btn"
								// onClick={}
								disabled={isDisabled || cartItems.length == 0}
								// to="/RestaurantOrderPlaced"
							>
								{t("Order")}
							</Button>
						</div>
					)}
					{fl_disclaimer && (
						<div
							className="mx-3 text-center"
							dangerouslySetInnerHTML={{
								__html: fl_disclaimer,
							}}
						/>
					)}
					{selected === "pay_online" && paymentUrl && (
						<div className="payment-iframe">
							<div
								className="d-flex justify-content-center m-5 "
								ref={loaderRef}
							>
								{/* <div className="home_loader"> */}
								<div
									className="loader"
									style={{
										borderColor: "lightgray",
										borderRightColor: "#000000",
									}}
								></div>
								{/* </div>sss */}
							</div>
							<iframe
								loading="lazy"
								src={paymentUrl}
								width="100%"
								title="Payment Preview"
								scrolling="no"
								onLoad={handleLoad}
							></iframe>
						</div>
					)}
				</Form>

				<div className="spacer-40 my-5"></div>
			</main>
			<AdditionalItems
				setOpenAdditional={setOpenAdditional}
				openAdditional={openAdditional}
				additional_items={additional_items}
				order_business={order_business}
				theme={theme}
				settotalPrice={settotalPrice}
			/>
			<Modal
				show={openMin}
				onHide={handleCloseMin}
				className={` `}
				style={{ minHeight: "150px", height: "auto" }}
			>
				<Modal.Body
					style={{
						padding: "20px",
						height: "auto",
					}}
				>
					{resturan_min_price > resturan_prie && (
						<>
							<p className=" ">
								{`${t(
									"To proceed with you order for"
								)} ${res_name} ${t(
									"you need to reach the amount of"
								)} ${currency} ${resturan_min_price} ${t(
									"and you already have"
								)} ${currency} ${resturan_prie}`}
							</p>
							<Link
								to={`/${room_uuid}/restaurant/categories/10`}
								className="reg-btn check_m"
							>
								Go to restaurant
							</Link>
							<hr />
						</>
					)}

					{flower_min_price > flower_prie && (
						<>
							<p className=" ">
								{`${t(
									"To proceed with you order for"
								)} ${fl_name} ${t(
									"you need to reach the amount of"
								)} ${currency} ${flower_min_price} ${t(
									"and you already have"
								)} ${currency} ${flower_prie}`}
							</p>
							<Link
								to={`/${room_uuid}/florists`}
								className="reg-btn check_m "
							>
								{t("Go to florist")}
							</Link>
							<hr />
						</>
					)}
					{pharmacy_min_price > pharmacy_prie && (
						<>
							<p className=" ">
								{`${t(
									"To proceed with you order for"
								)} ${ph_name} ${t(
									"you need to reach the amount of"
								)} ${currency} ${pharmacy_min_price} ${t(
									"and you already have"
								)} ${currency} ${pharmacy_prie}`}
							</p>
							<Link
								to={`/${room_uuid}/pharmacies`}
								className="reg-btn check_m"
							>
								{t("Go to pharmacy")}
							</Link>
							<hr />
						</>
					)}

					{supermarket_min_price > supermarket_prie && (
						<>
							<p className=" ">
								{`${t(
									"To proceed with you order for"
								)} ${su_name} ${t(
									"you need to reach the amount of"
								)} ${currency} ${supermarket_min_price} ${t(
									"and you already have"
								)} ${currency} ${supermarket_prie}`}
							</p>
							<Link
								to={`/${room_uuid}/supermarkets`}
								className="reg-btn check_m"
							>
								{t("Go to subermarket")}
							</Link>
							<hr />
						</>
					)}
					<div>
						<Button
							onClick={handleCloseMin}
							className="reg-btn v2 service"
							style={{
								textAlign: "center",
								padding: "8.5px 15px !important",
							}}
						>
							{t(`OK`)}
						</Button>
					</div>
				</Modal.Body>
			</Modal>
		</div>
	);
}
export default Checkout;
