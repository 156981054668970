import React, { useEffect, useState } from "react";
import { Accordion } from "react-bootstrap";
import HeaderInner from "../../Components/HeaderInner";
import Pharmacy from "../../assets/images/Pharmacy/Medicine.png";
import Payment1 from "../../assets/images/Payments/Payment-3.png";
import Textheader from "../../Components/Textheader";
import Clientinfo from "../../Components/Clientinfo";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axios from "../../axios";
import SpaLoader from "../../Components/Loaders/SpaLoader";
import { toast } from "react-toastify";
import Sar from "../../assets/images/icons/Sar";

function SupermarketOrderPlaced() {
	const location = useLocation();
	const rawPath = location.pathname;
	const room_uuid = rawPath.split("/")[1];
	const params = useParams();
	const [data, setData] = useState([]);
	const [orders, setOrders] = useState();
	const [loading, setloading] = useState(false);
	const [loadingData, setloadingData] = useState(true);
	const [showSuggestionButtons, setShowSuggestionButtons] = useState(true);
	const { t, i18n } = useTranslation();

	const navigate = useNavigate();
	useEffect(() => {
		//  setLoading(true);
		const About = async () => {
			setloadingData(true);
			// axios.get(`/648ed77e-da78-4987-9b86-de515659fc2c`).then((res) => {
			axios
				.get(
					`/${room_uuid}/orders/supermarket-suggestion/${params.order_id}`
				)
				.then((res) => {
					if (res.data.status === false) {
						navigate(`/${room_uuid}/qr-scan`);
					} else {
						console.log("res", res.data.status);
						// const data = res.data.data;
						// console.log("data***", data);
						setData(res.data.data);
						setOrders(
							res.data.data?.orderRows[0].supermarket_order
								?.order_items
						);
						setShowSuggestionButtons(
							res?.data?.data?.showSuggestionButtons
						);
						setloadingData(false);
					}
				});
		};
		About();
	}, []);

	const currency = localStorage.getItem("currency");

	// const {
	//   orderNumber,
	//   payment_method_text,
	//   paymentMethodText,
	//   roomNumber,
	//   room_number,
	//   total,
	//   ordered_at,
	//   order_price,
	//   discount,
	//   order,
	//   order_rows,
	//   order_number,
	//   datetime,
	//   deliveryCharge,
	//   orderedAt,
	//   transaction_details,
	// } = location.state?.metadata;
	// const { t, i18n } = useTranslation();
	// console.log("location.state?.metadata", location.state?.metadata);
	// const tempItems = order?.map((order) => {
	//   return order.items.map((aa) => aa);
	// });
	// let items;
	// if (tempItems) {
	//   items = [].concat(...tempItems);
	// } else {
	//   items = order_rows;
	// }

	const SendAcc = () => {
		setloading(true);
		axios
			.post(`/${room_uuid}/orders/order-respond-supermarket-suggestion`, {
				order_number: `#${params.order_id}`,
				decision: "accepted",
			})
			.then((res) => {
				setloading(false);
				if (res.data.status) {
					toast.success(t("We reveived your decesion"), {
						position: "bottom-center",
						autoClose: 8000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
						theme: "light",
					});

					setloading(true);
					setShowSuggestionButtons(false);
				}

				console.log("ddk", res.data.status);
			})
			.catch((err) => {
				setloading(false);
				console.log("err", err);
			});
	};
	const SendDec = () => {
		setloading(true);
		axios
			.post(`/${room_uuid}/orders/order-respond-supermarket-suggestion`, {
				order_number: `#${params.order_id}`,
				decision: "declined",
			})
			.then((res) => {
				setloading(false);
				if (res.data.status) {
					toast.success(t("We reveived your decesion"), {
						position: "bottom-center",
						autoClose: 8000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
						theme: "light",
					});
					setloading(true);
					setShowSuggestionButtons(false);
				}
			})
			.catch((err) => {
				setloading(false);
				console.log("err", err);
			});
	};
	return (
		<>
			<HeaderInner />
			{loadingData ? (
				<SpaLoader />
			) : (
				<main className="bg-white pad-top-0 back-white">
					<Textheader />
					<Clientinfo
						roomNumber={data?.roomNumber}
						orderNumber={data?.orderNumber}
						orderedAt={data?.orderedAt}
					/>

					<div className="full-heading grey-bg flex">
						<h2 className="blck co-header">
							{t("Order Details")}:
						</h2>
					</div>
					<div className="padd-div order-accordian">
						<Accordion className="v2" orders>
							{orders?.length > 0
								? orders?.map((item) => {
										return (
											<>
												{item?.substitute?.new_item ? (
													<div className="super">
														<div className="new">
															<p>
																{" "}
																<b>
																	×{" "}
																	{item?.quantity
																		? item?.quantity
																		: 1}
																</b>
																<span className="new_p">
																	{" "}
																	{
																		item
																			?.substitute
																			?.new_item
																			?.name
																	}
																</span>
															</p>
															<p className="new_p">
																{currency}{" "}
																{
																	item
																		?.substitute
																		?.new_item
																		?.discount_price
																}{" "}
															</p>
														</div>
														<div className="old">
															<p>
																{" "}
																{
																	item?.item_name
																}
															</p>
															<p>
																{currency}{" "}
																{item?.price}{" "}
															</p>
														</div>
													</div>
												) : (
													<div className="super">
														<div className="new">
															<p>
																{" "}
																<b>
																	×{" "}
																	{item?.quantity
																		? item?.quantity
																		: 1}
																</b>
																<span className="">
																	{" "}
																	{
																		item?.item_name
																	}
																</span>
															</p>
															<p className="">
																{currency}{" "}
																{item?.price}{" "}
															</p>
														</div>
														{/* <div className="old">
                            <p> {item.item_name}</p>
                            <p>{currency} {item.price} </p>
                          </div> */}
													</div>
												)}
											</>
										);
								  })
								: null}
						</Accordion>
					</div>
					<div className="padd-div order-accordian ">
						<Accordion
							className="v2"
							defaultActiveKey="0"
							ordertotal
						>
							<Accordion.Item eventKey="0">
								<Accordion.Header>
									<p>{t("Order Total")} </p>
									<p>
										{currency}{" "}
										{data?.orderSuggestionPrice?.total}{" "}
										<svg
											xmlns="http://www.w3.org/2000/svg"
											width="12.297"
											height="7.563"
											viewBox="0 0 12.297 7.563"
										>
											<g
												id="Group_38233"
												data-name="Group 38233"
												transform="translate(3338.363 10297.648) rotate(-90)"
											>
												<line
													id="Line_40"
													data-name="Line 40"
													x1="4.734"
													y2="4.734"
													transform="translate(10291.5 -3336.949)"
													fill="none"
													stroke="#006390"
													stroke-linecap="round"
													stroke-width="2"
												></line>
												<line
													id="Line_41"
													data-name="Line 41"
													x1="4.734"
													y1="4.734"
													transform="translate(10291.5 -3332.215)"
													fill="none"
													stroke="#006390"
													stroke-linecap="round"
													stroke-width="2"
												></line>
											</g>
										</svg>
									</p>
								</Accordion.Header>
								<Accordion.Body className="total-body">
									<div className="flex-div">
										<p>{t("Sub Total")} </p>
										<p className="red">
											{currency}{" "}
											{
												data?.orderSuggestionPrice
													?.subtotal
											}
											<span className="min_amou">
												{" "}
												{currency}{" "}
												{data?.orderPrice?.subtotal}
											</span>
										</p>
									</div>
									<div className="flex-div">
										<p>{t("discount")} </p>
										<p>{currency} 0</p>
									</div>
									<div className="flex-div">
										<h6>{t("Order Total")}</h6>
										<h6 className="red">
											{currency === "SAR" ? (
												<Sar color="currentcolor" />
											) : (
												`${currency || "AED"} `
											)}{" "}
											{data?.orderSuggestionPrice?.total}
											<span className="min_amou">
												{" "}
												{currency === "SAR" ? (
													<Sar color="currentcolor" />
												) : (
													`${currency || "AED"} `
												)}{" "}
												{data?.orderPrice?.total}
											</span>
										</h6>
									</div>
								</Accordion.Body>
							</Accordion.Item>
						</Accordion>
					</div>
					{data?.paymentMethodText && (
						<>
							<div className="full-heading grey-bg flex">
								<h2 className="blck co-header">
									{t("Payment Method")}{" "}
								</h2>
								<h2 className="grey">
									<img src={Payment1} alt="cash" />
									{t(`${data?.paymentMethodText}`)}
								</h2>
							</div>
						</>
					)}
					{showSuggestionButtons && (
						<div className="suber_bt">
							<button
								disabled={loading}
								className="Accept"
								onClick={SendAcc}
							>
								Accept
							</button>
							<button
								disabled={loading}
								className="Decline"
								onClick={SendDec}
							>
								Decline
							</button>
						</div>
					)}

					{/* <div className="full-div padd-div text-center">
          <Link className="reg-btn large" to={`/${room_uuid}`}>
            {t("Back to Home")}
          </Link>
        </div> */}
				</main>
			)}
		</>
	);
}
export default SupermarketOrderPlaced;
