import HomeLoader from "./HomeLoader";

const CustomLoader = ({ businessName }) => {
	console.log("businessName", businessName);
	const normalizedBusinessName = businessName
		?.trim()
		.toLowerCase()
		.replaceAll(",", "")
		.replaceAll(".", "");
	if (!businessName) return null; // Prevent initial unwanted render
	return normalizedBusinessName === "the lana residences dubai" ? (
		<div className="home_loader">
			<div
				className="loader"
				style={{
					borderColor: "lightgray",
					borderRightColor: "#000000",
				}}
			></div>
		</div>
	) : (
		<HomeLoader />
	);
};

export default CustomLoader;
