import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { Zoom } from "react-reveal";
import HeaderInner from "../../Components/HeaderInner";
import axios from "../../axios";
import PharmacyLoader from "../../Components/Loaders/PharmacyLoader";
import { ShowPharmacies, ShowPharmacy } from "../../API/Pharmacy";
import PharmacyComponent from "../../Components/PharmacyComponent";
import Search from "../../Components/Search";
import Title from "../../new-components/title/Title";
import NewFooter from "../../NewFooter";
import PharmacyComponentSub from "../../Components/PharmacyComponentSub";
import { ThemeContext } from "../../context/ThemeContext";
import { useBusinessNameContext } from "../../context/BusinessName";
import CustomBusinessServiceLoader from "../../Components/Loaders/CustomBusinessServiceLoader";

function PharmacyCategoriesSub() {
	const { businessName, setBusinessName } = useBusinessNameContext();

	const [enableClass, setEnableClass] = useState(false);
	const [loading, setLoading] = useState(false);
	const [pharmacies, setPharmacies] = useState();
	const [title, setTitle] = useState();
	// const [parmacy, setParmacy] = useState({ id: "", uuid: "" });
	const location = useLocation();
	const params = useParams();
	const rawPath = location.pathname;
	const room_uuid = rawPath.split("/")[1];
	// const theme = location.state?.theme;
	// const new_design = localStorage.getItem("new_design");
	// const theme = new_design == "true";
	const { theme } = useContext(ThemeContext);
	setTimeout(() => {
		setEnableClass(false);
	}, 3000);

	// useEffect(() => {
	//   const getData = async () => {
	//     setLoading(true)
	//     axios.get(`/${room_uuid}/pharmacies`).then((res) => {
	//       const data = res.data.data;
	//       setParmacy({
	//         id: data.pharmacies[0].id,
	//         uuid: data.pharmacies[0].uuid,
	//       });
	//       axios
	//         .get(`/${room_uuid}/pharmacy/${data.pharmacies[0].uuid}`)
	//         .then((res) => {
	//           const data = res.data.data;
	//           setPharmacies(data.categories);
	//           setTitle(data?.title)
	//           console.log(data);
	//         });
	//       // setPharmacies(data.categories);
	//       console.log(data);
	//       setLoading(false)
	//     });
	//   };
	//   getData();
	// }, []);
	// console.log("tit",);

	useEffect(() => {
		window.scrollTo({
			top: 0,
			left: 0,
		});
	}, []);

	// <HeaderInner headerName="Pharmacy" />
	return (
		<div className={theme ? "food pb-0" : ""}>
			{theme ? (
				<Title title={"Pharmacy"} />
			) : (
				<HeaderInner headerName={"Pharmacy"} />
			)}
			<main className="home-main bg-white back-white pad-top-0 pt-6">
				<Search
					placeholder="Search for Categories..."
					room_uuid={room_uuid}
					category="Pharmacy"
				/>

				{loading ? (
					<CustomBusinessServiceLoader
						businessName={businessName}
						loader={"pharmacy"}
					/>
				) : (
					<>
						{
							<PharmacyComponentSub
								room_uuid={room_uuid}
								ph_uuid={params.pharmacie_uuid}
								ph_id={params.pharmacie_id}
								theme={theme}
							/>
						}
					</>
				)}
			</main>
			{theme && <NewFooter />}
		</div>
	);
}
export default PharmacyCategoriesSub;
