import React, { useEffect } from "react";
import Title from "../../new-components/title/Title";
import { Link, useNavigate, useParams } from "react-router-dom";
import Overllay from "../../assets/new_imgs/Rectangle 1072.png";
import NewFooter from "../../NewFooter";
import "./FoodAndBeverages.scss";
import NewLoader from "../../new-components/title/Loader/NewLoader";
import { ShowRoomDining } from "../../API/In-Room-DiningAPI";

const InRoomDining = () => {
	const params = useParams();
	const lang = localStorage.getItem("lang");
	const navigate = useNavigate();
	let restaurants;
	let menu;
	let text;
	const { data, isLoading } = ShowRoomDining(params.room_uuid, lang);
	if (!isLoading) {
		if (data.data.status === false) {
			navigate(`/${params.room_uuid}/qr-scan`);
		} else {
			menu = data.data.data;
			restaurants = menu?.restaurants;
			text = menu?.center_header;

			if (restaurants?.length === 1) {
				navigate(
					`/${params.room_uuid}/food-and-beverages/in-room-dining/${restaurants[0].uuid}`,
					{ replace: true }
				);
			}
		}
	}
	if (!isLoading) {
	}
	useEffect(() => {
		window.scrollTo({
			top: 0,
			left: 0,
		});
	}, []);

	return (
		<div className={`food pb-5 ${lang === "AR" && "arabic"}`}>
			{isLoading ? (
				<NewLoader />
			) : (
				<>
					<Title title={text} />

					<div className="box_food">
						{restaurants?.length > 1 &&
							restaurants?.map((item, idx) => {
								return (
									<div
										className={`${
											item.is_closed
												? "closed restaurant-option"
												: ""
										}`}
									>
										<Link
											key={idx}
											to={`/${params.room_uuid}/food-and-beverages/in-room-dining/${item.uuid}`}
										>
											<div className="closed-tag"></div>
											{item?.is_closed && (
												<span className="open_at">
													{item.opens_at
														? `Opens at ${item.opens_at}`
														: `Closed`}
												</span>
											)}
											<img
												src={item?.image}
												alt=""
												className="booking_img"
											/>
											<img
												src={Overllay}
												alt=""
												className="overlay"
											/>
											<div className="text_food">
												<h5 className="">
													{lang === "EN"
														? item.display_name
														: item.name}
												</h5>
												<p>
													{item?.cuisines?.map(
														(cuisine, idx) => {
															if (
																idx + 1 <
																item?.cuisines
																	.length
															) {
																return `${cuisine.name}  |  `;
															} else {
																return `${cuisine.name}  `;
															}
														}
													)}
												</p>
											</div>{" "}
										</Link>
									</div>
								);
							})}
					</div>
					<NewFooter />
				</>
			)}
		</div>
	);
};

export default InRoomDining;
