import { createContext, useState, useContext } from "react";

const BusinessNameContext = createContext();

export const BusinessNameProvider = ({ children }) => {
	const [businessName, setBusinessName] = useState("");

	return (
		<BusinessNameContext.Provider value={{ businessName, setBusinessName }}>
			{children}
		</BusinessNameContext.Provider>
	);
};

export const useBusinessNameContext = () => useContext(BusinessNameContext);
