import React from "react";
import Sar from "../assets/images/icons/Sar";

function Currency({ currency }) {
	return currency === "SAR" ? (
		<Sar color="currentcolor" style={{ marginRight: "2px" }} />
	) : (
		`${currency}  `
	);
}

export default Currency;
