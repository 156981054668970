import React, { useState, useRef } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import HeaderInner from "../../Components/HeaderInner";
import EntertainmentCarosle from "../../Components/EntertainmentCarosle";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import PhoneInput from "react-phone-input-2";
import axios from "../../axios";
import { object, string } from "yup";
import { useEffect } from "react";
import SpaLoader from "../../Components/Loaders/SpaLoader";
import { useTranslation } from "react-i18next";
import ReactGA from "react-ga4";
import Title from "../../new-components/title/Title";
import NewFooter from "../../NewFooter";
import { phoneFormat } from "../../constants/api";
import PhoneInputComponent from "../../Components/PhoneInputComponent";
import { useBusinessNameContext } from "../../context/BusinessName";
import CustomBusinessServiceLoader from "../../Components/Loaders/CustomBusinessServiceLoader";

function EntertainmentDetail() {
	const loaderRef = useRef(null);
	const { businessName, setBusinessName } = useBusinessNameContext();
	const [show, setShow] = useState(false);
	const [loading, setLoading] = useState(false);
	const new_design = localStorage.getItem("new_design");
	const theme = new_design === "true";
	const handleClose = (En_name) => {
		setShow(false);
		ReactGA.event({
			category: "Close popup Entertainment detail ",
			action: "Click",
			value: `Entertainment detail: ${En_name}`,
		});
	};
	const handleShow = (En_name) => {
		// console.log("name", En_name);
		setShow(true);
		ReactGA.event({
			category: "Open popup Entertainment detail ",
			action: "Click",
			value: `Entertainment detail: ${En_name}`,
		});
	};
	const [headerImage, setHeaderImage] = useState();
	const [header_title, setheader_title] = useState("");
	const [isDisabled, setIsDisabled] = useState(false);
	const [description, setDescription] = useState();
	const [description_2, setDescription_2] = useState();
	const location = useLocation();
	const rawPath = location.pathname;
	const room_uuid = rawPath.split("/")[1];

	const { t } = useTranslation();

	const [entertainmentPrice, setEntertainmentPrice] = useState();
	const [entertainmentTime, setEntertainmentTime] = useState();
	const [entertainmentHeader, setEntertainmentHeader] = useState();
	const [entertainmentActivites, setEntertainmentActivites] = useState();
	const [description_21, setDescription_21] = useState();
	const [entertainmentActivites_1, setEntertainmentActivites_1] = useState();
	const [activities_format, setactivities_format] = useState("");
	const [description_2_format, setdescription_2_format] = useState("");

	const [entertainment, setEntertainment] = useState({
		name: "",
		phone: "",
		email: "",
		number_adults: "",
		number_childs: "",
		note: "",
		category_id: "",
		redirect_url: `https://${window.location.host}/${room_uuid}/EntertainmentCallback`,
	});

	const params = useParams();
	const navigate = useNavigate();

	let userSchema = object({
		name: string(),
		phone: string(),
		email: string(),
		number_adults: string(),
		number_childs: string(),
		note: string(),
		category_id: string(),
	});

	const handleInputChange = (e) => {
		const { name, value } = e.target;

		setEntertainment((prev) => ({
			...prev,
			[name]: value,
		}));
	};
	const [name, setName] = useState("");
	const [img, setImg] = useState("");
	const [errName, setErrName] = useState("");
	const [errPhone, setErrPhone] = useState("");
	const [errEmail, setErrEmail] = useState("");
	const [errnumberAdults, setErrnumberAdults] = useState("");
	const [paymentUrl, setPaymentUrl] = useState("");
	const checkValEmail =
		entertainment.email.length >= 0 &&
		!/\S+@\S+\.\S+/.test(entertainment.email);

	const checkInputs = () => {
		if (entertainment.name == "") {
			setErrName("");
		} else {
			setErrName("");
		}
		if (checkValEmail) {
			setErrEmail("The email must be a valid email address.");
		} else {
			setErrEmail("");
		}
		if (entertainment.phone == "") {
			setErrPhone("The phone field is required.");
		} else {
			setErrPhone("");
		}
		if (entertainment.number_adults == "") {
			setErrnumberAdults("The number adults field is required.");
		} else {
			setErrnumberAdults("");
		}
	};
	const handleBook = async () => {
		checkInputs();
		if (
			entertainment.name != "" &&
			!checkValEmail &&
			entertainment.phone &&
			entertainment.number_adults
		) {
			const anser = await userSchema.validate(entertainment);
			setIsDisabled(true);
			axios
				.post(`/${room_uuid}/entertainment/book-category`, anser, {
					headers: {
						lang: lang,
					},
				})
				.then((res) => {
					ReactGA.event({
						category: "Book service entertainment",
						action: "Click",
						value: `Entertainment: ${name}`,
					});
					if (res.data.status) {
						if (res?.data?.data?.payment_url) {
							setPaymentUrl(res?.data?.data?.payment_url);
						} else {
							navigate(`/${room_uuid}/EntertainmentOrderPlaced`, {
								state: {
									metadata: {
										res: res.data.data,
									},
								},
							});
						}
					}
					setIsDisabled(false);
				})
				.catch((err) => {
					setIsDisabled(false);
					console.log(err);
				});
			// navigate("/HCOrderPlaced");
		}
	};
	const lang = localStorage.getItem("lang");

	useEffect(() => {
		const getData = async () => {
			setLoading(true);
			axios
				.get(
					`/${room_uuid}/entertainment/category_details/${params.id}`,
					{
						headers: {
							lang: lang,
						},
					}
				)
				.then((res) => {
					if (res.data.status === false) {
						navigate(`/${room_uuid}/qr-scan`);
					} else {
						const data = res.data.data;
						// console.log(res.data);
						// setEntertainmentCategory(data.category);
						setHeaderImage(data.banners);
						setheader_title(data.header_title);
						setdescription_2_format(
							data.category.description_2_format
						);
						setactivities_format(data.category.activities_format);
						setHeaderImage(data.banners);
						setImg(data.banners[0]);
						if (lang == "AR") {
							setDescription(data.category.names[1].description);
							setDescription_21(
								data.category.names[1].description_2
							);
							setDescription_2(
								data.category.names[1].description_2
									?.split(/\r\n/)
									.filter((line) => line.trim() !== "")
							);
							setName(data.category.names[1].name);
							setEntertainmentPrice(data.category.names[1].price);
							setEntertainmentTime(data.category.names[1].time);
							setEntertainmentActivites(
								data.category.names[1].activities
									?.split(/\r\n/)
									.filter((line) => line.trim() !== "")
							);
							setEntertainmentActivites_1(
								data.category.names[1].activities
							);
							setEntertainmentHeader(
								data.category.names[1].header
							);
							// console.log("arr");
						}
						if (lang == "FR") {
							setDescription(data.category.names[2].description);
							setDescription_2(
								data.category.names[2].description_2
									?.split(/\r\n/)
									.filter((line) => line.trim() !== "")
							);
							setDescription_21(
								data.category.names[2].description_2
							);
							setEntertainmentActivites_1(
								data.category.names[2].activities
							);

							setName(data.category.names[2].name);
							setEntertainmentPrice(data.category.names[2].price);
							setEntertainmentTime(data.category.names[2].time);
							setEntertainmentActivites(
								data.category.names[2].activities
									?.split(/\r\n/)
									.filter((line) => line.trim() !== "")
							);
							setEntertainmentHeader(
								data.category.names[2].header
							);
							// console.log("frr");
						}
						if (lang == "RU") {
							setDescription(data.category.names[3].description);
							setDescription_2(
								data.category.names[3].description_2
									?.split(/\r\n/)
									.filter((line) => line.trim() !== "")
							);
							setDescription_21(
								data.category.names[3].description_2
							);
							setEntertainmentActivites_1(
								data.category.names[3].activities
							);

							setName(data.category.names[3].name);
							setEntertainmentPrice(data.category.names[3].price);
							setEntertainmentTime(data.category.names[3].time);
							setEntertainmentActivites(
								data.category.names[3].activities
									?.split(/\r\n/)
									.filter((line) => line.trim() !== "")
							);
							setEntertainmentHeader(
								data.category.names[3].header
							);
						}
						if (lang == "HI") {
							setDescription(data.category.names[4].description);
							setDescription_2(
								data.category.names[4].description_2
									?.split(/\r\n/)
									.filter((line) => line.trim() !== "")
							);
							setDescription_21(
								data.category.names[4].description_2
							);
							setEntertainmentActivites_1(
								data.category.names[4].activities
							);

							setName(data.category.names[4].name);
							setEntertainmentPrice(data.category.names[4].price);
							setEntertainmentTime(data.category.names[4].time);
							setEntertainmentActivites(
								data.category.names[4].activities
									?.split(/\r\n/)
									.filter((line) => line.trim() !== "")
							);
							setEntertainmentHeader(
								data.category.names[4].header
							);
						}
						if (lang == "CH") {
							setDescription(data.category.names[5].description);
							setDescription_2(
								data.category.names[5].description_2
									?.split(/\r\n/)
									.filter((line) => line.trim() !== "")
							);
							setDescription_21(
								data.category.names[5].description_2
							);
							setEntertainmentActivites_1(
								data.category.names[5].activities
							);

							setName(data.category.names[5].name);
							setEntertainmentPrice(data.category.names[5].price);
							setEntertainmentTime(data.category.names[5].time);
							setEntertainmentActivites(
								data.category.names[5].activities
									?.split(/\r\n/)
									.filter((line) => line.trim() !== "")
							);
							setEntertainmentHeader(
								data.category.names[5].header
							);
						}
						if (lang == "DE") {
							setDescription(data.category.names[6].description);
							setDescription_2(
								data.category.names[6].description_2
									?.split(/\r\n/)
									.filter((line) => line.trim() !== "")
							);
							setDescription_21(
								data.category.names[6].description_2
							);
							setEntertainmentActivites_1(
								data.category.names[6].activities
							);

							setName(data.category.names[6].name);
							setEntertainmentPrice(data.category.names[6].price);
							setEntertainmentTime(data.category.names[6].time);
							setEntertainmentActivites(
								data.category.names[6].activities
									?.split(/\r\n/)
									.filter((line) => line.trim() !== "")
							);
							setEntertainmentHeader(
								data.category.names[6].header
							);
						}
						if (lang == "KO") {
							setDescription(data.category.names[7].description);
							setDescription_2(
								data.category.names[7].description_2
									?.split(/\r\n/)
									.filter((line) => line.trim() !== "")
							);
							setDescription_21(
								data.category.names[7].description_2
							);
							setEntertainmentActivites_1(
								data.category.names[7].activities
							);

							setName(data.category.names[7].name);
							setEntertainmentPrice(data.category.names[7].price);
							setEntertainmentTime(data.category.names[7].time);
							setEntertainmentActivites(
								data.category.names[7].activities
									?.split(/\r\n/)
									.filter((line) => line.trim() !== "")
							);
							setEntertainmentHeader(
								data.category.names[7].header
							);
						}
						if (lang == "EN") {
							setDescription(data.category.names[0].description);
							setDescription_2(
								data.category.names[0].description_2
									?.split(/\r\n/)
									.filter((line) => line.trim() !== "")
							);
							setDescription_21(
								data.category.names[0].description_2
							);
							setEntertainmentActivites_1(
								data.category.names[0].activities
							);

							setName(data.category.names[0].name);
							setEntertainmentPrice(data.category.names[0].price);
							setEntertainmentTime(data.category.names[0].time);
							setEntertainmentActivites(
								data.category.names[0].activities
									?.split(/\r\n/)
									.filter((line) => line.trim() !== "")
							);
							setEntertainmentHeader(
								data.category.names[0].header
							);
						}
						setEntertainment((prev) => ({
							...prev,
							category_id: data.category.uuid,
						}));
					}
					setLoading(false);
				})
				.catch((err) => {
					if (err?.response.status == 404) {
						// navigate(`/not-found`);
					}
				});
		};
		getData();
	}, []);
	useEffect(() => {
		window.scrollTo({
			top: 0,
			left: 0,
		});
	}, []);
	const handleLoad = () => {
		// Hide the loading view when the iframe is loaded
		if (loaderRef.current) {
			loaderRef.current.style.display = "none"; // Hide the loader by setting display to 'none'
			loaderRef.current.style.visibility = "hidden"; // Hide the loader by setting visibility to 'hidden'

			loaderRef.current.remove();
		}
	};
	return (
		<div className={theme ? "food pb-0" : ""}>
			{theme ? (
				<Title title={header_title} />
			) : (
				<HeaderInner headerName={header_title} />
			)}
			<main className="home-main  pad-top-0 back-fe">
				{loading ? (
					<CustomBusinessServiceLoader
						businessName={businessName}
						loader={"spa"}
					/>
				) : (
					<>
						<EntertainmentCarosle arr={headerImage} />

						<div className="padd-div f-16">
							<h3 style={{ fontSize: "16px", marginTop: "10px" }}>
								{entertainmentHeader}
							</h3>
							{description && (
								<>
									<div
										dangerouslySetInnerHTML={{
											__html: description?.replaceAll(
												"\n",
												"</br>"
											),
										}}
									></div>
									<br />
								</>
							)}
							{entertainmentPrice && (
								<>
									<strong>{t("Price")} :</strong>
									<div className="d-flex ">
										{/* <span className="mx-1">{`${currency} `}</span> */}
										<div
											dangerouslySetInnerHTML={{
												__html: entertainmentPrice?.replaceAll(
													"\n",
													"</br>"
												),
											}}
										></div>
									</div>
									<br />
								</>
							)}

							{description_2 ? (
								description_2_format == "list" ? (
									<div
										className={
											lang == "AR" ? "mr-3" : "ml-3"
										}
									>
										<ul className="ent_list">
											{description_2?.map((item, i) => {
												return (
													<li
														key={i}
														className={
															i == 0
																? "list_head"
																: ""
														}
													>
														{item}
													</li>
												);
											})}
										</ul>

										<br />
									</div>
								) : (
									<div className="mt-2 mb-2">
										{/* <strong className=" mb-1"></strong> */}
										<p className="des2_title">
											{
												description_21?.split(
													"\r\n\r\n"
												)[0]
											}
										</p>
										<div
											dangerouslySetInnerHTML={{
												__html: description_21
													?.split("\r\n\r\n")[1]
													?.replaceAll("\n", "</br>"),
											}}
										></div>
										<br />
									</div>
								)
							) : null}

							{entertainmentActivites ? (
								activities_format == "list" ? (
									<div
										className={
											lang == "AR" ? "mr-3" : "ml-3"
										}
									>
										<ul className="ent_list">
											{entertainmentActivites?.map(
												(item, i) => {
													return (
														<li
															key={i}
															className={
																i == 0
																	? "list_head"
																	: ""
															}
														>
															{item}
														</li>
													);
												}
											)}
										</ul>
										<br />
									</div>
								) : (
									<div className=" mt-2 mb-2">
										{/* <span className="mx-1">{`${currency} `}</span> */}
										<p className="des2_title">
											{
												entertainmentActivites_1?.split(
													"\r\n\r\n"
												)[0]
											}
										</p>
										<div
											dangerouslySetInnerHTML={{
												__html: entertainmentActivites_1
													?.split("\r\n\r\n")[1]
													?.replaceAll("\n", "</br>"),
											}}
										></div>
										<br />
									</div>
								)
							) : null}

							{entertainmentTime && (
								<>
									<strong>{t("Timing")}:</strong>
									<br />
									<div className="d-flex ">
										<div
											dangerouslySetInnerHTML={{
												__html: entertainmentTime?.replaceAll(
													"\n",
													"</br>"
												),
											}}
										></div>
									</div>
									<br />
								</>
							)}
						</div>

						<div className="padd-div text-center">
							<Button
								className="reg-btn large more-pad font-dark"
								onClick={() => handleShow(name)}
							>
								{t("Book")}
							</Button>
						</div>
					</>
				)}
				<Modal
					show={show}
					onHide={handleClose}
					className={`custom-width  ${theme ? "food-modal" : ""}`}
				>
					<Modal.Body>
						<button
							type="button"
							className="button close-pop"
							onClick={() => handleClose(name)}
						>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="24.054"
								height="24.625"
								viewBox="0 0 24.054 24.625"
							>
								<defs></defs>
								<path
									id="Icon_ionic-ios-close-circle"
									data-name="Icon ionic-ios-close-circle"
									className="cls-50"
									d="M15.4,3.375A12.17,12.17,0,0,0,3.375,15.687,12.17,12.17,0,0,0,15.4,28,12.17,12.17,0,0,0,27.429,15.687,12.17,12.17,0,0,0,15.4,3.375Zm3.047,16.77L15.4,17.025l-3.047,3.12a.913.913,0,0,1-1.307,0,.959.959,0,0,1,0-1.338l3.047-3.12-3.047-3.12a.959.959,0,0,1,0-1.338.913.913,0,0,1,1.307,0L15.4,14.35l3.047-3.12a.913.913,0,0,1,1.307,0,.966.966,0,0,1,0,1.338l-3.047,3.12,3.047,3.12a.966.966,0,0,1,0,1.338A.9.9,0,0,1,18.449,20.145Z"
									transform="translate(-3.375 -3.375)"
								></path>
							</svg>
						</button>
						<div className="body-container">
							<div className="img-pnl">
								<img className="" src={img} />
							</div>
							<div className="content-pnl">
								<div className="top">
									<p className="title text-start">{name}</p>
									{/* <p className="">Timing</p> */}
								</div>
								<div className="input-pnl">
									<Row className="justify-content-center">
										<Col xs="12">
											<Form.Group
												className="mb-3 dark-placeholder i-f-small"
												controlId="formBasicname"
											>
												<Form.Control
													type="number"
													placeholder={t(
														`No. Adults`
													)}
													name="number_adults"
													value={
														entertainment.number_adults
													}
													onChange={handleInputChange}
												/>
											</Form.Group>
											{errnumberAdults != "" && (
												<span className="err_msg">
													{t(`${errnumberAdults}`)}
												</span>
											)}
										</Col>
										<Col xs="12">
											<Form.Group
												className="mb-3 dark-placeholder i-f-small"
												controlId="formBasicname"
											>
												<Form.Control
													type="number"
													placeholder={t(
														`No. Children (Optional)`
													)}
													name="number_childs"
													value={
														entertainment.number_childs
													}
													onChange={handleInputChange}
												/>
											</Form.Group>
										</Col>
										<Col xs="12">
											<Form.Group
												className="mb-3 dark-placeholder i-f-small"
												controlId="formBasicname"
											>
												<Form.Control
													type="text"
													placeholder={t(`Your Name`)}
													name="name"
													value={entertainment.name}
													onChange={handleInputChange}
												/>
											</Form.Group>
											{errName != "" && (
												<span className="err_msg">
													{t(`${errName}`)}
												</span>
											)}
										</Col>
									</Row>
									<Form.Group className="mb-3 dark-placeholder i-f-small w-100">
										<PhoneInputComponent
											value={entertainment.phone}
											onChange={(phone) => {
												setEntertainment((prev) => ({
													...prev,
													phone,
												}));
												// setPhone(phone);
											}}
										/>
									</Form.Group>
									{errPhone != "" && (
										<span
											className="err_msg"
											style={{ marginLeft: "-100px" }}
										>
											{t(`${errPhone}`)}
										</span>
									)}
									<Row>
										<Col xs="12">
											<Form.Group
												className="mb-3 dark-placeholder i-f-small"
												controlId="formBasicname"
											>
												<Form.Control
													type="email"
													placeholder={t(
														`Your Email`
													)}
													name="email"
													value={entertainment.email}
													onChange={handleInputChange}
												/>
											</Form.Group>
											{errEmail != "" && (
												<span className="err_msg">
													{t(`${errEmail}`)}
												</span>
											)}
										</Col>
										<Col xs="12">
											<Form.Group className="mb-3 dark-placeholder i-f-small w-100">
												<Form.Control
													as="textarea"
													placeholder={t(
														`Your Notes (Optional)`
													)}
													name="note"
													value={entertainment.note}
													onChange={handleInputChange}
													style={{ height: "106px" }}
												/>
											</Form.Group>
										</Col>
									</Row>
								</div>
								<div className="spacer-40"></div>
								{!paymentUrl && (
									<div className="padd-div text-center mb-2 ">
										<Button
											className="reg-btn book fw-normal"
											onClick={handleBook}
											disabled={isDisabled}
										>
											{t(`Book`)}
										</Button>
									</div>
								)}
								{paymentUrl && (
									<div className="payment-iframe">
										<div
											className="d-flex justify-content-center m-5 "
											ref={loaderRef}
										>
											{/* <div className="home_loader"> */}
											<div
												className="loader"
												style={{
													borderColor: "lightgray",
													borderRightColor: "#000000",
												}}
											></div>
											{/* </div>sss */}
										</div>
										<iframe
											loading="lazy"
											src={paymentUrl}
											width="100%"
											title="Payment Preview"
											scrolling="no"
											onLoad={handleLoad}
										></iframe>
									</div>
								)}
							</div>
						</div>
					</Modal.Body>
				</Modal>
			</main>
			{theme && <NewFooter />}
		</div>
	);
}
export default EntertainmentDetail;
