import React, { useContext, useEffect, useState } from "react";
import { ShowCheckUUID } from "./API/HomeMenuAPI";
import { useNavigate, useParams } from "react-router-dom";
import { ThemeContext } from "./context/ThemeContext";
import { useBusinessNameContext } from "./context/BusinessName";
import CustomLoader from "./Components/Loaders/CustomLoader";

const CheckIsFound = () => {
	const { businessName, setBusinessName } = useBusinessNameContext();
	const params = useParams();
	const navigate = useNavigate();
	const { setTheme } = useContext(ThemeContext);

	const { data, isLoading, error } = ShowCheckUUID(params.room_uuid);
	if (!isLoading) {
		if (data.data.status == true) {
			if (data?.data?.data?.is_active) {
				if (data.data.data.is_restaurant_table) {
					localStorage.setItem("in_dining_logo", data.data.data.logo);
					localStorage.setItem(
						"currency",
						data.data.data.currency || "AED"
					);
					localStorage.setItem(
						"new_design",
						data.data.data.use_new_design
					);
					setTheme(data.data.data.use_new_design);
					localStorage.setItem("type", "in_dining");
					localStorage.setItem(
						"languages",
						JSON.stringify(data.data.data.lang)
					);
					localStorage.setItem(
						"navigatr",
						`/${data.data.data.room}/rstauran-menu-categories/${data.data.data.restaurant_uuid}`
					);
					navigate(
						`/${data.data.data.room}/rstauran-menu-categories/${data.data.data.restaurant_uuid}`,
						{
							state: {
								metadata: {
									languages: data.data.data.lang,
								},
							},
						}
					);
				} else {
					navigate(`/${data.data.data.room}/qr-code`);
					localStorage.setItem("in_dining_logo", data.data.data.logo);
					localStorage.setItem(
						"new_design",
						data.data.data.use_new_design
					);
					setTheme(data.data.data.use_new_design);

					localStorage.setItem("open_lang", "true");
					localStorage.setItem("type", "qr-code");
					localStorage.setItem("navigatr", "");
					localStorage.setItem("languages", null);

					localStorage.setItem(
						"currency",
						data.data.data.currency || "AED"
					);
					localStorage.setItem("color", data?.color);
				}
			} else {
				navigate(`/room_not_active`);
			}
		} else {
			navigate(`/not-found`);
		}
	}

	return isLoading && <CustomLoader businessName={businessName} />;
};

export default CheckIsFound;
