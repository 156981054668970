import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Button, Form, Modal } from "react-bootstrap";
import Post from "../assets/images/food.png";
import { AiOutlineCloseCircle } from "react-icons/ai";
import Image from "react-bootstrap/Image";
import { useCartStore } from "../store/useStore";
import { addToCart } from "../utils/cartUtils";
import axios from "../axios";
import { useQueryClient } from "react-query";
import { useTranslation } from "react-i18next";
import { Zoom } from "react-reveal";
import { object } from "yup";
import ReactGA from "react-ga4";
import { ThemeContext } from "../context/ThemeContext";
import ExtraOptions from "./ExtraOptions/ExtraOptions";

import {
	deepCloneAndMutate,
	updateCheckedState,
	calculateTotalPrice,
	collectSelectedExtras,
	updateNestedOptionsCount,
	checkExtras,
} from "./../utils/ExtraWithOptions/ExtraWithOption";
import { set } from "lodash";
import Sar from "../assets/images/icons/Sar";

function AddCartPost({
	item,
	restaurantId,
	isOffCategory,
	restaurant_is_closed,
	isbooking,
	isAdditional,
	currency,
	settotalPrice,
	selected,
	restaurantInfo,
}) {
	// const new_design = localStorage.getItem("new_design");
	// const theme = new_design == "true";
	const [itemState, setItemState] = useState(() => deepCloneAndMutate(item));
	const { theme } = useContext(ThemeContext);
	// console.log("isOffCategory", restaurant_is_closed);
	// console.log("restaurant_is_closed", !isOffCategory || !restaurant_is_closed);
	let res = false;
	if (isOffCategory) {
		res = true;
	} else if (restaurant_is_closed) {
		res = true;
	} else if (isbooking) {
		res = true;
	} else {
		res = false;
	}
	// console.log("res", res);
	// const [enableClass, setEnableClass] = useState(true);
	const [show, setShow] = useState(false);
	const [count, setCount] = useState(1);
	const [showCart, setShowCart] = useState(false);
	const [loading, setLoading] = useState(false);
	const [extras, setExtras] = useState([]);
	const [priceOption, setPriceOption] = useState(0);

	const location = useLocation();
	const rawPath = location.pathname;
	const room_uuid = rawPath.split("/")[1];
	const queryClient = useQueryClient();

	const { cart, setCart, cartCount, setCartCount } = useCartStore(
		(state) => ({
			cart: state.cart,
			setCart: state.setCart,
			cartCount: state.cartCount,
			setCartCount: state.setCartCount,
		})
	);

	const { t, i18n } = useTranslation();
	const handleClose = () => {
		setShow(false);
	};
	const handleShow = () => {
		setShow(true);
		ReactGA.event({
			category: "Choose an item from the restaurant ",
			action: "Click",
			value: `item name: ${item?.name}}`,
		});
	};

	useEffect(() => {
		const checkboxes = document.getElementsByClassName("checkboxes");
		Object.values(checkboxes).map((check) => {
			if (check.children[0].required == true) {
				check.children[0].setCustomValidity(
					"Please select 1 option at least"
				);
			}
		});
	}, []);

	useEffect(() => {}, [show]);

	const handleCartClose = () => {
		setShowCart(false);
		setCount(1);
		setExtras([]);
	};
	const handleCartShow = () => setShowCart(true);
	const handleIncrease = () => {
		setCount((prev) => prev + 1);
	};
	const handleDecrease = () => {
		setCount((prev) => {
			if (prev <= 1) {
				return 1;
			}
			return prev - 1;
		});
	};

	const lang = localStorage.getItem("lang");

	const formData = new FormData();

	formData.append("item_id", item.id);
	formData.append("quantity", count);
	formData.append("restaurant_id", parseInt(restaurantId));

	const [optionsIds, setOptionsIds] = useState({});

	const handleInvalid = (event, index) => {
		event.target.setCustomValidity("Please select 1 option at least");
	};
	const handleHandleOptionsChanges = (
		event,
		optionName,
		optionId,
		type,
		price,
		idx,
		max_op,
		is_required
	) => {
		event.target.setCustomValidity("");
		// console.log("max_op", max_op);

		if (type == "radio") {
			// if (state == "remove") {
			//   const { [optionName]: _, ...rest } = optionsIds;

			//   setOptionsIds(rest);
			// } else {
			setOptionsIds((prev) => ({
				...prev,
				[optionName]: optionId,
			}));
			// }
		} else {
			const idex = document.getElementById(`idx${idx}`);
			var inputNodes = idex.getElementsByTagName("INPUT");
			var inputLang = idex.querySelectorAll(
				'input[type="checkbox"]:checked'
			).length;
			Object.values(inputNodes).map((inp) => {
				if (inputLang >= 1) {
					inp.required = false;
					inp.setCustomValidity("");
				} else if (inputLang == 0 && is_required) {
					inp.required = true;
					inp.setCustomValidity("Please select 1 option at least");
				}
				if (inputLang == max_op) {
					// console.log("inputLang", inputLang);
					if (inp.checked == false) {
						inp.disabled = true;
					}
				} else {
					if (inp.checked == false) {
						inp.disabled = false;
					}
				}
			});

			// Check if the object is present in the array
			const index = extras.findIndex(
				(obj) => JSON.stringify(obj) === JSON.stringify(optionId)
			);

			if (index !== -1) {
				// If found, remove the object from the array
				extras.splice(index, 1);
				setPriceOption(priceOption - Number(price * count));
			} else {
				// If not found, add the object to the array
				extras.push(optionId);
				setPriceOption(priceOption + Number(price * count));
			}
			// if (extras.includes(optionId)) {

			//   const tempExtras = JSON.parse(JSON.stringify(extras));
			//   console.log("tempExtras", tempExtras);
			//   const filtered = tempExtras.filter((temp) => {
			//     return temp !== optionId;
			//   });
			//   setExtras(filtered);
			//   console.log("filtered", filtered);
			// } else {
			//   setPriceOption(priceOption + Number(price));

			//   setExtras((prev) => [optionId, ...prev]);
			// }
		}
	};

	// console.log("price", priceOption);
	// console.log("optionsIds", Object.values(optionsIds));
	// useEffect(() => console.log("optionsIds", optionsIds, extras), [optionsIds]);

	// const objectOpt = [Object.values(optionsIds)]
	// console.log();
	let text_extras = [];

	const addToCartV2 = (e) => {
		e.preventDefault();

		if (!checkExtras(itemState.extrasWithOptions)) return;
		console.log(
			"itemState.extrasWithOptions",
			checkExtras(itemState.extrasWithOptions)
		);
		const optionsArray = [...Object.values(optionsIds), ...extras];
		const formatArray = optionsArray.map((option) => {
			return {
				extra_id: option.extra_id,
				option_id: option.id,
				price: option.price,
			};
		});
		// console.log("...Object.values(optionsIds), ...extras]", formatArray);
		// e.preventDefault();

		const extraText = itemState.extrasWithOptions
			.filter((extra) => extra.text_value) // Only keep extras with a text_value
			.map((extra) => {
				return {
					extra_id: extra.extra_id,
					text: extra.text_value, // Extracting text_value field
				};
			});
		console.log("extraText", extraText);
		// if (extraText.length > 0) {
		// 	Object.values(extraText).map((item) => {
		// 		if (item.value != "") {
		// 			text_extras.push({ extra_id: item.id, text: item.value });
		// 		}
		// 	});
		// }
		setLoading(true);
		if (extraText.length > 0) {
			axios
				.post(
					`/${room_uuid}/restaurant/order/order-item`,
					{
						restaurant_id: parseInt(restaurantId),
						item_id: item.id,
						quantity: count,
						extras: selectedExtras,
						text_extras: extraText,
					},
					{
						headers: {
							lang: lang,
						},
					}
				)
				.then((res) => {
					// settotalPrice
					console.log("selected", selected);
					console.log("restaurantInfo?.uuid", restaurantInfo?.uuid);

					queryClient.invalidateQueries(`checkout`);
					queryClient.invalidateQueries(
						`restaurant_submenu-${lang}-${restaurantInfo?.uuid}-${selected}`
					);
					setLoading(false);
					const data = res.data.data;
					handleCartClose();
					setCartCount(cartCount + 1);

					if (isAdditional) {
						settotalPrice(res.data.data.price);
					}
				});
		} else {
			axios
				.post(
					`/${room_uuid}/restaurant/order/order-item`,
					{
						restaurant_id: parseInt(restaurantId),
						item_id: item.id,
						quantity: count,
						extras: selectedExtras,
						// text_extras[0][extra_id]
						// text_extras[0][extra_id]
					},
					{
						headers: {
							lang: lang,
						},
					}
				)
				.then((res) => {
					console.log("selected", selected);
					console.log("restaurantInfo?.uuid", restaurantInfo?.uuid);
					queryClient.invalidateQueries(
						`restaurant_submenu-${lang}-${restaurantInfo?.uuid}-${selected}`
					);
					queryClient.invalidateQueries(`checkout`);
					setLoading(false);
					const data = res.data.data;
					handleCartClose();
					setCartCount(cartCount + 1);

					if (isAdditional) {
						settotalPrice(res.data.data.price);
					}
				});
		}
	};
	const setExtra = (name, type) => {
		// if (extras.length <= 2) {
		if (type == "radio") {
			setExtras([name]);
		} else {
			if (extras.includes(name)) {
				const tempExtras = JSON.parse(JSON.stringify(extras));
				const filtered = tempExtras.filter((temp) => {
					return temp !== name;
				});
				setExtras(filtered);
			} else {
				setExtras((prev) => [name, ...prev]);
			}
		}

		// }
	};
	let newpice = 0;
	extras?.map((ext) => {
		newpice = newpice + ext.price;
	});
	newpice = newpice * count;

	const orderWithCount = (count, type) => {
		let newCount = count;
		if (type == "plus") {
			newCount = 1;
		}
		if (type == "minus") {
			if (count == 0) {
				newCount = 0;
			} else {
				newCount = -1;
			}
		}
		axios
			.post(`/${room_uuid}/restaurant/order/order-item`, {
				restaurant_id: parseInt(restaurantId),
				item_id: item.id,
				quantity: newCount,
			})
			.then((res) => {
				setLoading(false);
				console.log("selected", selected);
				console.log("restaurantInfo?.uuid", restaurantInfo?.uuid);

				queryClient.invalidateQueries(
					`restaurant_submenu-${lang}-${restaurantInfo?.uuid}-${selected}`
				);
				queryClient.invalidateQueries(`checkout`);
				const data = res.data.data;
				handleCartClose();
				setCartCount(cartCount + 1);
				if (isAdditional) {
					settotalPrice(res.data.data.price);
				}
			});
	};
	const totalPrice = useMemo(() => {
		const basePrice = itemState.price || 0;
		const extrasPrice = calculateTotalPrice(itemState.extrasWithOptions);
		return (basePrice + extrasPrice) * count;
	}, [itemState, count]);
	const selectedExtras = useMemo(
		() => collectSelectedExtras(itemState.extrasWithOptions),
		[itemState]
	);
	const checkExtrasValid = useMemo(
		() => checkExtras(itemState.extrasWithOptions),
		[itemState]
	);
	const handleOptionChange = (path, checked) => {
		console.log(path, checked);
		setItemState((prev) => ({
			...prev,
			extrasWithOptions: updateCheckedState(
				[...prev.extrasWithOptions],
				path,
				checked
			),
		}));
	};
	const handleUpdateOptionCount = (path, count) => {
		const [extraPath, optionPath, subExtraPath, subOptionPath] = path;
		setItemState((prev) => ({
			...prev,
			extrasWithOptions: updateNestedOptionsCount(
				[...prev.extrasWithOptions],
				path,
				count
			),
		}));
	};
	const handleExtraTextChange = (extraIndex, newText) => {
		setItemState((prev) => ({
			...prev,
			extrasWithOptions: prev.extrasWithOptions.map((extra, index) =>
				index === extraIndex ? { ...extra, text_value: newText } : extra
			),
		}));
	};
	return (
		<div key={item.id}>
			{item ? (
				<Zoom>
					<div className="add-cart-post new res_card">
						<div className="add-cart-post-inner">
							<div className={"d-none"}></div>
							<div
								onClick={handleShow}
								className="img-pnl position-relative navigate cover animated-background"
								// className="img-pnl animated-background"
							>
								<Image src={encodeURI(item.image)} alt="Post" />
							</div>
							<div className="txt-pnl navigate">
								<div onClick={handleCartShow}>
									<div className="d-flex align-items-center">
										<h2

										//    className="animated-background"
										>
											{lang == "EN"
												? item?.display_name
												: item?.name}
										</h2>{" "}
										{item.calories && (
											<span className="calories mx-1">
												({item.calories} {t("Calories")}
												)
											</span>
										)}
									</div>
									{item.restaurant_name && (
										<h6 className="s_title">
											{item.restaurant_name}
										</h6>
									)}
									{item.dietarySymbols?.length > 0 && (
										<ul className="dietary-symbols">
											{item.dietarySymbols?.map(
												(item) => {
													return (
														<li key={item.id}>
															<img
																src={item?.icon}
																alt={item.name}
															/>
														</li>
													);
												}
											)}
										</ul>
									)}
									{item.description?.length > 0 && (
										<p
										// className="animated-background"
										>
											{item.description}
										</p>
									)}
								</div>
								<div className="flex-div">
									<h3
										className={`${
											item.restaurant_name ? "" : "mt7"
										}`}
									>
										{restaurantInfo?.show_prices == 1 ? (
											item.price == "0.00" ? (
												<span
													style={{ fontSize: "10px" }}
												>
													{t("Price upon selection")}
												</span>
											) : (
												<>
													{currency === "SAR" ? (
														<Sar color="currentcolor" />
													) : (
														`${currency || "AED"} `
													)}
													{` `}
													{item.price}
												</>
											)
										) : null}
									</h3>
									{restaurantInfo?.can_order &&
									!isOffCategory ? (
										item?.in_cart ? (
											<div
												className="count-order-amount in_cart"
												onClick={handleCartShow}
											>
												<Button className="small">
													<i className="fa fa-minus"></i>
												</Button>
												<span>
													{item.in_cart_count}
												</span>
												<Button className=" small">
													<i className="fa fa-plus"></i>
												</Button>
											</div>
										) : (
											<Button
												className={`add-button ${lang}`}
												onClick={handleCartShow}
											>
												{t("Add To Cart")}
											</Button>
										)
									) : null}
								</div>
							</div>
						</div>
					</div>
				</Zoom>
			) : null}

			{item ? (
				<Modal
					show={show}
					// style={{ maxWidth: "430px" }}checkExtras
					id={"restaurant-order-modal"}
					className={`custom-width img p-2 ${
						theme ? "food-modal" : ""
					}`}
					onHide={handleClose}
				>
					<Button className="close-btn img" onClick={handleClose}>
						<div className="close-btn-wrap"></div>
						<AiOutlineCloseCircle />
					</Button>
					<Modal.Body style={{ padding: "20px" }}>
						<div className="modal-img-container">
							<Image src={item.image} alt="Post" />
						</div>
					</Modal.Body>
				</Modal>
			) : (
				<Modal
					show={show}
					// style={{ maxWidth: "430px" }}
					id={"restaurant-order-modal"}
					className={`custom-width img ${theme ? "food-modal" : ""}`}
					onHide={handleClose}
				>
					<Button className="close-btn img" onClick={handleClose}>
						<div className="close-btn-wrap"></div>
						<AiOutlineCloseCircle />
					</Button>
					<Modal.Body style={{ padding: "7px" }}>
						<div className="modal-img-container">
							<Image
								src={
									"https://tdhbucket.s3.me-central-1.amazonaws.com/restaurant_items/item1.png"
								}
								alt="Post"
							/>
						</div>
					</Modal.Body>
				</Modal>
			)}
			{item ? (
				<Modal
					show={showCart}
					className={`custom-width ${theme ? "food-modal" : ""}`}
					onHide={handleCartClose}
					key={item.id}
				>
					<Button className="close-btn" onClick={handleCartClose}>
						{/* <i className="fa fa-times-circle-o"></i> */}
						<div className="close-btn-wrap"></div>
						<AiOutlineCloseCircle />
					</Button>
					<Modal.Body key={item.id}>
						<div className="modal-img-container">
							<Image src={item.image} alt="Post" />
						</div>
						<div className="modal-text-container sub-menu">
							<h2 className="text-1">
								{lang == "EN" ? item?.display_name : item?.name}
							</h2>
							{item.dietarySymbols?.length > 0 && (
								<ul className="dietary-symbols m-2">
									{item.dietarySymbols?.map((item) => {
										return (
											<li key={item.id}>
												<img
													src={item?.icon}
													alt={item.name}
												/>
											</li>
										);
									})}
								</ul>
							)}
							<p className="text-2">
								{item.description ? item.description : ""}
							</p>
							<div className="price-container">
								<p>
									{restaurantInfo?.show_prices == 1 ? (
										item.price == "0.00" ? (
											<span style={{ fontSize: "10px" }}>
												{t("Price upon selection")}
											</span>
										) : (
											<>
												<span>
													{currency === "SAR" ? (
														<Sar color="currentcolor" />
													) : (
														`${currency || "AED"} `
													)}
													<span> {item.price}</span>
												</span>
											</>
										)
									) : null}
								</p>
								{restaurantInfo?.can_order && !isOffCategory ? (
									<div className="count-order-amount">
										<Button
											className="small"
											onClick={handleDecrease}
										>
											<i className="fa fa-minus"></i>
										</Button>
										<p>{count}</p>
										<Button
											className=" small"
											onClick={handleIncrease}
										>
											<i className="fa fa-plus"></i>
										</Button>
									</div>
								) : null}
							</div>
							{/* <div className="d-flex justify-content-center cart-btn-container">
              <Button className="reg-btn small w-100">Add To Cart</Button>
            </div> */}

							<form
								action=""
								onSubmit={addToCartV2}
								key={item.id}
							>
								<ExtraOptions
									key={item.id}
									item={item}
									extrasWithOptions={
										itemState.extrasWithOptions
									}
									onOptionChange={handleOptionChange}
									updateOptionCount={handleUpdateOptionCount}
									updateTextValue={handleExtraTextChange}
									path={[]}
								/>
								<div className="full-div mt-4 modal-add-cart">
									{restaurantInfo?.can_order &&
									!isOffCategory ? (
										<Button
											type="submit"
											// onClick={addToCartV2}
											className="reg-btn big w-100 flex-div"
											disabled={loading}
										>
											<div className="plus-container">
												<i className="fa fa-plus-square"></i>{" "}
												<span>{t("Add To Cart")}</span>
											</div>
											<div>
												{restaurantInfo?.show_prices ==
												1 ? (
													item.price == "0.00" &&
													priceOption == 0 ? (
														<span
															style={{
																fontSize:
																	"12px",
															}}
														>
															{t(
																"Price upon selection"
															)}
														</span>
													) : (
														<>
															{currency ===
															"SAR" ? (
																<Sar color="currentcolor" />
															) : (
																`${
																	currency ||
																	"AED"
																} `
															)}{" "}
															{parseFloat(
																totalPrice
															).toFixed(2)}
														</>
													)
												) : null}
											</div>
										</Button>
									) : null}
								</div>
							</form>
						</div>
					</Modal.Body>
				</Modal>
			) : null}
		</div>
	);
}
export default AddCartPost;
