import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import HeaderInner from "../../Components/HeaderInner";
import { useEffect } from "react";
import SpaLoader from "../../Components/Loaders/SpaLoader";
import { Zoom } from "react-reveal";
import { ShowEntertainment } from "../../API/EntertainmentAPI";
import CustomBusinessServiceLoader from "../../Components/Loaders/CustomBusinessServiceLoader";
import { useBusinessNameContext } from "../../context/BusinessName";

function Entertainment() {
	const { businessName, setBusinessName } = useBusinessNameContext();
	const location = useLocation();
	const rawPath = location.pathname;
	const room_uuid = rawPath.split("/")[1];
	const navigate = useNavigate();

	const lang = localStorage.getItem("lang");

	useEffect(() => {
		window.scrollTo({
			top: 0,
			left: 0,
		});
	}, []);
	let flowers;
	let text;
	let entertainmentCategories;
	const { data, isLoading, error } = ShowEntertainment(room_uuid, lang);
	if (!isLoading) {
		if (error) {
			if (error?.response.status === 404) {
				navigate(`/not-found`);
			}
		} else {
			if (data.data.status === false) {
				navigate(`/${room_uuid}/qr-scan`);
			} else {
				flowers = data.data.data;
				entertainmentCategories = flowers?.entertainment_companies;
				text = flowers?.title;
				if (entertainmentCategories?.length === 1) {
					navigate(
						`/${room_uuid}/entertainment/categories/${entertainmentCategories[0].uuid}`,
						{ replace: true }
					);
				}
			}
		}
	}

	return (
		<>
			<HeaderInner headerName={text} />
			<main className="home-main bg-white pt-3">
				<div className="">
					{isLoading ? (
						<CustomBusinessServiceLoader
							businessName={businessName}
							loader={"spa"}
						/>
					) : entertainmentCategories.length > 0 ? (
						entertainmentCategories.map((x, idx) => {
							return (
								<div id={x.name} key={idx}>
									<Zoom>
										<div
											className="con-img-container v3"
											onClick={() =>
												navigate(
													`/${room_uuid}/entertainment/categories/${x.uuid}`
												)
											}
										>
											<div className="con-img">
												<img src={x.logo} alt="" />
												<p></p>
											</div>
										</div>
										<div className="con-text-container">
											<p>{x.name}</p>
											<hr className="mb-0 dark" />
										</div>
									</Zoom>
								</div>
							);
						})
					) : null}
				</div>
			</main>
		</>
	);
}
export default Entertainment;
