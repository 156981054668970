import React, {useContext, useEffect} from "react";
import {Link, useLocation, useNavigate, useParams} from "react-router-dom";
import HeaderInner from "../../Components/HeaderInner";
import SpaLoader from "../../Components/Loaders/SpaLoader";
import {Zoom} from "react-reveal";
import {ShowEntertainments} from "../../API/EntertainmentAPI";
import {ShowHH_HouseKeepings} from "../../API/HH_HouseKeepingAPI";
import Title from "../../new-components/title/Title";
import NewFooter from "../../NewFooter";
import Overllay from "../../assets/new_imgs/Rectangle 1072.png";
import {ThemeContext} from "../../context/ThemeContext";
import {useBusinessNameContext} from "./../../context/BusinessName";
import CustomBusinessServiceLoader from "../../Components/Loaders/CustomBusinessServiceLoader";
import "./HH_HouseKeepings.scss";

function HH_HouseKeepings() {
	const {businessName, setBusinessName} = useBusinessNameContext();
	const location = useLocation();
	const rawPath = location.pathname;
	const room_uuid = rawPath.split("/")[1];
	const euuid = rawPath.split("categories/")[1];
	const navigate = useNavigate();
	const params = useParams();
	const {theme} = useContext(ThemeContext);
	const lang = localStorage.getItem("lang");

	let flowers;
	let bgColor;
	let color;
	let text;
	let company_uuid;
	let entertainmentCategories;
	const {data, isLoading, error} = ShowHH_HouseKeepings(
		room_uuid,
		params.uuid,
		lang
	);

	if (!isLoading) {
		console.log("ShowHH_HouseKeepingsShowHH_HouseKeepings", data);
		if (error) {
			if (error?.response.status == 404) {
				navigate(`/not-found`);
			}
		} else {
			if (data.data.status === false) {
				navigate(`/${room_uuid}/qr-scan`);
			} else {
				flowers = data.data.data;
				entertainmentCategories = flowers.houseKeepingServices;
				color = flowers.color;
				bgColor = flowers.header_color;
				text = flowers.title;
				company_uuid = flowers?.company_uuid;
			}
		}
	}

	useEffect(() => {
		window.scrollTo({
			top: 0,
			left: 0,
		});
	}, []);

	return (
		<div className={theme ? "food" : ""}>
			{theme ? <Title title={text} /> : <HeaderInner headerName={text} />}
			{isLoading ? (
				<div className="loader-container">
					<CustomBusinessServiceLoader
						businessName={businessName}
						loader={"spa"}
					/>
				</div>
			) : (
				<main className="home-main bg-white back-white pad-top-0">
					<div className="">
						{theme && entertainmentCategories ? (
							<div className="box_food">
								{entertainmentCategories?.map((item, idx) => (
									<span
										key={idx}
										onClick={() =>
											navigate(
												`/${room_uuid}/HH_HouseKeepingDetail/${company_uuid}/${item.id}`,
												{
													state: {theme: true},
												}
											)
										}
									>
										<div style={{position: "relative"}}>
											<img
												src={item?.image}
												alt={item.name}
												className="booking_img"
											/>
											<img
												src={Overllay}
												alt=""
												className="overlay"
											/>
											<div className="text_food">
												<h5>{item.name}</h5>
											</div>
										</div>
									</span>
								))}
							</div>
						) : entertainmentCategories ? (
							<div className="non-theme-content">
								{entertainmentCategories.map((item, idx) => (
									<div
										className="housekeeping-item"
										key={idx}
										id={item.name}
									>
										<Zoom>
											<div
												className="con-img-container v3"
												onClick={() =>
													navigate(
														`/${room_uuid}/HH_HouseKeepingDetail/${company_uuid}/${item.id}`
													)
												}
											>
												<div className="con-img">
													<img
														src={item.image}
														alt={item.name}
													/>
												</div>
											</div>
											<div className="con-text-container">
												<p>{item.name}</p>
												<hr className="mb-0 dark" />
											</div>
										</Zoom>
									</div>
								))}
							</div>
						) : null}
					</div>
				</main>
			)}
			{theme && <NewFooter />}
		</div>
	);
}
export default HH_HouseKeepings;
