import { useState } from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import VerificationInput from "react-verification-input";
export default function Otp({
	email,
	phone,
	onChange,
	onClose,
	handleBook,
	error,
	value,
}) {
	const [otp, setOtp] = useState("");
	const { t, i18n } = useTranslation();
	const onBook = () => {
		handleBook();
	};

	return (
		<div className="otp_div">
			<h5>OTP Verification</h5>
			<p>
				{t("Please enter the OTP sent to your")}
				{email !== "" ? ` email (${email}) and ` : " "}
				{t("phone number")} ({phone}){" "}
				{t("to complete the verification process. Thank you!")}
			</p>
			<VerificationInput
				value={value}
				// onChange={(e) => setVerification_code(e)}

				inputField={{
					onChange: (e) => {
						if (e.nativeEvent.target.value) {
							onChange(e.nativeEvent.target.value);
						}
						setOtp(e.nativeEvent.target.value);
					},
				}}
			/>
			{error && <span className="text-danger">{error}</span>}
			<div className="butons_opt">
				<Button
					className="canc reg-btn order-btn"
					onClick={onClose}
					// disabled={isDisabled}
				>
					{t("Cancel")}
				</Button>
				<Button
					className="reg-btn order-btn"
					onClick={onBook}
					disabled={value?.length != 6}
				>
					{t("verify")}
				</Button>
			</div>
		</div>
	);
}
