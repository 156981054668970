import React from 'react'
import PhoneInput from 'react-phone-input-2';

const PhoneInputComponent = ({ onChange, value, className }) => {

  const code = localStorage.getItem("countryCode")

  console.log("PhoneInputComponent code", code);
  console.log("PhoneInputComponent value", value);

  return (
    <PhoneInput
      containerclassName={className || ""}
      country={code ? code?.toLowerCase() : "ae"}
      value={value || ""}
      placeholder='1214221'
      onChange={onChange}
    />
  )
}

export default PhoneInputComponent