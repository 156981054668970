import React, { useContext, useState } from "react";
import { Button, Modal, Dropdown } from "react-bootstrap";
import HeaderInner from "../../Components/HeaderInner";
import CarBookModal from "../../Components/CarBookModal";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axios from "../../axios";
import { useEffect } from "react";
import SpaLoader from "../../Components/Loaders/SpaLoader";
import { Zoom } from "react-reveal";
import Bounce from "react-reveal/Bounce";
import { ShowHealthCare } from "../../API/HealthCareAPI";
import { useTranslation } from "react-i18next";
import Title from "../../new-components/title/Title";
import Overllay from "../../assets/new_imgs/Rectangle 1072.png";
import NewFooter from "../../NewFooter";
import { ThemeContext } from "../../context/ThemeContext";
import { useBusinessNameContext } from "../../context/BusinessName";
import CustomBusinessServiceLoader from "../../Components/Loaders/CustomBusinessServiceLoader";

function HealthCare() {
	const { businessName, setBusinessName } = useBusinessNameContext();
	const navigate = useNavigate();
	// const [healthCategories, setHealthCategories] = useState();
	const [loading, setLoading] = useState(false);
	const location = useLocation();
	const rawPath = location.pathname;
	const room_uuid = rawPath.split("/")[1];
	console.log("rawPath", rawPath);
	const { t, i18n } = useTranslation();

	const lang = localStorage.getItem("lang");

	// setTimeout(() => {
	//   setEnableClass(false);
	// }, 3000);
	// const new_design = localStorage.getItem("new_design");
	// const theme = new_design == "true";
	const { theme } = useContext(ThemeContext);
	let flowers;
	let bgColor;
	let color;
	let text;
	let healthCategories;
	const { data, isLoading, error } = ShowHealthCare(room_uuid, lang);
	if (!isLoading) {
		if (data.data.status === false) {
			navigate(`/${room_uuid}/qr-scan`);
		} else {
			flowers = data.data.data;
			healthCategories = flowers.heakth_care_and_wellness_companies;
			color = flowers.color;
			bgColor = flowers.header_color;
			text = flowers.title;
			if (healthCategories?.length == 1) {
				navigate(
					`/${room_uuid}/healthCar/categories/${healthCategories[0].uuid}`,
					{ replace: true }
				);
			}
			// console.log("ShowHealthCare", flowers);
		}
	}
	useEffect(() => {
		window.scrollTo({
			top: 0,
			left: 0,
		});
	}, []);
	return (
		<div className={theme ? "food" : ""}>
			{theme ? (
				<Title title={text} />
			) : (
				<HeaderInner
					headerName={text || "Healthcare & Wellness"}
					bgColor={bgColor}
					color={color}
				/>
			)}

			<main className="home-main bg-white pt-3">
				<div className={theme ? "box_food" : ""}>
					{isLoading ? (
						<CustomBusinessServiceLoader
							businessName={businessName}
							loader={"spa"}
						/>
					) : healthCategories?.length > 1 ? (
						healthCategories?.map((x, idx) => {
							return theme ? (
								<div key={idx}>
									<Link
										to={`/${room_uuid}/healthCar/categories/${x.uuid}`}
									>
										<img
											src={x?.logo}
											alt=""
											className="booking_img"
										/>
										<img
											src={Overllay}
											alt=""
											className="overlay"
										/>
										<div className="text_food">
											<h5 className="">{x.name}</h5>
										</div>{" "}
									</Link>
								</div>
							) : (
								<div id={x.name} key={idx}>
									<Zoom>
										<div
											className="con-img-container"
											onClick={() =>
												navigate(
													`/${room_uuid}/healthCar/categories/${x.uuid}`
												)
											}
										>
											<div className="con-img">
												<img src={x.logo} alt="" />
												<p></p>
											</div>
										</div>
										<div className="con-text-container">
											<p>{x.name}</p>
											<hr className="mb-0 dark" />
										</div>
									</Zoom>
								</div>
							);
						})
					) : null}
				</div>
			</main>
			{theme && <NewFooter />}
		</div>
	);
}
export default HealthCare;
