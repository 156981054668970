import React, { useEffect, useRef, useState } from "react";
import { Dropdown } from "react-bootstrap";
import HeaderInner from "../../Components/HeaderInner";
import CarRentalPost from "../../Components/CarRentalPost";
import { useLocation, useNavigate } from "react-router-dom";
import CarRentalLoader from "../../Components/Loaders/CarRentalLoader";
import { useTranslation } from "react-i18next";
import Search from "../../Components/Search";
import { useInfiniteQuery } from "react-query";
import { CarsAPI } from "../../API/CarsAPI";
import ReactGA from "react-ga4";
import Carosle from "../../Components/Carosle";
import { useBusinessNameContext } from "../../context/BusinessName";
import CustomBusinessServiceLoader from "../../Components/Loaders/CustomBusinessServiceLoader";

function Entertainment() {
	const { businessName, setBusinessName } = useBusinessNameContext();

	const lang = localStorage.getItem("lang");
	const { t } = useTranslation();
	const location = useLocation();
	const navigate = useNavigate();
	const rawPath = location.pathname;
	const room_uuid = rawPath.split("/")[1];
	const [make, setMake] = useState("");
	const [model, setModel] = useState("");
	const [price, setPrice] = useState(null);
	const [year, setYear] = useState("");

	let carSettings = {
		carModels: [],
		carTypes: [],
		years_list: [],
	};

	const {
		data,
		error,
		isLoading,
		hasNextPage,
		isFetchingNextPage,
		fetchNextPage,
	} = useInfiniteQuery(
		`cars-${lang}-${make?.id ? make?.id : ""}-${
			model?.id ? model?.id : ""
		}-${year}-${price}`,
		({ pageParam = 1 }) =>
			CarsAPI({
				room_uuid,
				make: make?.id ? make?.id : "",
				model: model?.id ? model?.id : "",
				sort_direction: price ? `${price}&sort_by=price` : null,
				year,
				pageParam,
				lang,
			}),
		{
			getNextPageParam: (lastPageData) => {
				const lastPage = lastPageData?.data?.data?.cars?.last_page;
				const currentPage =
					lastPageData?.data?.data?.cars?.current_page;
				return currentPage < lastPage ? currentPage + 1 : undefined;
			},
		}
	);

	let banners;
	let header_title;
	if (!isLoading) {
		if (data.pages[0].data.status === false) {
			navigate(`/${room_uuid}/qr-scan`);
		} else {
			console.log("data", data);
			carSettings.carModels = data.pages[0]?.data.data.carModels;
			carSettings.carTypes = data.pages[0]?.data.data.carTypes;
			carSettings.years_list = data.pages[0]?.data.data.years_list;
			banners = data.pages[0]?.data.data.banners;
			header_title = data.pages[0]?.data.data.header_title;
		}
	}

	const loadMoreRef = useRef();

	useEffect(() => {
		if (!hasNextPage) {
			return;
		}

		const observer = new IntersectionObserver(
			(entries) =>
				entries.forEach(
					(entry) => entry.isIntersecting && fetchNextPage()
				),
			{
				root: null,
				rootMargin: "0px",
				threshold: 1.0,
			}
		);

		const el = loadMoreRef.current;

		if (!el) {
			return;
		}

		observer.observe(el);
		return () => observer.disconnect(); // Cleanup the observer on component unmount
	}, [loadMoreRef.current, hasNextPage]);

	useEffect(() => {
		window.scrollTo({
			top: 0,
			left: 0,
		});
	}, []);

	if (error) {
		console.error("Error fetching data:", error);
		return <div>Error fetching data</div>;
	}

	return (
		<>
			<HeaderInner headerName={header_title} />
			<main className="home-main bg-white back-fe pad-top-0 car_rental">
				{isLoading ? (
					<CustomBusinessServiceLoader
						businessName={businessName}
						loader={"carRental"}
					/>
				) : (
					<>
						<Carosle
							className="dot-bar less-margin small-dots"
							headerImages={banners}
						/>
						<Search
							placeholder="Search for A Car"
							room_uuid={room_uuid}
							category="Car Rental"
						/>

						<div className="padd-div car-drop-flex flex-div">
							<Dropdown className="car-drop">
								<Dropdown.Toggle
									variant="success"
									id="dropdown-basic"
									disabled={carSettings.carTypes.length === 0}
								>
									{make.name ? make.name : `${t("Make")}`}
								</Dropdown.Toggle>
								<Dropdown.Menu>
									<Dropdown.Item onClick={() => setMake("")}>
										{t("Make")}
									</Dropdown.Item>
									{carSettings.carTypes &&
										carSettings.carTypes.map((type) => (
											<Dropdown.Item
												key={type.id}
												onClick={() => {
													ReactGA.event({
														category:
															"Select Make in car page",
														action: "Click",
														value: `make: ${type.name}`,
													});
													setMake(type);
												}}
											>
												{type.name}
											</Dropdown.Item>
										))}
								</Dropdown.Menu>
							</Dropdown>
							<Dropdown className="car-drop">
								<Dropdown.Toggle
									variant="success"
									disabled={
										carSettings.carModels.length === 0
									}
									id="dropdown-basic"
								>
									{model.name ? model.name : `${t("Model")}`}
								</Dropdown.Toggle>
								<Dropdown.Menu>
									<Dropdown.Item onClick={() => setModel("")}>
										{t("Model")}
									</Dropdown.Item>
									{carSettings.carModels &&
										carSettings.carModels.map((model) => (
											<Dropdown.Item
												key={model.id}
												onClick={() => {
													setModel(model);
													ReactGA.event({
														category:
															"Select Model in car page",
														action: "Click",
														value: `model: ${model.name}`,
													});
												}}
											>
												{model.name}
											</Dropdown.Item>
										))}
								</Dropdown.Menu>
							</Dropdown>
							<Dropdown className="car-drop">
								<Dropdown.Toggle
									variant="success"
									id="dropdown-basic"
								>
									{price
										? price === "asc"
											? "Low to high"
											: "High to low"
										: `${t("Price")}`}
								</Dropdown.Toggle>
								<Dropdown.Menu>
									<Dropdown.Item
										onClick={() => setPrice("asc")}
									>
										{t("Low to high")}
									</Dropdown.Item>
									<Dropdown.Item
										onClick={() => setPrice("desc")}
									>
										{t("High to low")}
									</Dropdown.Item>
								</Dropdown.Menu>
							</Dropdown>
						</div>
						<div className="spacer-40"></div>

						<ul className="display-list car-list two-items v2">
							{data?.pages &&
								data.pages.map((page, pageIndex) => {
									// Convert the object to an array
									const carsArray = Object.values(
										page.data?.data?.cars?.data || {}
									);
									return carsArray.map((item, itemIndex) => (
										<CarRentalPost
											key={`${pageIndex}-${itemIndex}`}
											car={item}
											room_uuid={room_uuid}
										/>
									));
								})}
						</ul>
						<div
							className="ref_loading"
							ref={loadMoreRef}
							onClick={() => fetchNextPage()}
							disabled={!hasNextPage || isFetchingNextPage}
						></div>
						<div>
							{isFetchingNextPage ? (
								<div className="d-flex justify-content-center">
									<div
										className="spinner-border"
										role="status"
									>
										<span className="visually-hidden"></span>
									</div>
								</div>
							) : hasNextPage ? (
								<div className="d-flex justify-content-center">
									<div
										className="spinner-border"
										role="status"
									>
										<span className="visually-hidden"></span>
									</div>
								</div>
							) : (
								""
							)}
						</div>
					</>
				)}
			</main>
		</>
	);
}

export default Entertainment;
